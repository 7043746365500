import { createMachine } from 'xstate';
import Limits from '../../../utils/constants/Limits';
import getLastDayToSchedule from '../../../utils/getLastDayToSchedule';
import {
    setSelectedCover,
    evaluateDisabledDays,
    setDaysToSchedule,
    updateMachineContextFromGlobalContext,
} from './actions/roamingDatesBaymaxMachineActions';
import { RoamingDatesBaymaxMachineContextTypes } from './context/roamingDatesBaymaxMachineContext';
import { isRoamingProductSelected, areDatesSelected } from './guards/roamingDatesBaymaxMachineGuards';
import { ProductOption } from '../../../business-logic/models/ProductGroupsResponse';

const RoamingDatesBaymaxMachine = createMachine(
    {
        id: 'RoamingDatesBaymaxMachine',
        initial: 'waitForContextInitialisation',
        context: {
            selectedProductGrouping: '',
            coversAvailable: [],
            selectedCover: null,
            daysToSchedule: [],
            scheduleLimitInHours: null,
            datePickerMode: 'week',
            // use location specific timezone
            timezone: '',
            destinations: [],
            startingRegion: '',
            startingDestination: '',
            coverStartDates: [],
            // Defaults to allowing all cover purchases at any time
            // use fallback limits as default
            disabledDays: [
                {
                    before: new Date(),
                    after: getLastDayToSchedule(new Date(), Limits.FALLBACK_SCHEDULE_LIMIT_IN_HOURS),
                },
            ],
        },
        schema: {
            context: {} as RoamingDatesBaymaxMachineContextTypes,
            events: {} as
                | { type: 'SELECT_DATES'; data: Date[] }
                | { type: 'AGREE_CONDITIONS' }
                | { type: 'CHECKOUT' }
                | { type: 'GO_BACK' }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          coversAvailable: ProductOption[];
                          selectedProductGrouping: string;
                      };
                  },
        },
        tsTypes: {} as import('./roamingDatesBaymaxMachine.typegen').Typegen0,
        preserveActionOrder: true,
        states: {
            waitForContextInitialisation: {
                on: {
                    CONTEXT_INITIALISED: {
                        actions: 'updateMachineContextFromGlobalContext',
                        target: 'verifyRoamingSelected',
                    },
                },
            },
            verifyRoamingSelected: {
                always: [
                    {
                        cond: 'isRoamingProductSelected',
                        target: 'prepareCalendarPicker',
                    },
                    {
                        target: 'redirectToStart',
                    },
                ],
            },
            redirectToStart: {
                type: 'final',
                entry: 'redirectToPurchase',
            },
            prepareCalendarPicker: {
                always: [
                    {
                        actions: ['setSelectedCover', 'setScheduleLimitInHours', 'evaluateDisabledDays'],
                        target: 'selectingDates',
                    },
                ],
            },
            selectingDates: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'verifyDatesSelected',
                    },
                },
            },
            verifyDatesSelected: {
                always: [
                    {
                        cond: 'areDatesSelected',
                        target: 'datesSelected',
                    },
                    {
                        target: 'selectingDates',
                    },
                ],
            },
            datesSelected: {
                on: {
                    SELECT_DATES: {
                        actions: 'setDaysToSchedule',
                        target: 'verifyDatesSelected',
                    },
                    AGREE_CONDITIONS: {
                        target: 'showRoamingModal',
                    },
                },
            },
            showRoamingModal: {
                on: {
                    CHECKOUT: {
                        actions: 'goToCheckout',
                    },
                },
            },
        },
    },
    {
        actions: {
            setSelectedCover,
            setDaysToSchedule,
            updateMachineContextFromGlobalContext,
            evaluateDisabledDays,
        },
        guards: {
            areDatesSelected,
            isRoamingProductSelected,
        },
    },
);
export default RoamingDatesBaymaxMachine;
