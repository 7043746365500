import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useRive, UseRiveParameters } from 'rive-react';
import RiveAnimation from '../../../utils/constants/RiveAnimation';

import './RiveButton.scss';

interface RiveButtonProps {
    animationName: RiveAnimation;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
}

const RiveButton: React.FC<RiveButtonProps> = ({ animationName, onClick, disabled, className }) => {
    const params: UseRiveParameters = {
        src: 'flip.riv',
        autoplay: false,
    };
    const { RiveComponent, rive } = useRive(params);

    useEffect(() => {
        rive?.play(animationName);
    }, [animationName, rive]);

    return (
        <div
            role="button"
            className={classNames('rive-button__button', { 'rive-button__button--disabled': disabled }, className)}
            onClick={disabled ? undefined : onClick}
            onKeyDown={(e) => {
                if ((e.key === 'Enter' || e.key === 'Space') && onClick) {
                    onClick();
                }
            }}
            tabIndex={0}
            aria-disabled={disabled}
        >
            <div className="rive-button__rive">
                <RiveComponent />
            </div>
        </div>
    );
};

export default RiveButton;
