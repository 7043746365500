import getCoverCategory from './getCoverCategory';
import getCoverConfig from './getCoverConfig';
import getCoverInformation from './getCoverInformation';
import getCoverTerms from './getCoverTerms';
import getPartnerCoverTerms from './getPartnerCoverTerms';

export default {
    getCoverConfig,
    getCoverInformation,
    getCoverTerms,
    getPartnerCoverTerms,
    getCoverCategory,
};
