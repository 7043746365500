import { useMachine } from '@xstate/react';
import classNames from 'classnames';
import { ChangeEvent, ChangeEventHandler, FC, FormEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRoamingBaymax } from '../../business-logic/context-provider/RoamingBaymaxContext';
import { Destination, Region } from '../../business-logic/models/RoamingDestinations';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Checkbox from '../../components/form/checkbox/Checkbox';
import Fieldset from '../../components/form/fieldset/Fieldset';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import roamingDestinationsContent from '../../content/ui/screens/roaming-destinations/roamingDestinations';
import checkAgeEligibilityBaymax from '../../hoc/check-age-eligibility/checkAgeEligibilityBaymax';
import withContent from '../../hoc/with-content/withContent';
import common from '../../strings/common';
import Routes from '../../utils/Routes';
import { RoamingDestinationsBaymaxMachineContextTypes } from './roaming-destinations-baymax-machine/context/roamingDestinationsBaymaxMachineContext';
import RoamingDestinationsBaymaxMachine from './roaming-destinations-baymax-machine/roamingDestinationsBaymaxMachine';

import './RoamingDestinations.scss';

const contentMap = {
    heading: 'ui.heading',
};

interface RoamingDestinationsBaymaxBaymaxProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingDestinationsBaymax: FC<RoamingDestinationsBaymaxBaymaxProps> = ({ content }) => {
    const history = useHistory();
    const {
        loading: roamingLoading,
        initialised: roamingInitialised,
        chosenProductGroup,
        selectedProductOption,
        destinations,
    } = useRoamingBaymax();

    const [state, send] = useMachine(RoamingDestinationsBaymaxMachine, {
        context: {
            selectedProductGrouping: chosenProductGroup,
        },
        actions: {
            redirectToSelectDates: (ctx: RoamingDestinationsBaymaxMachineContextTypes) => {
                // one destination chosen + only one region present
                // and region name === destination name

                const destination =
                    destinations
                        .find((item: Destination) => item.destination === ctx.chosenDestinations[0])
                        ?.regions.find((item: Region) => item.region === ctx.chosenDestinations[0]) ?? null;
                if (!destination) {
                    history.goBack();
                }
                history.push({
                    pathname: Routes.ROAMING_DATES_BAYMAX,
                    state: {
                        selectedProductGrouping: chosenProductGroup,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: state.context.chosenDestinations,
                            startingDestination: state.context.chosenDestinations[0],
                            startingRegion: destination?.region,
                            timezone: destination?.timezone,
                        },
                    },
                });
            },
            redirectToChooseStartingRegion: (ctx) => {
                history.push({
                    pathname: Routes.ROAMING_REGION_BAYMAX,
                    state: {
                        selectedProductGrouping: chosenProductGroup,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: state.context.chosenDestinations,
                            startingDestination: state.context.chosenDestinations[0],
                            startingRegion: '',
                            timezone: '',
                        },
                    },
                });
            },
            redirectToChooseStartingDestination: (ctx) => {
                history.push({
                    pathname: Routes.ROAMING_START_BAYMAX,
                    state: {
                        selectedProductGrouping: chosenProductGroup,
                        selectedProductOption: ctx.selectedProductOption,
                        destination: {
                            destinations: state.context.chosenDestinations,
                            startingDestination: '',
                            startingRegion: '',
                            timezone: '',
                        },
                    },
                });
            },
        },
    });

    useEffect(() => {
        if (!roamingLoading && roamingInitialised && !!selectedProductOption) {
            send({
                type: 'CONTEXT_INITIALISED',
                data: {
                    selectedProductOption,
                    destinations,
                },
            });
        }
    }, [
        send,
        state.context,
        chosenProductGroup,
        roamingLoading,
        roamingInitialised,
        selectedProductOption,
        destinations,
    ]);

    const selectDestinations = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send({ type: 'CONTINUE' });
    };

    const renderCheckbox = (destination: string, onChange: ChangeEventHandler<HTMLInputElement>, checked: boolean) => {
        return (
            <Checkbox
                id={destination}
                key={destination}
                name="covers"
                className={classNames('checkbox--button-style', checked && 'checked')}
                label={<span className="roaming-destinations__option__label">{destination}</span>}
                onChange={onChange}
                checked={checked}
            />
        );
    };

    const renderDestinationCheckbox = (chosenLocation: Destination) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
                send({
                    type: 'SELECTED_DESTINATIONS',
                    data: [...state.context.chosenDestinations, chosenLocation.destination],
                });
            } else {
                send({
                    type: 'SELECTED_DESTINATIONS',
                    data: state.context.chosenDestinations.filter((item: any) => item !== chosenLocation.destination),
                });
            }
        };

        const checked = state.context.chosenDestinations.some((item: string) => item === chosenLocation.destination);

        return renderCheckbox(chosenLocation.destination, onChange, checked);
    };
    return (
        <Layout title={content.heading} showBackButton showLoading={state.hasTag('loading') || roamingLoading}>
            {!roamingLoading && !roamingInitialised && !state.hasTag('loading') && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            <form onSubmit={selectDestinations}>
                <Fieldset legend={content.heading} visuallyHideLegend className="roaming-destinations__list">
                    {!state.hasTag('loading') &&
                        destinations?.map((destination: Destination) => (
                            <div key={destination.destination}>{renderDestinationCheckbox(destination)}</div>
                        ))}
                </Fieldset>
                <Sticky>
                    <Button
                        width="full"
                        type="submit"
                        variant="primary"
                        label={common.continue}
                        className="roaming-destinations__cta"
                        disabled={!!state.context.chosenDestinations.length === false}
                    />
                </Sticky>
            </form>
        </Layout>
    );
};

export default checkAgeEligibilityBaymax(
    withContent(RoamingDestinationsBaymax, contentMap, roamingDestinationsContent),
);
