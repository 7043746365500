import axios from 'axios';
import { CartInstanceResponse } from '../../business-logic/models/Cart';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';
import toHeaders from '../utils/toHeaders';
import assertAccessTokenValidity from './utils/assertTokenValidity';

const baseApiPath = import.meta.env.VITE_BASE_API_PATH;

const getGuestCart = async ({
    accessToken,
}: { accessToken: string | undefined } & ServiceArgs): Promise<CartInstanceResponse | null> => {
    try {
        assertAccessTokenValidity(accessToken);
        const { data } = await axios.get<CartInstanceResponse>(
            `${baseApiPath}/api/v1/guest/cart`,
            toHeaders(accessToken),
        );
        return data;
    } catch (error) {
        if (isAxios404Error(error)) {
            return null; // handle error silently
        }
        throw new Error(`Error with calling GET cart service: ${error}`);
    }
};

export default asService(getGuestCart);
