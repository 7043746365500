import classNames from 'classnames';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../../../analytics/Analytics';
import blackArrow from '../../../../assets/images/arrow-left-black.svg';
import dayPassInverse from '../../../../assets/images/cover/day-pass-inverse.svg';
import dayPass from '../../../../assets/images/cover/day-pass.svg';
import { PaymentStatus } from '../../../../business-logic/models/PoliciesResponse';
import SlideInCoverTerms from '../../../../components/slide-in-cover-terms/SlideInCoverTerms';
import useCoverInformation from '../../../../hooks/useCoverInformation';
import CoverCode from '../../../../utils/constants/CoverCode';
import CoverStatus from '../../../../utils/constants/CoverStatus';
import DateFormat from '../../../../utils/constants/DateFormat';
import PageTitles from '../../../../utils/constants/PageTitles';
import formatISO8601DateTime from '../../../../utils/formatISO8601DateTime';
import getTimezone from '../../../../utils/getTimezone';
import { Route } from '../../../../utils/Routes';

import './ManageCoverCard.scss';

interface ManageCoverCardProp {
    status: CoverStatus;
    paymentStatus?: PaymentStatus;
    coverCode: string;
    title: string;
    startTime: string;
    endTime: string;
    renewalTime: string | null;
    destinations?: string[];
    pdsVersion: string;
    timeZone: string;
    goToBillingCenter: () => void;
    canCancel: boolean;
    initiateCancellation: () => void;
    className?: string;
}

const ManageCoverCard = ({
    status,
    paymentStatus,
    coverCode,
    title,
    startTime,
    endTime,
    renewalTime,
    destinations,
    pdsVersion,
    timeZone,
    goToBillingCenter,
    canCancel = false,
    initiateCancellation,
    className,
}: ManageCoverCardProp) => {
    const history = useHistory();
    const defaultDate = formatISO8601DateTime(startTime, timeZone, DateFormat.DEFAULT);
    const coverInformation = useCoverInformation(coverCode, pdsVersion);
    const pageName = PageTitles[history?.location.pathname as Route];

    const [selectedCoverForBenefits, setSelectedCover] = useState<keyof typeof CoverCode | null>(null);
    return (
        <div
            className={classNames(
                'manage-cover-card',
                status === CoverStatus.INACTIVE && 'manage-cover-card--inactive',
                className,
            )}
        >
            <div className="manage-cover-card__header">
                <div className="manage-cover-card__header__wrapper">
                    <div className="manage-cover-card__header__content">
                        <img
                            src={
                                status === CoverStatus.INACTIVE
                                    ? coverInformation?.inverseIcon || dayPassInverse
                                    : coverInformation?.icon || dayPass
                            }
                            alt=""
                            className="manage-cover-card__header__content__icon"
                        />
                        <span className="manage-cover-card__header__content__text">{title}</span>
                    </div>
                    {(status === CoverStatus.ACTIVE || status === CoverStatus.ENDING) && (
                        <div className="manage-cover-card__header__status">
                            <span>Active</span>
                        </div>
                    )}
                </div>
                <button
                    className="manage-cover-card__header__button"
                    type="button"
                    title="See insurance benefits and or cancellation options"
                    onClick={() => {
                        setSelectedCover((coverCode as CoverCode) ?? null);
                        Analytics.trackBenefitsDrawerViewed(pageName, coverCode);
                    }}
                >
                    <img
                        src={blackArrow}
                        className="manage-cover-card__header__button__icon"
                        alt="See insurance benefits and or cancellation options"
                    />
                </button>
            </div>
            {status !== CoverStatus.ENDING && (
                <div className="manage-cover-card__date">
                    <span
                        className={classNames(
                            'manage-cover-card__date__label',
                            renewalTime && 'manage-cover-card__date__label--wide',
                        )}
                    >
                        {status === CoverStatus.INACTIVE ? 'Started:' : 'Starts:'}
                    </span>
                    <span className="manage-cover-card__date__item">
                        {formatISO8601DateTime(startTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                    </span>
                </div>
            )}

            {renewalTime && status !== CoverStatus.ENDING && (
                <div className="manage-cover-card__date">
                    <span className="manage-cover-card__date__label manage-cover-card__date__label--wide">
                        Next payment:
                    </span>
                    <span className="manage-cover-card__date__item">
                        {formatISO8601DateTime(renewalTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                    </span>
                </div>
            )}
            {!renewalTime && status !== CoverStatus.ENDING && (
                <div className="manage-cover-card__date">
                    <span className="manage-cover-card__date__label">
                        {status === CoverStatus.INACTIVE ? 'Ended:' : 'Ends:'}
                    </span>
                    <span className="manage-cover-card__date__item">
                        {formatISO8601DateTime(endTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                    </span>
                </div>
            )}
            {status === CoverStatus.ENDING && (
                <div className="manage-cover-card__date">
                    <span className="manage-cover-card__date__label">Ending:</span>
                    <span className="manage-cover-card__date__item">
                        {formatISO8601DateTime(endTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                    </span>
                </div>
            )}
            {!!destinations && <span className="manage-cover-card__destinations">{destinations.join(', ')}</span>}
            {status === CoverStatus.ENDING && paymentStatus !== PaymentStatus.FAILED && (
                <span className="manage-cover-card__terms">
                    You can always start cover again after it ends. Just be sure to check the latest PDS in case things
                    have changed.
                </span>
            )}
            {paymentStatus === PaymentStatus.FAILED && (
                <span className="manage-cover-card__terms">
                    Sorry, your payment’s failed. You’ll need to update your payment details in{' '}
                    <button
                        type="button"
                        className="manage-cover-card__terms__billing-link"
                        onClick={goToBillingCenter}
                    >
                        <strong>Managing Billing and Payments</strong>
                    </button>{' '}
                    and pay any overdue invoice to avoid your cover ending..
                </span>
            )}
            <div className="manage-cover-card__footer">
                <small className="manage-cover-card__footer__timezone">
                    Times displayed in {getTimezone(timeZone, defaultDate)}
                </small>
                <small className="manage-cover-card__footer__pds">PDS version {pdsVersion}</small>
            </div>
            <SlideInCoverTerms
                coverCode={coverCode as CoverCode}
                pdsVersion={pdsVersion}
                isOpen={selectedCoverForBenefits === coverCode}
                onOpen={() => {
                    setSelectedCover((coverCode as CoverCode) ?? null);
                }}
                onClose={() => setSelectedCover(null)}
                canCancel={canCancel}
                initiateCancellation={() => initiateCancellation()}
            />
        </div>
    );
};

export default ManageCoverCard;
