import LocalizedStrings from 'react-localization';

const datePickerModal = new LocalizedStrings({
    en: {
        singleTitle: 'Select your start date',
        multipleTitle: 'Select the days you want cover for',
        weekTitle: 'Select one week at a time',
        scheduleInfo: 'Schedule up to 12 months ahead',
    },
});

export default datePickerModal;
