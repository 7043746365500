import { FC, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import './SlideInDownTransition.scss';

export interface SlideInDownTransitionProps {
    children: ReactNode;
    in: boolean;
}

const SlideInDownTransition: FC<SlideInDownTransitionProps> = ({ in: inProp, children }) => {
    return (
        <div className="slide-in-down-wrapper">
            <CSSTransition mountOnEnter in={inProp} timeout={500} classNames="slide-in-down" unmountOnExit>
                <div>{children}</div>
            </CSSTransition>
        </div>
    );
};

export default SlideInDownTransition;
