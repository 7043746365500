import React, { ReactNode } from 'react';
import classNames from 'classnames';
import './CoverStatusCard.scss';

interface CoverStatusCardProps {
    heading?: ReactNode;
    errorMessage?: string;
    isCoverActive?: boolean;
    children?: ReactNode;
    className?: string;
}

const CoverStatusCard: React.FC<CoverStatusCardProps> = ({
    heading,
    errorMessage,
    isCoverActive,
    children,
    className,
}) => {
    return (
        <div
            className={classNames(
                'cover-status-card-beta',
                !isCoverActive && 'cover-status-card-beta--inactive',
                className,
            )}
        >
            {!!heading && <div className="cover-status-card-beta__header">{heading}</div>}
            {children}
            {errorMessage && <p className="cover-status-card-beta__error">{errorMessage}</p>}
        </div>
    );
};

export default CoverStatusCard;
