import { createMachine } from 'xstate';
import { ProductOption } from '../../../business-logic/models/ProductGroupsResponse';
import { Destination } from '../../../business-logic/models/RoamingDestinations';
import {
    setChosenDestinations,
    trackDestinationsSelected,
    updateMachineContextFromGlobalContext,
} from './actions/roamingDestinationsBaymaxMachineActions';
import { RoamingDestinationsBaymaxMachineContextTypes } from './context/roamingDestinationsBaymaxMachineContext';
import { hasChosenMultipleDestinations, hasMultipleRegions } from './guards/roamingDestinationsBaymaxMachineGuards';

const RoamingDestinationsBaymaxMachine = createMachine(
    {
        id: 'RoamingDestinationsBaymaxMachine',
        initial: 'waitForContextInitialisation',
        schema: {
            context: {} as RoamingDestinationsBaymaxMachineContextTypes,
            events: {} as
                | { type: 'SELECTED_DESTINATIONS'; data: string[] }
                | {
                      type: 'CONTEXT_INITIALISED';
                      data: {
                          selectedProductOption: ProductOption;
                          destinations: Destination[];
                      };
                  }
                | { type: 'CONTINUE' },
        },
        tsTypes: {} as import('./roamingDestinationsBaymaxMachine.typegen').Typegen0,
        context: {
            products: [],
            productGroups: [],
            selectedProductGrouping: '',
            selectedProductOption: null,
            availableDestinations: [],
            chosenDestinations: [],
        },
        preserveActionOrder: true,
        states: {
            waitForContextInitialisation: {
                tags: ['loading'],
                on: {
                    CONTEXT_INITIALISED: {
                        actions: 'updateMachineContextFromGlobalContext',
                        target: 'idle',
                    },
                },
            },
            idle: {
                on: {
                    SELECTED_DESTINATIONS: {
                        actions: 'setChosenDestinations',
                    },
                    CONTINUE: {
                        target: 'determineHowManyDestinations',
                    },
                },
            },
            determineHowManyDestinations: {
                tags: ['loading'],
                always: [
                    {
                        cond: 'hasChosenMultipleDestinations',
                        target: 'chooseStartingDestination',
                    },
                    {
                        target: 'determineHowManyRegions',
                    },
                ],
            },
            determineHowManyRegions: {
                tags: ['loading'],
                always: [
                    {
                        cond: 'hasMultipleRegions',
                        target: 'chooseStartingRegion',
                    },
                    {
                        target: 'selectRoamingDates',
                    },
                ],
            },
            selectRoamingDates: {
                entry: ['redirectToSelectDates', 'trackDestinationsSelected'],
                tags: ['loading'],
                type: 'final',
            },
            chooseStartingRegion: {
                entry: ['redirectToChooseStartingRegion', 'trackDestinationsSelected'],
                tags: ['loading'],
                type: 'final',
            },
            chooseStartingDestination: {
                entry: ['redirectToChooseStartingDestination', 'trackDestinationsSelected'],
                tags: ['loading'],
                type: 'final',
            },
        },
    },
    {
        actions: {
            setChosenDestinations,
            updateMachineContextFromGlobalContext,
            trackDestinationsSelected,
        },
        guards: {
            hasChosenMultipleDestinations,
            hasMultipleRegions,
        },
    },
);

export default RoamingDestinationsBaymaxMachine;
