import { CartRequestItem } from '../business-logic/models/Cart';
import ProductGroupsResponse from '../business-logic/models/ProductGroupsResponse';
import ProductResponse from '../business-logic/models/ProductResponse';
import { PurchaseState } from '../types/PurchaseState';
import getScheduledStartTime from './getScheduledStartTime';

// Baymax
const getCartRequestItems = (
    purchaseState: PurchaseState,
    products: ProductResponse[],
    productGroups: ProductGroupsResponse,
    userTimeZone: string,
    insuredPersons: {
        accountHolder: boolean;
        dependants: string[];
    },
): CartRequestItem[] => {
    // Get cover codes in selected product option
    const selectedProductGroup = productGroups.find(
        (group) => group.id.toLowerCase() === purchaseState?.selectedProductGrouping?.toLowerCase(),
    );
    const coverCodes = selectedProductGroup?.options.find((x) =>
        x.coverCodes.includes(purchaseState.selectedProductOption?.representedByCoverCode ?? ''),
    )?.coverCodes;
    // For each cover code to add to cart
    return coverCodes!.reduce<CartRequestItem[]>((acc, cur) => {
        const coverCodeProduct = products.find((product) => product.productSpec.mainCoverType.coverCode === cur);

        // Check if requiredInsuredPersonRelationship is 'Dependant'
        const isDependantCover =
            coverCodeProduct?.productSpec.mainCoverType.requiredInsuredPersonRelationship?.toLowerCase() ===
            'dependant';

        // If dependant cover
        if (isDependantCover) {
            // For each dependant
            insuredPersons.dependants.forEach((dependant) => {
                // For each start date
                purchaseState.coverStartDates.forEach((startDate) => {
                    acc.push({
                        coverCode: cur,
                        scheduledStartTime: getScheduledStartTime(
                            startDate,
                            purchaseState.destination?.timezone || userTimeZone.toString(),
                        ),
                        personId: dependant,
                        insurancePolicyId: null, // TODO: check for extras cover to return policyID, otherwise return null
                        insuranceProductId: coverCodeProduct.insuranceProductId || '',
                        coverTypeId: coverCodeProduct.productSpec.mainCoverTypeId, // TODO: Assuming no extra covers for now
                        destination:
                            Object.hasOwn(purchaseState, 'destination') && purchaseState.destination !== null
                                ? {
                                      destinations: purchaseState.destination.destinations,
                                      startingRegion: {
                                          region: purchaseState.destination.startingRegion,
                                          timeZone: purchaseState.destination.timezone,
                                      },
                                  }
                                : undefined,
                        timeZone: purchaseState.destination?.timezone || undefined,
                    });
                });
            });
        }

        // If not dependant cover and account holder is insured person
        if (!isDependantCover && insuredPersons.accountHolder) {
            // For each start date
            purchaseState.coverStartDates.forEach((startDate) => {
                acc.push({
                    coverCode: cur,
                    scheduledStartTime: getScheduledStartTime(
                        startDate,
                        purchaseState.destination?.timezone || userTimeZone.toString(),
                    ),
                    personId: null,
                    insurancePolicyId: null, // TODO: check for extras cover to return policyID, otherwise return null
                    insuranceProductId: coverCodeProduct!.insuranceProductId || '',
                    coverTypeId: coverCodeProduct!.productSpec.mainCoverTypeId, // TODO: Assuming no extra covers for now
                    destination:
                        Object.hasOwn(purchaseState, 'destination') && purchaseState.destination !== null
                            ? {
                                  destinations: purchaseState.destination.destinations,
                                  startingRegion: {
                                      region: purchaseState.destination.startingRegion,
                                      timeZone: purchaseState.destination.timezone,
                                  },
                              }
                            : undefined,
                    timeZone: purchaseState.destination?.timezone || undefined,
                });
            });
        }

        return acc;
    }, []);
};

export default getCartRequestItems;
