import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import requireFlags from '../require-flags/requireFlags';

/**
 * Helper HOC that'll wait for the flags to be initialised and then
 * render either the feature component or fallback component based on
 * the evaluation of the feature flag provided
 *
 * @param featureFlag feature flag to evaluate
 * @param FeatureComponent the feature component to render if flag is true
 * @param FallbackComponent the fallback component if flag is false
 * @returns FC
 */
function withFeatureComponent<T extends JSX.IntrinsicAttributes>(
    featureFlag: string,
    FeatureComponent: FC<T>,
    FallbackComponent: FC<T>,
): FC<T> {
    const WrappedComponent: FC<T> = (props) => {
        const flags = useFlags();

        if (flags && flags[featureFlag]) {
            return <FeatureComponent {...(props as T)} />;
        }
        return <FallbackComponent {...(props as T)} />;
    };

    return requireFlags(WrappedComponent);
}

export default withFeatureComponent;
