import cancelIcon from '../../../../assets/images/cancel-circle.svg';
import { AllCoverInformation } from '../../../../business-logic/models/cdn-content/CoverInformation';
import { Policy } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { ActionItemProps } from '../../../../components/action-list/action-item/ActionItem';
import coverListCardContent from '../../../../content/ui/components/cover-list-card/coverListCard';
import DateFormat from '../../../../utils/constants/DateFormat';
import formatEndingTime from '../../../../utils/formatEndingTime';
import unixNumToString from '../../../../utils/unixNumToString';
import ActiveBadge from '../active-badge/ActiveBadge';
import renderPdsVersion from './renderPdsVersion';

const createSingleCoverListItem = (
    singleCover: Policy,
    timeZone: string,
    coverInformation: AllCoverInformation,
    onCancelCover: (coverId: string) => void,
): ActionItemProps => {
    const productInfo = coverInformation[singleCover.mainCover.coverCode][singleCover.PDSVersion];
    const content = {
        coverScheduled: coverListCardContent.formatString(coverListCardContent.coverScheduled, {
            startDate: unixNumToString(
                singleCover.mainCover.activeFrom,
                singleCover.mainCover.coverTimezone || timeZone,
                DateFormat.VERBOSE_WITH_TIMEZONE,
            ),
        }),
        coverActive: coverListCardContent.formatString(coverListCardContent.coverActive, {
            coverName: productInfo.name,
            endDateTime: (
                <strong>
                    {formatEndingTime(singleCover.mainCover.activeTo, singleCover.mainCover.coverTimezone || timeZone)}
                </strong>
            ),
        }),
        cancelCoverAriaLabel: coverListCardContent.formatString(coverListCardContent.cancelCoverAriaLabel, {
            coverName: productInfo.name,
        }),
    };

    return singleCover.mainCover.status === 'Started'
        ? {
              leftIcon: productInfo.icon,
              rightIcon: <ActiveBadge />,
              header: productInfo.name,
              innerClickTitle: coverListCardContent.manageCoverLabel,
              customDataElement: (
                  <>
                      <p className="cover-list-card__action-item-subtext">{content.coverActive}</p>
                      {singleCover.mainCover.destination && (
                          <p className="cover-list-card__action-item-subtext">
                              <>
                                  {singleCover.mainCover.destination.destinations
                                      .sort((a, b) => a.localeCompare(b))
                                      .join(', ')}
                              </>
                          </p>
                      )}
                      {renderPdsVersion(singleCover.PDSVersion)}
                  </>
              ),
          }
        : {
              leftIcon: productInfo.icon,
              rightIcon: (
                  <button
                      type="button"
                      onClick={() => onCancelCover(singleCover.mainCover.insuranceCoverId)}
                      className="cover-list-card__active-badge-with-cancel-button"
                      aria-label={coverListCardContent
                          .formatString(coverListCardContent.cancelCoverAriaLabel, {
                              coverName: productInfo.name,
                          })
                          .toString()}
                  >
                      <img src={cancelIcon} alt="" className="cover-list-card__active-badge-with-cancel-icon" />
                  </button>
              ),
              header: productInfo.name,
              innerClickTitle: coverListCardContent.manageCoverLabel,
              customDataElement: (
                  <>
                      <p className="cover-list-card__action-item-subtext">{content.coverScheduled}</p>
                      {singleCover.mainCover.destination && (
                          <p className="cover-list-card__action-item-subtext">
                              <>
                                  {singleCover.mainCover.destination.destinations
                                      .sort((a, b) => a.localeCompare(b))
                                      .join(', ')}
                              </>
                          </p>
                      )}
                      {renderPdsVersion(singleCover.PDSVersion)}
                  </>
              ),
              ariaLabel: content.cancelCoverAriaLabel as string,
          };
};

export default createSingleCoverListItem;
