// eslint-disable-next-line import/no-unresolved, import/extensions
import { Destination } from '../../../../business-logic/models/RoamingDestinations';
import { RoamingDestinationsBaymaxMachineContextTypes } from '../context/roamingDestinationsBaymaxMachineContext';

export const hasChosenMultipleDestinations = (ctx: RoamingDestinationsBaymaxMachineContextTypes): boolean =>
    ctx.chosenDestinations.length > 1;

export const hasMultipleRegions = (ctx: RoamingDestinationsBaymaxMachineContextTypes): boolean => {
    return (
        !!ctx.availableDestinations?.find((item: Destination) => item.destination === ctx.chosenDestinations[0])
            ?.regions &&
        ctx.availableDestinations?.find((item: Destination) => item.destination === ctx.chosenDestinations[0])!.regions
            .length > 1
    );
};

export const isRoamingSelected = (ctx: RoamingDestinationsBaymaxMachineContextTypes): boolean =>
    ctx.selectedProductGrouping.toLowerCase().includes('fliproaming') && !!ctx.selectedProductOption;
