import classNames from 'classnames';
import { ButtonHTMLAttributes, FC, useMemo } from 'react';
import usePrevious from '../../hooks/usePrevious';
import Button, { ButtonProps } from '../button/Button';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import SuccessTick from '../success-tick/SuccessTick';

import './LoadingButton.scss';

export interface LoadingButtonProps extends ButtonProps {
    status: 'idle' | 'loading' | 'success';
    onSuccessAnimationComplete?: () => void;
    buttonVariant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'link'
        | 'primary-invert'
        | 'secondary-invert'
        | 'drawer-link';
}

const LoadingButton: FC<LoadingButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
    status,
    onSuccessAnimationComplete,
    className,
    buttonVariant = 'primary',
    ...rest
}) => {
    const label = useMemo(() => {
        if (status === 'success') {
            return (
                <>
                    <SuccessTick
                        size="100%"
                        eventListeners={
                            onSuccessAnimationComplete
                                ? [{ eventName: 'complete', callback: onSuccessAnimationComplete }]
                                : undefined
                        }
                        style={{
                            position: 'absolute',
                            left: '50%',
                            right: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 1,
                        }}
                    />
                </>
            );
        }
        if (status === 'loading') {
            return <LoadingSpinner transparentBg size="100%" />;
        }
        return rest.label;
    }, [onSuccessAnimationComplete, rest.label, status]);

    const prevStatus = usePrevious(status);

    // This stops the animation when returning back to a non-success state from a success state
    const disableSuccessBgAnimation = useMemo(() => {
        return prevStatus === 'success' && status !== 'success';
    }, [status, prevStatus]);

    return (
        <div className="loading-button__wrapper">
            <Button
                variant={buttonVariant}
                className={classNames(
                    'loading-button',
                    {
                        'loading-button--animating': status !== 'idle',
                        'loading-button__success': status === 'success',
                        'loading-button__success--disable-animate': disableSuccessBgAnimation,
                    },
                    className,
                )}
                {...rest}
                label={
                    <>
                        {label}
                        <span
                            className={classNames('loading-button__success-bg', {
                                'loading-button__success-bg--animate': status === 'success',
                                'loading-button__success-bg--disable-animate': disableSuccessBgAnimation,
                            })}
                        />
                    </>
                }
            />
        </div>
    );
};

export default LoadingButton;
