import LocalizedStrings from 'react-localization';

const roamingDatesContent = new LocalizedStrings({
    en: {
        heading: 'Select your dates',
        paymentOptionLegend: 'Choose a payment option',
        selectCoverDates: 'Select your cover dates',
        continueCTA: 'Continue to checkout',
        benefitsCTA: 'Check all the details, exclusions and the countries we cover',
    },
});

export default roamingDatesContent;
