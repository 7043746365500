import React from 'react';
import classNames from 'classnames';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Layout } from '../layout/Layout';
import AnnouncementKeys from '../announcements/AnnouncementKeys';

import './Dashboard.scss';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';

interface DashboardCardProps {
    children?: React.ReactNode;
    className?: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = ({ children, className }): React.ReactElement => (
    <div className={classNames('dashboard__card', className)}>{children}</div>
);

export interface DashboardProps {
    children?: React.ReactNode;
    title: string;
    centerTitle?: boolean;
    className?: string;
    loading?: boolean;
}

export const Dashboard: React.FC<DashboardProps> = ({ children, className, title, loading, centerTitle = false }) => {
    const { loading: userLoading } = useUser();
    const { openCoversLoading: coverLoading } = useOpenCovers();

    const isLoading = userLoading || coverLoading || loading;

    return (
        <div className="dashboard">
            <Layout
                isDashboard
                className={classNames('dashboard__layout', className)}
                nav
                bgPattern={false}
                showLoading={isLoading}
                showAnnouncement={AnnouncementKeys.MOBILE_APP_LAUNCH}
            >
                {!isLoading && (
                    <>
                        <h1 className={classNames('dashboard__title', centerTitle && 'dashboard__title--centered')}>
                            {title}
                        </h1>
                        {children}
                    </>
                )}
            </Layout>
        </div>
    );
};

export default Dashboard;
