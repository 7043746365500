import { Redirect } from 'react-router-dom';
import { useUser } from '../../business-logic/context-provider/user-context';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import StorageKeys from '../../utils/constants/StorageKeys';
import Routes from '../../utils/Routes';

/**
 * Redirects the user to onboarding start (How It Works page) if the user has not filled out their profile
 * Not filling out their profile indicates that they have not gone through their initial purchase flow/onboarding
 * Wrapping the dashboard components in this prevents any additional refresh calls that are made
 * on the dashboard when the user first logs in by not rendering the dashboard component just yet
 * if we're still waiting to determine if we need to redirect
 *
 * @param WrappedComponent Component to render if user is onboarded
 * @returns React.FC
 */
function redirectToOnboarding<T extends JSX.IntrinsicAttributes>(WrappedComponent: React.FC<T>): React.FC<T> {
    return (props: any) => {
        const { profileCompleted, initialised } = useUser();

        if (!initialised) {
            return <LoadingSpinnerOverlay />;
        }

        if (!profileCompleted) {
            const isInCartFlow = sessionStorage.getItem(StorageKeys.CART_COVER_SELECTION);

            if (isInCartFlow) {
                return (
                    <Redirect
                        to={{
                            pathname: Routes.CART,
                        }}
                    />
                );
            }

            return (
                <Redirect
                    to={{
                        pathname: Routes.DATE_OF_BIRTH,
                        state: { isOnboarding: true },
                    }}
                />
            );
        }

        return <WrappedComponent {...props} />;
    };
}

export default redirectToOnboarding;
