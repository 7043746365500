import { ChangeEvent, FC, useRef } from 'react';
import dateOfBirthInput from '../../content/ui/components/date-of-birth-input/dateOfBirthInput';
import withContent from '../../hoc/with-content/withContent';
import InputType from '../../utils/constants/InputType';
import TextField from '../text-field/TextField';
import Fieldset from '../form/fieldset/Fieldset';

import './DateOfBirthInput.scss';

const contentMap = {
    day: 'ui.day',
    month: 'ui.month',
    year: 'ui.year',
};

interface DateOfBirthInputProps {
    content: Record<keyof typeof contentMap, string>;
    day: string;
    month: string;
    year: string;
    hasError: boolean;
    onDayChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onMonthChange: (month: ChangeEvent<HTMLInputElement>) => void;
    onYearChange: (year: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    disabled?: boolean;
    form?: string;
}

const DateOfBirthInput: FC<DateOfBirthInputProps> = ({
    content,
    day,
    month,
    year,
    hasError,
    onDayChange,
    onMonthChange,
    onYearChange,
    onBlur,
    disabled,
    form,
}) => {
    const dayInput = useRef<HTMLInputElement>(null);
    const monthInput = useRef<HTMLInputElement>(null);
    const yearInput = useRef<HTMLInputElement>(null);

    const handleDayChange = (e: ChangeEvent<HTMLInputElement>) => {
        onDayChange(e);
        if (e.target.value.length === 2) {
            monthInput.current?.focus();
        }
    };

    const handleMonthChange = (e: ChangeEvent<HTMLInputElement>) => {
        onMonthChange(e);
        if (e.target.value.length === 2) {
            yearInput.current?.focus();
        }
    };

    return (
        <Fieldset className="date-of-birth-input__btn-group" legend="Date of birth">
            <TextField
                innerRef={dayInput}
                name="day"
                label={content.day}
                placeholder="DD"
                maxLength={2}
                value={day}
                isError={hasError}
                onChange={handleDayChange}
                onBlur={onBlur}
                type={InputType.NUMBER}
                inputMode="numeric"
                disabled={disabled}
                form={form}
            />
            <TextField
                innerRef={monthInput}
                name="month"
                label={content.month}
                placeholder="MM"
                maxLength={2}
                value={month}
                isError={hasError}
                onChange={handleMonthChange}
                onBlur={onBlur}
                type={InputType.NUMBER}
                inputMode="numeric"
                disabled={disabled}
                form={form}
            />
            <TextField
                innerRef={yearInput}
                name="year"
                label={content.year}
                placeholder="YYYY"
                maxLength={4}
                value={year}
                isError={hasError}
                onChange={onYearChange}
                onBlur={onBlur}
                type={InputType.NUMBER}
                inputMode="numeric"
                disabled={disabled}
                form={form}
            />
        </Fieldset>
    );
};

export default withContent(DateOfBirthInput, contentMap, dateOfBirthInput);
