import React from 'react';
import chevron from '../../../assets/images/chevron-right-harbour.svg';

import './ActionItem.scss';

interface ActionItemContentProps {
    header?: string | React.ReactNode;
    data?: string | React.ReactNode;
    customDataElement?: React.ReactElement;
    leftIcon?: string;
    leftIconAlt?: string;
    rightIcon?: string | React.ReactElement | null;
    rightIconAlt?: string;
    additionalContext?: React.ReactNode;
    onInnerClick?: () => void;
    innerClickTitle?: string;
}

const ActionItemContent: React.FC<ActionItemContentProps> = ({
    header,
    data = '',
    customDataElement,
    leftIcon,
    rightIcon = chevron,
    leftIconAlt,
    rightIconAlt = 'chevron',
    additionalContext,
    onInnerClick,
    innerClickTitle = '',
}) => (
    <>
        <div className="action-item__main-content">
            <div className="action-item__content">
                {leftIcon && <img className="action-item__left-icon" src={leftIcon} alt={leftIconAlt} />}
                {onInnerClick ? (
                    <button
                        type="button"
                        onClick={onInnerClick}
                        className="action-item__content__trigger"
                        title={innerClickTitle}
                    >
                        {header && <h3 className="action-item__header">{header}</h3>}
                        {data && <p className="action-item__data">{data}</p>}
                        {customDataElement && customDataElement}
                    </button>
                ) : (
                    <div>
                        {header && <h3 className="action-item__header">{header}</h3>}
                        {data && <p className="action-item__data">{data}</p>}
                        {customDataElement && customDataElement}
                    </div>
                )}
            </div>
            {rightIcon &&
                (typeof rightIcon === 'string' ? (
                    <img className="action-item__right-icon" src={rightIcon} alt={rightIconAlt} />
                ) : (
                    rightIcon
                ))}
        </div>
        {additionalContext || null}
    </>
);

export type ActionItemProps = ActionItemContentProps & { disabled?: boolean; ariaLabel?: string; onClick?: () => void };

export const ActionItem: React.FC<ActionItemProps> = ({ disabled, ariaLabel, onClick, ...rest }) =>
    onClick ? (
        <li className="action-item">
            <button
                className="action-item__button-wrapper"
                onClick={onClick}
                disabled={disabled}
                type="button"
                aria-label={ariaLabel}
            >
                <ActionItemContent {...rest} />
            </button>
        </li>
    ) : (
        <li className="action-item">
            <ActionItemContent {...rest} />
        </li>
    );

export default ActionItem;
