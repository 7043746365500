import Routes, { Route } from '../utils/Routes';
import { Category, PageEvent } from './Analytics.d';

const PageEvents: Record<Route, PageEvent | null> = {
    [Routes.HOME]: [Category.DASHBOARD, 'Dashboard'],
    [Routes.LOGIN]: [Category.AUTHENTICATION, 'Login'],
    [Routes.FORGOT_PASSWORD]: [Category.AUTHENTICATION, 'Forgot password'],

    // Onboarding
    [Routes.LANDING]: [Category.ONBOARDING, 'Landing page'],
    [Routes.REGISTER]: [Category.ONBOARDING, 'Create account'],
    [Routes.YOUR_ACTIVITIES]: [Category.ONBOARDING, 'Your activities'],

    // Checkout
    [Routes.RESIDENCY_STATE]: [Category.CHECKOUT, 'State of residence'],
    [Routes.RESIDENCY_STATUS]: [Category.CHECKOUT, 'Residency status'],
    [Routes.DATE_OF_BIRTH]: [Category.CHECKOUT, 'Date of birth'],
    [Routes.GENDER]: [Category.CHECKOUT, 'Gender'],
    [Routes.NAME]: [Category.CHECKOUT, 'Name'],
    [Routes.SCHEDULE_ALWAYS_ON_SUCCESS]: [Category.CHECKOUT, 'Schedule always on success'],
    [Routes.SCHEDULE_BOOST_SUCCESS]: [Category.CHECKOUT, 'Schedule boost success'],
    [Routes.CHECKOUT]: [Category.CHECKOUT, 'Checkout'],
    [Routes.BEFORE_YOU_GO]: [Category.CHECKOUT, 'Before you go'],
    [Routes.SCHEDULE_ACTIVE_COVER_BAYMAX]: [Category.CHECKOUT, 'Purchase options'],
    [Routes.SCHEDULE_KIDS_COVER_BAYMAX]: [Category.CHECKOUT, 'Purchase options'],
    [Routes.SELECT_DATES]: [Category.CHECKOUT, 'Select your dates'],

    // Profile
    [Routes.ACCOUNT]: [Category.ACCOUNT, 'Account'],
    [Routes.ACCOUNT_DETAILS]: [Category.ACCOUNT, 'Account details'],
    [Routes.ACCOUNT_PREFERENCES]: [Category.ACCOUNT, 'Preferences'],
    [Routes.ACCOUNT_CERTIFICATE_OF_COVER]: [Category.ACCOUNT, 'Certificate of cover'],
    [Routes.MANAGE_COVER]: [Category.ACCOUNT, 'Manage Cover'],

    // Intermediate states - do not track, mark with null
    [Routes.LOGIN_CALLBACK]: null,
    [Routes.LOADING]: null,
    [Routes.NOT_FOUND]: null,

    [Routes.SELECT_COVER_BAYMAX]: [Category.CHECKOUT, 'Select Cover'],
    [Routes.COVER_DETAILS]: [Category.CHECKOUT, 'Your selected cover'],

    // Roaming
    [Routes.ROAMING_DESTINATIONS_BAYMAX]: [Category.CHECKOUT, 'Select Destinations'],
    [Routes.ROAMING_START_BAYMAX]: [Category.CHECKOUT, 'Select Starting Destination'],
    [Routes.ROAMING_REGION_BAYMAX]: [Category.CHECKOUT, 'Select Starting Region'],
    [Routes.ROAMING_DATES_BAYMAX]: [Category.CHECKOUT, 'Schedule Cover'],

    [Routes.CART]: [Category.CHECKOUT, 'Cart'],
    [Routes.CART_BAYMAX]: [Category.CHECKOUT, 'Cart'],
    [Routes.CART_SUCCESS]: [Category.CHECKOUT, 'Cart Checkout Success'],
    [Routes.CART_BAYMAX_SUCCESS]: [Category.CHECKOUT, 'Cart Checkout Success'],
};

export default PageEvents;
