import { FC } from 'react';
import Fieldset from '../../../components/form/fieldset/Fieldset';
import RadioButton from '../../../components/form/radio-button/RadioButton';
import TextField from '../../../components/text-field/TextField';
import onboardingFlow from '../../../strings/onboardingFlow';

import './BeforeYouGoFormBody.scss';

interface BeforeYouGoFormBodyProps {
    reason: string;
    setReason: (reason: string) => void;
    setElseReason: (reason: string) => void;
}

const BeforeYouGoFormBody: FC<BeforeYouGoFormBodyProps> = ({ reason, setReason, setElseReason }) => {
    const {
        beforeYouGo: { didNotWork, reasons, somethingElse, tellUsMore, whatsMissing },
    } = onboardingFlow;

    return (
        <>
            <Fieldset legend={didNotWork} className="before-you-go-form-body__fieldset">
                {Object.entries(reasons).map(([key, value]) => (
                    <RadioButton
                        id={key}
                        key={key}
                        name="reasons"
                        className="before-you-go-form-body__radio-button"
                        label={value}
                        checked={reason === value}
                        onChange={() => setReason(value)}
                    />
                ))}

                <RadioButton
                    id="somethingElse"
                    name="reasons"
                    className="before-you-go-form-body__radio-button"
                    label={somethingElse}
                    checked={reason === somethingElse}
                    onChange={() => setReason(somethingElse)}
                />
            </Fieldset>
            {reason && reason !== reasons.dontNeed && (
                <TextField
                    className="before-you-go-form-body__text-field"
                    name="elseReason"
                    label={reason === reasons.doesNotCover ? whatsMissing : tellUsMore}
                    onChange={(e) => setElseReason(e.target.value)}
                />
            )}
        </>
    );
};

export default BeforeYouGoFormBody;
