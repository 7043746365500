import { assign, spawn } from 'xstate';
import UserDetails from '../../../../../business-logic/models/UserDetails';
import kidDetailsFormMachine from '../../../../../components/kid-details-form/kid-details-form-machine/kidDetailsFormMachine';
import { PersonsInsuredBaymaxMachineContextTypes } from '../context/personsInsuredBaymaxMachineContext';
import { Typegen0 } from '../persons-insured-baymax-machine/personsInsuredBaymaxMachine.typegen';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const spawnKidDetailsFormMachine = assign<
    PersonsInsuredBaymaxMachineContextTypes,
    { type: EventsCausingActions['spawnKidDetailsFormMachine'] }
>({
    kidDetailsFormMachineRef: () => spawn(kidDetailsFormMachine, { sync: true }),
}) as any;

export const setSelectedPersons = assign<
    PersonsInsuredBaymaxMachineContextTypes,
    { type: EventsCausingActions['setSelectedPersons']; data: { selectedPersons: string[] } }
>({
    selectedPersons: (ctx, event) => event.data.selectedPersons,
    isAccountHolderInsured: (ctx, event) => event.data.selectedPersons.includes('myself'),
}) as any;

export const setRecentlyAddedKid = assign<
    PersonsInsuredBaymaxMachineContextTypes,
    { type: EventsCausingActions['setRecentlyAddedKid']; data: UserDetails }
>({
    selectedPersons: (ctx, event) => {
        return [...ctx.selectedPersons, event.data.personId!];
    },
}) as any;
