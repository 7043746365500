import { assign } from 'xstate';
import { Destination } from '../../../../business-logic/models/RoamingDestinations';
import { RoamingStartDestinationBaymaxMachineContextTypes } from '../context/roamingStartDestinationBaymaxMachineContext';
import { Typegen0 } from '../roamingStartDestinationBaymaxMachine.typegen';
import Analytics from '../../../../analytics/Analytics';

type EventsCausingActions = Typegen0['eventsCausingActions'];

export const setStartingDestination = assign<
    RoamingStartDestinationBaymaxMachineContextTypes,
    { type: EventsCausingActions['setStartingDestination']; data: string | null }
>({
    startDestination: (ctx, event) => {
        return event.data;
    },
}) as any;

export const setAllDestinations = assign<
    RoamingStartDestinationBaymaxMachineContextTypes,
    {
        type: EventsCausingActions['setAllDestinations'];
        data: {
            availableDestinations: Destination[];
        };
    }
>({
    availableDestinations: (ctx, event) => event.data.availableDestinations,
}) as any;

export const trackStartDestinationSelected = (ctx: RoamingStartDestinationBaymaxMachineContextTypes): void => {
    if (!ctx.startDestination) return;
    Analytics.trackStartDestinationSelected(ctx.startDestination);
};
