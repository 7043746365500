import LocalizedStrings from 'react-localization';

const name = new LocalizedStrings({
    en: {
        heading: 'Lastly, what is your name?',
        disclaimer: 'This will be used for any future insurance purchases.',
        firstName: 'First Name',
        lastName: 'Last Name',
        seePrices: 'See Prices',
    },
});

export default name;
