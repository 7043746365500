import { SetupIntent, Stripe, StripeElements, StripeError } from '@stripe/stripe-js';
import { ActorRefFrom, assign, createMachine, sendParent, spawn } from 'xstate';
import Analytics from '../../../../analytics/Analytics';
import SetupIntentResponse from '../../../../business-logic/models/SetupIntent';
import { UserPaymentMethod } from '../../../../business-logic/models/UserPaymentMethod';
import PaymentService from '../../../../services/payment-service/PaymentService';
import stripePaymentElementMachine from '../stripe-payment-element/stripe-payment-element-machine/stripePaymentElementMachine';

export type PAYMENT_ELEMENT_MACHINE_TAGS =
    | 'LOADING_SETUP_INTENT'
    | 'VALID_CARD'
    | 'CHECKOUT_LOADING'
    | 'CHECKOUT_SUCCESS';

const paymentElementMachine = createMachine(
    {
        id: 'PaymentElementMachine',
        initial: 'init',
        tsTypes: {} as import('./paymentElementMachine.typegen').Typegen0,
        schema: {
            context: {} as {
                useGuestCheckout?: boolean;
                accessToken: string;
                defaultCard?: UserPaymentMethod;
                stripePaymentElementMachine?: ActorRefFrom<typeof stripePaymentElementMachine>;
                paymentMethodId: string;
                setupCardError: string;
                hasSubscriptionInCheckout: boolean;
                paymentId: string;
                setupIntent?: SetupIntentResponse;
            },
            events: {} as
                | { type: 'CHANGE_CARD_DETAILS' }
                | { type: 'CANCEL_UPDATE_CARD' }
                | { type: 'SETUP_INTENT_TRY_AGAIN' }
                | { type: 'SAVED_CARD_CLICK_PAY' }
                | {
                      type: 'NEW_CARD_CLICK_PAY';
                      data: { stripe: Stripe; elements: StripeElements };
                  }
                | { type: 'PAYMENT_DETAILS_COMPLETE' }
                | { type: 'PAYMENT_DETAILS_INCOMPLETE' }
                | { type: 'CHECKOUT_SUCCESS' }
                | { type: 'CHECKOUT_ERROR' },
        },
        states: {
            init: {
                always: [
                    {
                        cond: 'hasDefaultCard',
                        target: 'defaultCard',
                    },
                    {
                        target: 'newCard',
                    },
                ],
            },
            defaultCard: {
                initial: 'readyToPay',
                states: {
                    readyToPay: {
                        tags: ['VALID_CARD'],
                        entry: ['setDefaultCardPaymentMethodId'],
                        on: {
                            CHANGE_CARD_DETAILS: {
                                target: 'collectCardDetails',
                            },
                            SAVED_CARD_CLICK_PAY: {
                                actions: 'initiateCheckout',
                                target: 'awaitCheckoutCompletion',
                            },
                        },
                    },
                    collectCardDetails: {
                        initial: 'init',
                        states: {
                            init: {
                                entry: 'spawnStripePaymentElementMachine',
                                tags: ['LOADING_SETUP_INTENT'],
                                always: [
                                    {
                                        cond: 'hasSetupIntent',
                                        target: 'idle',
                                    },
                                    {
                                        target: 'loadingSetupIntent',
                                    },
                                ],
                            },
                            loadingSetupIntent: {
                                tags: ['LOADING_SETUP_INTENT'],
                                invoke: {
                                    src: 'getSetupIntent',
                                    onDone: [
                                        {
                                            actions: 'setSetupIntent',
                                            target: 'idle',
                                        },
                                    ],
                                    onError: [
                                        {
                                            target: 'displaySetupIntentError',
                                        },
                                    ],
                                },
                            },
                            displaySetupIntentError: {
                                on: {
                                    SETUP_INTENT_TRY_AGAIN: {
                                        target: 'init',
                                    },
                                },
                            },
                            idle: {
                                on: {
                                    PAYMENT_DETAILS_COMPLETE: {
                                        actions: 'trackPaymentInfoEntered',
                                        target: 'readyToPay',
                                    },
                                },
                            },
                            readyToPay: {
                                tags: ['VALID_CARD'],
                                on: {
                                    NEW_CARD_CLICK_PAY: {
                                        target: 'setupCard',
                                    },
                                    PAYMENT_DETAILS_INCOMPLETE: {
                                        target: 'idle',
                                    },
                                },
                            },
                            setupCard: {
                                entry: 'notifySettingUpPaymentMethod',
                                tags: ['CHECKOUT_LOADING'],
                                invoke: {
                                    src: 'setupCard',
                                    onError: [
                                        {
                                            actions: 'setSetupCardError',
                                            target: 'displaySetupCardError',
                                        },
                                    ],
                                    onDone: [
                                        {
                                            actions: ['setPaymentMethodId', 'initiateCheckout'],
                                            target: 'awaitCheckoutCompletion',
                                        },
                                    ],
                                },
                            },
                            displaySetupCardError: {
                                tags: ['VALID_CARD'],
                                exit: 'clearSetupCardError',
                                on: {
                                    PAYMENT_DETAILS_COMPLETE: {
                                        actions: 'trackPaymentInfoEntered',
                                        target: 'readyToPay',
                                    },
                                    PAYMENT_DETAILS_INCOMPLETE: {
                                        target: 'idle',
                                    },
                                    NEW_CARD_CLICK_PAY: {
                                        target: 'setupCard',
                                    },
                                },
                            },
                            awaitCheckoutCompletion: {
                                tags: ['CHECKOUT_LOADING'],
                                on: {
                                    CHECKOUT_SUCCESS: {
                                        target: 'awaitRedirect',
                                    },
                                    CHECKOUT_ERROR: {
                                        target: 'readyToPay',
                                    },
                                },
                            },
                            awaitRedirect: {
                                tags: ['CHECKOUT_SUCCESS'],
                                type: 'final',
                            },
                        },
                        on: {
                            CANCEL_UPDATE_CARD: {
                                target: 'readyToPay',
                            },
                        },
                    },
                    awaitRedirect: {
                        tags: ['CHECKOUT_SUCCESS'],
                        type: 'final',
                    },
                    awaitCheckoutCompletion: {
                        tags: ['CHECKOUT_LOADING'],
                        on: {
                            CHECKOUT_SUCCESS: {
                                target: 'awaitRedirect',
                            },
                            CHECKOUT_ERROR: {
                                target: 'readyToPay',
                            },
                        },
                    },
                },
            },
            newCard: {
                initial: 'loadingSetupIntent',
                states: {
                    loadingSetupIntent: {
                        entry: 'spawnStripePaymentElementMachine',
                        tags: ['LOADING_SETUP_INTENT'],
                        invoke: {
                            src: 'getSetupIntent',
                            onDone: [
                                {
                                    actions: 'setSetupIntent',
                                    target: 'idle',
                                },
                            ],
                            onError: [
                                {
                                    target: 'displaySetupIntentError',
                                },
                            ],
                        },
                    },
                    displaySetupIntentError: {
                        on: {
                            SETUP_INTENT_TRY_AGAIN: {
                                target: 'loadingSetupIntent',
                            },
                        },
                    },
                    idle: {
                        on: {
                            PAYMENT_DETAILS_COMPLETE: {
                                actions: 'trackPaymentInfoEntered',
                                target: 'readyToPay',
                            },
                        },
                    },
                    readyToPay: {
                        tags: ['VALID_CARD'],
                        on: {
                            NEW_CARD_CLICK_PAY: {
                                target: 'setupCard',
                            },
                            PAYMENT_DETAILS_INCOMPLETE: {
                                target: 'idle',
                            },
                        },
                    },
                    setupCard: {
                        entry: 'notifySettingUpPaymentMethod',
                        tags: ['CHECKOUT_LOADING'],
                        invoke: {
                            src: 'setupCard',
                            onDone: [
                                {
                                    actions: ['setPaymentMethodId', 'initiateCheckout'],
                                    target: 'awaitCheckoutCompletion',
                                },
                            ],
                            onError: [
                                {
                                    actions: 'setSetupCardError',
                                    target: 'displaySetupCardError',
                                },
                            ],
                        },
                    },
                    displaySetupCardError: {
                        tags: ['VALID_CARD'],
                        exit: 'clearSetupCardError',
                        on: {
                            PAYMENT_DETAILS_COMPLETE: {
                                actions: 'trackPaymentInfoEntered',
                                target: 'readyToPay',
                            },
                            PAYMENT_DETAILS_INCOMPLETE: {
                                target: 'idle',
                            },
                            NEW_CARD_CLICK_PAY: {
                                target: 'setupCard',
                            },
                        },
                    },
                    awaitCheckoutCompletion: {
                        tags: ['CHECKOUT_LOADING'],
                        on: {
                            CHECKOUT_SUCCESS: {
                                target: 'awaitRedirect',
                            },
                            CHECKOUT_ERROR: {
                                target: 'readyToPay',
                            },
                        },
                    },
                    awaitRedirect: {
                        tags: ['CHECKOUT_SUCCESS'],
                        type: 'final',
                    },
                },
            },
        },
    },
    {
        guards: {
            hasSetupIntent: (ctx) => !!ctx.setupIntent,
            hasDefaultCard: (ctx) => !!ctx.defaultCard,
        },
        actions: {
            spawnStripePaymentElementMachine: assign({
                stripePaymentElementMachine: (ctx) =>
                    spawn(
                        stripePaymentElementMachine.withContext({
                            hasSubscriptionInCheckout: ctx.hasSubscriptionInCheckout,
                            setAsDefault: true,
                            selectedPaymentMethod: '',
                        }),
                        { sync: true },
                    ),
            }),
            setSetupIntent: assign({ setupIntent: (ctx, event) => event.data as SetupIntentResponse }),
            setDefaultCardPaymentMethodId: assign({
                paymentMethodId: (ctx) => ctx.defaultCard!.paymentMethodID,
            }),
            setPaymentMethodId: assign({
                paymentMethodId: (ctx, event) => (event.data as SetupIntent).payment_method as string,
            }),
            notifySettingUpPaymentMethod: sendParent(() => ({ type: 'SETTING_UP_PAYMENT_METHOD' })),
            initiateCheckout: sendParent((ctx) => ({
                type: 'INITIATE_CHECKOUT',
                data: {
                    paymentMethodId: ctx.paymentMethodId,
                    setAsDefault:
                        typeof ctx.stripePaymentElementMachine?.getSnapshot()?.context.setAsDefault === 'boolean'
                            ? ctx.stripePaymentElementMachine?.getSnapshot()?.context.setAsDefault
                            : true,
                    selectedPaymentMethod:
                        ctx.stripePaymentElementMachine?.getSnapshot()?.context.selectedPaymentMethod || 'card',
                },
            })),
            setSetupCardError: assign({
                setupCardError: (ctx, event) => (event.data as StripeError).message as string,
            }),
            clearSetupCardError: assign({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                setupCardError: (ctx) => '',
            }),

            // *******************
            // Analytics
            // *******************

            trackPaymentInfoEntered: (ctx) => {
                const paymentMethod =
                    ctx.stripePaymentElementMachine?.getSnapshot()?.context.selectedPaymentMethod || 'card';

                if (paymentMethod === 'apple_pay' || paymentMethod === 'google_pay') {
                    Analytics.trackWalletCheckoutStarted(ctx.paymentId, 1, paymentMethod);
                } else {
                    Analytics.trackPaymentInfoEntered(ctx.paymentId, 1, paymentMethod);
                }
            },
        },
        services: {
            getSetupIntent: (ctx) =>
                ctx.useGuestCheckout
                    ? PaymentService.getGuestSetupIntent({ accessToken: ctx.accessToken })
                    : PaymentService.getSetupIntent({ accessToken: ctx.accessToken }),
            setupCard: (ctx, event) =>
                PaymentService.stripeConfirmSetup({ stripe: event.data.stripe, elements: event.data.elements }),
        },
    },
);

export default paymentElementMachine;
