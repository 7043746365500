import classNames from 'classnames';
import { FC } from 'react';

import './RoamingPriceCoverTile.scss';

type TProps = {
    title?: string | null;
    price?: number | null;
};

const RoamingPriceCoverTileBaymax: FC<TProps> = ({ price, title }: TProps) => {
    return (
        <div className={classNames('roaming-price-cover-tile')}>
            <p className="roaming-price-cover-tile__title">{title}</p>
            <div className="roaming-price-cover-tile__price-section">
                <p className={classNames('roaming-price-cover-tile__price')}>
                    <span className="roaming-price-cover-tile__price__currency">A$</span>
                    {price}
                </p>
            </div>
        </div>
    );
};

export default RoamingPriceCoverTileBaymax;
