import LocalizedStrings from 'react-localization';

const homeV2Content = new LocalizedStrings({
    en: {
        noCoverScheduled: 'No cover scheduled',
        coverIsScheduled: 'Cover is scheduled',
        coverIsActive: 'Your cover is active',
    },
});

export default homeV2Content;
