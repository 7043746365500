import LocalizedStrings from 'react-localization';

const calendarButton = new LocalizedStrings({
    en: {
        editDatesLabel: 'Edit dates',
        selectDatesLabel: 'Open calendar to select',
        weekSelectedIndexLabel: `Week {index}:`,
        weekSelectedStartingDateLabel: 'Starting {date}',
        daySelectedIndexLabel: `Day {index}:`,
        daySelectedStartingDateLabel: 'Starting {date}',
    },
});

export default calendarButton;
