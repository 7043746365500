import LocalizedStrings from 'react-localization';

const checkAgeEligibility = new LocalizedStrings({
    en: {
        title: 'The cover selected is only eligible for customers between {minAge} and {maxAge} years of age',
        description:
            'If you need further information, or your details are incorrect, please reach out to {customerSupport}',
        cta: 'Back to dashboard',
    },
});

export default checkAgeEligibility;
