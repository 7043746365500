import classNames from 'classnames';
import { addDays, format, isAfter, isBefore, isEqual } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import { DayModifiers } from 'react-day-picker/types/Modifiers';

import common from '../../../../strings/common';
import dateFormat from '../../../../utils/constants/DateFormat';
import Alert, { AlertTypes } from '../../../alert/Alert';
import { Button } from '../../../button/Button';
import defaultClassNames from '../../constants';
import { NewDatePickerProps } from '../../types';
import CalendarLabel from '../calendar-label/CalendarLabel';

import './WeekPicker.scss';

type DateRange = {
    from: Date;
    to: Date;
};

const WeekPicker: React.FC<NewDatePickerProps> = ({
    id,
    innerRef,
    onFocus,
    onBlur,
    onChange,
    value,
    monthToShow,
    fromMonth,
    toMonth,
    disabledDays,
    modifiers,
    showYearInCaption,
    className,
    error,
    label,
}) => {
    const [savedRanges, setSavedRanges] = useState<DateRange[]>([]);
    const [hoveredDays, setHoveredDays] = useState<DateRange | null>(null);
    const [disabledRanges, setDisabledRanges] = useState<DateRange[]>([]);
    const weeksContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value) {
            const ranges: DateRange[] = value.map((startDate) => ({
                from: startDate,
                to: addDays(startDate, 6),
            }));
            setSavedRanges(ranges);
        }
    }, [value]);

    const sortRanges = (a: DateRange, b: DateRange) => a.from.getTime() - b.from.getTime();

    const handleDayMouseEnter = (day: Date, m: DayModifiers) => {
        if (m.disabled) return;
        setHoveredDays({
            from: day,
            to: addDays(day, 6),
        });
    };

    const handleDayMouseLeave = () => {
        setHoveredDays(null);
    };

    const handleTouchMouseLeave = () => {
        // On touch screens, the events that get fired when a date
        // is selected are in the following order
        // touch enter, touch leave, mouse enter
        // the mouse is captured and not released on touch
        // causing a hover state to remain after touch leave
        // we add a delay here to set hovered to null on touch leave
        // to override mouse enter firing after touch leave
        // and release the hovered state
        setTimeout(() => {
            setHoveredDays(null);
        }, 50);
    };

    const isRangeOverlapped = (range1: DateRange | null, range2: DateRange | null) => {
        if (!range2 || !range1) {
            return false;
        }
        return (
            (isBefore(range1.from, range2.to) || isEqual(range1.from, range2.to)) &&
            (isAfter(range1.to, range2.from) || isEqual(range1.to, range2.from))
        );
    };

    const onDayClickHandler = (day: Date, m: DayModifiers) => {
        if (!day || m.disabled) {
            return;
        }
        const newRange = {
            from: day,
            to: addDays(day, 6),
        };

        setSavedRanges((prevState) => {
            const sr = prevState.filter((range) => !isRangeOverlapped(range, newRange));
            return [newRange, ...sr].sort(sortRanges);
        });
        setHoveredDays(null);
    };

    const mods = {
        ...modifiers,
        hoveredRange: hoveredDays || undefined,
        hoveredStart: hoveredDays ? hoveredDays.from : undefined,
        hoveredEnd: hoveredDays ? hoveredDays.to : undefined,

        savedRange: savedRanges.filter((range) => !isRangeOverlapped(range, hoveredDays)),
        savedStart: savedRanges.filter((range) => !isRangeOverlapped(range, hoveredDays)).map((range) => range.from),
        savedEnd: savedRanges.filter((range) => !isRangeOverlapped(range, hoveredDays)).map((range) => range.to),

        disabledRange: disabledRanges.filter(
            (range) => !isRangeOverlapped(range, hoveredDays) && range.from.getTime() !== range.to.getTime(),
        ),
        disabledStart: disabledRanges
            .filter((range) => !isRangeOverlapped(range, hoveredDays) && range.from.getTime() !== range.to.getTime())
            .map((range) => range.from),
        disabledEnd: disabledRanges
            .filter((range) => !isRangeOverlapped(range, hoveredDays) && range.from.getTime() !== range.to.getTime())
            .map((range) => range.to),

        disabledRangeDay: disabledRanges
            .filter((range) => !isRangeOverlapped(range, hoveredDays) && range.from.getTime() === range.to.getTime())
            .map((range) => range.from),
    };

    const renderDay = (day: Date) => {
        const date = day.getDate();
        return (
            <div className="day-wrapper">
                <div className="day-value">{date}</div>
            </div>
        );
    };

    const onRemoveDateClick = (date: string) => {
        setSavedRanges((ranges) => ranges.filter(({ from }) => format(from, dateFormat.DATE) !== date));
    };

    const getValues = useMemo(() => {
        return savedRanges;
    }, [savedRanges]);

    // If week selection changes, scroll down UI to show the most recently added week at the bottom
    useEffect(() => {
        if (weeksContainerRef.current) {
            weeksContainerRef.current.scrollTop = weeksContainerRef.current.scrollHeight;
        }
    }, [getValues]);

    const onDoneClick = () => {
        if (onChange) {
            onChange(getValues.map(({ from }) => from));
        }
    };

    const renderCaptionElement = ({ date }: { date: Date }) => (
        <div className="DayPicker-Caption">
            <p>{`${format(date, 'MMMM').toUpperCase()} ${showYearInCaption ? format(date, 'Y') : ''}`}</p>
            {label && <CalendarLabel text={label} />}
        </div>
    );

    const computedDisabledDays = useMemo(() => {
        const disabledDaysArray = Array.isArray(disabledDays) ? disabledDays : [disabledDays];

        const oneDayInMs = 24 * 60 * 60 * 1000;
        const oneWeekInMs = 7 * oneDayInMs;
        const dRanges: DateRange[] = [];

        const rangesDisabled = savedRanges.flatMap((range) => {
            return [
                { after: new Date(range.from.getTime() - oneWeekInMs), before: new Date(range.from.getTime()) },
                {
                    after: new Date(range.from.getTime() - oneDayInMs),
                    before: new Date(range.to.getTime() + oneDayInMs),
                },
            ];
        });

        for (let i = 0; i < savedRanges.length - 1; i += 1) {
            const currentRange = savedRanges[i];
            const nextRange = savedRanges[i + 1];

            if (nextRange.from.getTime() - currentRange.to.getTime() > oneDayInMs * 7) {
                dRanges.push({
                    from: new Date(nextRange.from.getTime() - oneDayInMs * 6),
                    to: new Date(nextRange.from.getTime() - oneDayInMs),
                });
            } else {
                dRanges.push({
                    from: new Date(currentRange.to.getTime() + oneDayInMs),
                    to: new Date(nextRange.from.getTime() - oneDayInMs),
                });
            }
        }

        setDisabledRanges(dRanges);
        return [...rangesDisabled, ...disabledDaysArray];
    }, [savedRanges, disabledDays]);

    return (
        <div id={id} ref={innerRef} className="date-picker-week">
            <DayPicker
                renderDay={renderDay}
                className={classNames('day-picker', className)}
                classNames={{
                    ...defaultClassNames,
                    day: 'DayPicker-Day-custom',
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onDayClick={onDayClickHandler}
                month={monthToShow}
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={computedDisabledDays}
                modifiers={mods}
                fixedWeeks
                onDayMouseEnter={handleDayMouseEnter}
                onDayMouseLeave={handleDayMouseLeave}
                onDayTouchStart={handleDayMouseEnter}
                onDayTouchEnd={handleTouchMouseLeave}
                captionElement={renderCaptionElement}
            />
            {error && <Alert className="date-picker__alert" message={error} type={AlertTypes.INFO} />}
            {(!!getValues.length || !!value.length) && (
                <div className="date-picker-week__controls">
                    <Button
                        label={common.confirmDates}
                        className="date-picker-week__btn date-picker-week__done-btn"
                        onClick={onDoneClick}
                    />
                </div>
            )}
            {!!getValues.length && (
                <>
                    <div
                        className={classNames('date-picker-week__dates', {
                            'date-picker-week__dates--condensed': getValues.length >= 3,
                        })}
                        ref={weeksContainerRef}
                    >
                        {getValues.map(({ from }, index) => {
                            const date = format(from, dateFormat.DATE);
                            const keyRow = date + index;
                            return (
                                <div key={keyRow}>
                                    <div className="date-picker-week__date">
                                        <div className="date-picker-week__date-label">Week {index + 1}:</div>
                                        <div className="date-picker-week__date-value">Starting {date}</div>
                                        <div
                                            role="none"
                                            className="date-picker-week__date-remove"
                                            onClick={() => onRemoveDateClick(date)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default WeekPicker;
