import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns-tz/format';
import enAU from 'date-fns/locale/en-AU';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

export default (time: number, timeZone: string): string => {
    return format(utcToZonedTime(fromUnixTime(time), timeZone), 'h:mm a, do MMM, yyyy (z)', {
        timeZone,
        locale: enAU,
    });
};
