enum CartBaymaxStep {
    PROFILE,
    WHOS_COVERED,
    REVIEW_COVER,
    PAYMENT,
}

export const CartBaymaxStepsWithoutKids = [CartBaymaxStep.PROFILE, CartBaymaxStep.REVIEW_COVER, CartBaymaxStep.PAYMENT];

export const CartBaymaxStepsWithKids = [
    CartBaymaxStep.PROFILE,
    CartBaymaxStep.WHOS_COVERED,
    CartBaymaxStep.REVIEW_COVER,
    CartBaymaxStep.PAYMENT,
];

export default CartBaymaxStep;
