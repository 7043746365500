import OktaSignIn from '@okta/okta-signin-widget';
import { Dispatch, ReactNode, SetStateAction } from 'react';

import oktaConfig from '../../../auth/oktaConfig';
import isValidEmail from '../../../validation/isValidEmail';
import setupForgotPasswordLink from './setupForgotPasswordLink';
import setupLandingView from './setupLandingView';
import CustomerService from '../../../services/customer-service/CustomerService';
import ErrorMessages from '../../alert/error-messages/ErrorMessages';

/**
 * Creates Okta Sign In Widget instance, and adds handlers for various events.
 *
 * @param param0
 * @returns Widget Instance
 */
const setupOktaWidget = ({
    setLoading,
    setError,
}: {
    setLoading: Dispatch<SetStateAction<boolean>>;
    setError: Dispatch<SetStateAction<ReactNode | null>>;
}): OktaSignIn => {
    // registration handlers
    const widget = new OktaSignIn({
        ...oktaConfig.oktaSignInConfig,
        features: {
            ...oktaConfig.oktaSignInConfig.features,
        },
    });

    const handleLoginWithEmailClick = async (email: string, onContinueLogin: () => void) => {
        setError(null);
        if (isValidEmail(email)) {
            try {
                await CustomerService.enrolCustomer({ email });
                onContinueLogin();
            } catch (error) {
                setError(ErrorMessages.default);
                throw error;
            }
        } else {
            // If invalid email passthrough to Okta Sign In Widget, Widget will handle error
            onContinueLogin();
        }
    };

    // error handlers
    widget.on('afterError', () => {
        setLoading(false);
        setupLandingView(handleLoginWithEmailClick);
    });

    // sign in handlers
    widget.showSignInAndRedirect({
        el: '#okta-sign-in-widget-wrapper',
    });

    // initialisation
    widget.on('ready', () => {
        setLoading(false);
    });

    widget.on('afterRender', (context: any) => {
        if (context.controller === 'primary-auth') {
            setupLandingView(handleLoginWithEmailClick);
        }
        if (context.controller === 'mfa-verify' && window.location.href.match('password')) {
            setupForgotPasswordLink();
        }
    });

    return widget;
};

export default setupOktaWidget;
