import { createMachine } from 'xstate';
import { Destination } from '../../../business-logic/models/RoamingDestinations';
import {
    trackStartDestinationSelected,
    setStartingDestination,
    setAllDestinations,
} from './actions/roamingStartDestinationBaymaxMachineActions';
import { RoamingStartDestinationBaymaxMachineContextTypes } from './context/roamingStartDestinationBaymaxMachineContext';
import { areDestinationsChosen, hasMultipleRegions } from './guards/roamingStartDestinationBaymaxMachineGuards';

const RoamingStartDestinationBaymaxMachine = createMachine(
    {
        id: 'RoamingStartDestinationBaymaxMachine',
        initial: 'verifyDestinationsChosen',
        schema: {
            context: {} as RoamingStartDestinationBaymaxMachineContextTypes,
            events: {} as
                | { type: 'SELECT_START_DESTINATION'; data: string | null }
                | { type: 'CONTINUE' }
                | {
                      type: 'LOAD_DESTINATIONS';
                      data: {
                          availableDestinations: Destination[];
                      };
                  },
        },
        tsTypes: {} as import('./roamingStartDestinationBaymaxMachine.typegen').Typegen0,
        context: {
            selectedProductGrouping: '',
            selectedProductOption: null,
            availableDestinations: [],
            startDestination: '',
            chosenDestinations: [],
        },
        preserveActionOrder: true,
        states: {
            verifyDestinationsChosen: {
                always: [
                    {
                        cond: 'areDestinationsChosen',
                        target: 'initialise',
                    },
                    {
                        target: 'redirectToStart',
                    },
                ],
            },
            redirectToStart: {
                type: 'final',
                entry: 'redirectToPurchase',
            },
            initialise: {
                tags: ['loading'],
                on: {
                    LOAD_DESTINATIONS: {
                        actions: 'setAllDestinations',
                        target: 'idle',
                    },
                },
            },
            idle: {
                on: {
                    SELECT_START_DESTINATION: {
                        actions: 'setStartingDestination',
                    },
                    CONTINUE: {
                        target: 'determineHowManyRegions',
                    },
                },
            },
            determineHowManyRegions: {
                tags: ['loading'],
                always: [
                    {
                        cond: 'hasMultipleRegions',
                        target: 'chooseStartingRegion',
                    },
                    {
                        target: 'selectRoamingDates',
                    },
                ],
            },
            selectRoamingDates: {
                entry: ['redirectToSelectDates', 'trackStartDestinationSelected'],
                tags: ['loading'],
                type: 'final',
            },
            chooseStartingRegion: {
                entry: ['redirectToChooseStartingRegion', 'trackStartDestinationSelected'],
                tags: ['loading'],
                type: 'final',
            },
        },
    },
    {
        actions: {
            trackStartDestinationSelected,
            setStartingDestination,
            setAllDestinations,
        },
        guards: {
            areDestinationsChosen,
            hasMultipleRegions,
        },
    },
);

export default RoamingStartDestinationBaymaxMachine;
