import { useState, FC } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import onboardingFlow from '../../strings/onboardingFlow';
import Analytics from '../../analytics/Analytics';
import routes from '../../utils/Routes';
import BeforeYouGoFormBody from './before-you-go-form-body/BeforeYouGoFormBody';
import Button from '../../components/button/Button';

type TState = {
    reason: string;
    elseReason?: string;
};

const BeforeYouGo: FC = () => {
    const history = useHistory();
    const {
        beforeYouGo: { title, reasons, exitToDashboard, sendAndExitToDashboard },
    } = onboardingFlow;

    const [state, setState] = useState<TState>({
        reason: '',
    });

    const setReason = (reason: string) => {
        setState({
            ...state,
            reason,
        });
    };

    const setElseReason = (elseReason: string) => {
        setState({
            ...state,
            elseReason,
        });
    };

    const onSubmitHandler = () => {
        if (state.reason !== reasons.dontNeed) {
            Analytics.trackBeforeYouGoReason(state.reason, state.elseReason);
        } else {
            Analytics.trackBeforeYouGoReason(state.reason);
        }
        history.push(routes.HOME);
    };

    return (
        <Layout title={title} showBackButton>
            <form>
                <BeforeYouGoFormBody reason={state.reason} setReason={setReason} setElseReason={setElseReason} />
                <Button
                    variant="primary"
                    label={state.reason === '' ? exitToDashboard : sendAndExitToDashboard}
                    width="full"
                    onClick={onSubmitHandler}
                />
            </form>
        </Layout>
    );
};

export default BeforeYouGo;
