import React, { useCallback, useMemo, useState } from 'react';
import { useUser } from '../../../business-logic/context-provider/user-context';
import { PartnerEvent, Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { ActionList } from '../../../components/action-list/ActionList.stories';
import { ActionItemProps } from '../../../components/action-list/action-item/ActionItem';
import coverListCardContent from '../../../content/ui/components/cover-list-card/coverListCard';
import withContent from '../../../hoc/with-content/withContent';
import usePaymentStripe from '../../../hooks/usePaymentStripe';
import createSingleCoverListItem from './utils/createSingleCoverListItem';
import createSubscriptionCoverListItem from './utils/createSubscriptionCoverListItem';

import { useProduct } from '../../../business-logic/context-provider/ProductContext';
import CancelCoverDrawer from '../../../components/cancel-cover-drawer/CancelCoverDrawer';
import CoverTypeId from '../../../utils/constants/CoverTypeId';
import createPartnerEventCoverListItem from './utils/createPartnerEventCoverListItem';

import './CoverListCard.scss';

const contentMap = {
    yourCover: 'ui.yourCover',
    firstNameCover: 'ui.firstNameCover',
};

interface CoverListCardProps {
    content: Record<keyof typeof contentMap, string>;
    firstName?: string;
    policies: Policy[];
    partnerEvents: PartnerEvent[];
}

const CoverListCard: React.FC<CoverListCardProps> = ({ content, firstName, policies, partnerEvents }) => {
    const { userTimeZone, accessToken } = useUser();
    const { manageProtection } = usePaymentStripe({
        basePath: import.meta.env.VITE_BASE_API_PATH || '',
        accessToken,
    });
    const { coverInformation } = useProduct();

    const [coverIdToBeCancelled, setCoverIdToBeCancelled] = useState<string | null>(null);

    const handleCancel = useCallback((coverId: string) => {
        setCoverIdToBeCancelled(coverId);
    }, []);

    const policyCoversList: WithReactKey<ActionItemProps>[] = useMemo(() => {
        const policyCovers: any[] = [];
        policies
            .sort((a, b) => a.mainCover.activeFrom - b.mainCover.activeFrom)
            .forEach((policy) => {
                if (policy.mainCover.coverTypeId === CoverTypeId.SINGLE_V1) {
                    policyCovers.push({
                        key: policy.insurancePolicyId,
                        ...createSingleCoverListItem(policy, userTimeZone, coverInformation, handleCancel),
                    });
                }
                if (policy.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1) {
                    policyCovers.push({
                        key: policy.insurancePolicyId,
                        ...createSubscriptionCoverListItem(
                            policy,
                            userTimeZone,
                            coverInformation,
                            handleCancel,
                            manageProtection,
                        ),
                    });
                }
            });
        return policyCovers;
    }, [handleCancel, manageProtection, policies, userTimeZone]);
    // No cover for this person
    if (policyCoversList.length === 0 && partnerEvents.length === 0) {
        return null;
    }

    const numberOfCovers = policyCoversList.length > 1 ? 's' : '';

    return (
        <>
            <h2>
                {firstName
                    ? coverListCardContent.formatString(content.firstNameCover, { firstName, numberOfCovers })
                    : coverListCardContent.formatString(content.yourCover, { numberOfCovers })}
            </h2>

            {/* Partner events */}
            {partnerEvents.map((p) => (
                <ActionList
                    key={p.insurancePolicyId}
                    className="cover-list-card__action-list"
                    items={[
                        {
                            key: p.insurancePolicyId,
                            ...createPartnerEventCoverListItem(p, userTimeZone),
                        },
                    ]}
                />
            ))}

            {/* All Covers */}
            {policyCoversList.length > 0 ? (
                <ActionList className="cover-list-card__action-list" items={policyCoversList} />
            ) : null}
            <CancelCoverDrawer
                firstName={firstName}
                policies={policies}
                isOpen={!!coverIdToBeCancelled}
                preselectedCover={coverIdToBeCancelled}
                onClose={() => setCoverIdToBeCancelled(null)}
            />
        </>
    );
};

export default withContent(CoverListCard, contentMap, coverListCardContent);
