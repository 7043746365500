import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import CdnService from '../../services/cdn-service/CdnService';
import ProductService from '../../services/product-service/ProductService';
import withRetriesAsync from '../../services/utils/withRetriesAsync';
import { CoverCategory } from '../models/cdn-content/CoverCategory';
import { AllCoverInformation } from '../models/cdn-content/CoverInformation';
import { AllCoverTerms } from '../models/cdn-content/CoverTerms';
import ProductGroupsResponse from '../models/ProductGroupsResponse';
import ProductResponse from '../models/ProductResponse';

// Interfaces
interface ProductState {
    loading: boolean;
    initialised: boolean;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    coverInformation: AllCoverInformation;
    coverCategories: CoverCategory[];
    coverTerms: AllCoverTerms;
    error: boolean;
}

const initialState: ProductState = {
    loading: false,
    initialised: false,
    products: [],
    productGroups: [],
    coverInformation: {},
    coverCategories: [],
    coverTerms: {},
    error: false,
};

export type ProductDispatchAction =
    | { type: 'SET_LOADING'; payload: boolean }
    | { type: 'SET_INITIALISED'; payload: boolean }
    | { type: 'SET_PRODUCTS'; payload: ProductResponse[] }
    | { type: 'SET_PRODUCT_GROUPS'; payload: ProductGroupsResponse }
    | { type: 'SET_COVER_INFORMATION'; payload: AllCoverInformation }
    | { type: 'SET_COVER_CATEGORIES'; payload: CoverCategory[] }
    | { type: 'SET_COVER_TERMS'; payload: AllCoverTerms }
    | { type: 'SET_PRODUCTS_ERROR'; payload: boolean };

export type IProductContext = {
    initialised: boolean;
    loading: boolean;
    products: ProductResponse[];
    productGroups: ProductGroupsResponse;
    coverInformation: AllCoverInformation;
    coverCategories: CoverCategory[];
    coverTerms: AllCoverTerms;
    error: boolean;
};

export const ProductContext = createContext<any>({});

export const useProduct = (): IProductContext => {
    const context: IProductContext = useContext(ProductContext);
    if (typeof context === 'undefined') {
        throw new Error('Product Context must be used within the ProductContext');
    }
    return context;
};

const reducer = (state: ProductState, action: ProductDispatchAction): ProductState => {
    switch (action.type) {
        case 'SET_LOADING':
            return { ...state, loading: action.payload };
        case 'SET_INITIALISED':
            return { ...state, initialised: action.payload };
        case 'SET_PRODUCTS':
            return { ...state, products: action.payload };
        case 'SET_PRODUCT_GROUPS':
            return { ...state, productGroups: action.payload };
        case 'SET_COVER_INFORMATION':
            return { ...state, coverInformation: action.payload };
        case 'SET_COVER_TERMS':
            return { ...state, coverTerms: action.payload };
        case 'SET_COVER_CATEGORIES':
            return { ...state, coverCategories: action.payload };
        case 'SET_PRODUCTS_ERROR':
            return { ...state, error: action.payload };
        default:
            throw new Error();
    }
};

export const ProductProvider: React.FC = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { showTestProducts } = useFlags();
    const filterTestProducts = useMemo(() => showTestProducts !== true, [showTestProducts]);

    const setProductsLoading = (loading: boolean) => {
        dispatch({ type: 'SET_LOADING', payload: loading });
    };
    const setProductsInitialised = (initialised: boolean) => {
        dispatch({ type: 'SET_INITIALISED', payload: initialised });
    };
    const setProducts = (products: ProductResponse[]) => {
        dispatch({ type: 'SET_PRODUCTS', payload: products });
    };
    const setProductGroups = (productGroups: ProductGroupsResponse) => {
        dispatch({ type: 'SET_PRODUCT_GROUPS', payload: productGroups });
    };
    const setCoverInformation = (coverInformation: AllCoverInformation) => {
        dispatch({ type: 'SET_COVER_INFORMATION', payload: coverInformation });
    };
    const setCoverCategories = (coverCategories: CoverCategory[]) => {
        dispatch({ type: 'SET_COVER_CATEGORIES', payload: coverCategories });
    };
    const setCoverTerms = (coverTerms: AllCoverTerms) => {
        dispatch({ type: 'SET_COVER_TERMS', payload: coverTerms });
    };
    const setProductsError = (error: boolean) => {
        dispatch({ type: 'SET_PRODUCTS_ERROR', payload: error });
    };
    // ***************** Fetchers *****************

    const fetchAllProducts = useCallback(async () => {
        const response = await ProductService.getProducts({
            filterTestProducts,
        });
        return response;
    }, [filterTestProducts]);

    const fetchAllProductGroups = useCallback(async () => {
        const response = await ProductService.getProductGroups({});
        return response;
    }, []);

    const fetchCoverInformation = useCallback(async (): Promise<AllCoverInformation> => {
        const response = await CdnService.getCoverInformation({});
        return response;
    }, []);

    const fetchCoverCategories = useCallback(async (): Promise<CoverCategory[]> => {
        const response = await CdnService.getCoverCategory({});
        return response;
    }, []);

    const fetchCoverTerms = useCallback(async (): Promise<AllCoverTerms> => {
        const response = await Promise.all([CdnService.getCoverTerms({}), CdnService.getPartnerCoverTerms({})]);
        return { ...response[0], ...response[1] };
    }, []);
    // ***************** Fetch and set *****************

    // ***************** Private calls *****************
    // Try catch handled in fetchAndSetAll

    const fetchAndSetProducts = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchAllProducts());
        setProducts(response);
    }, [fetchAllProducts]);

    const fetchAndSetProductGroups = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchAllProductGroups());
        setProductGroups(response);
    }, [fetchAllProductGroups]);

    const fetchAndSetCoverInformation = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchCoverInformation());
        setCoverInformation(response);
    }, [fetchCoverInformation]);

    const fetchAndSetCoverCategories = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchCoverCategories());
        setCoverCategories(response);
    }, [fetchCoverCategories]);

    const fetchAndSetCoverTerms = useCallback(async () => {
        const response = await withRetriesAsync(() => fetchCoverTerms());
        setCoverTerms(response);
    }, [fetchCoverTerms]);
    // ***************** Main fetch and set *****************

    const fetchAndSetAllProducts = useCallback(async () => {
        setProductsLoading(true);

        try {
            await Promise.all([
                fetchAndSetProducts(),
                fetchAndSetProductGroups(),
                fetchAndSetCoverInformation(),
                fetchAndSetCoverTerms(),
                fetchAndSetCoverCategories(),
            ]);
            setProductsInitialised(true);
        } catch (e) {
            setProductsError(true);
        } finally {
            setProductsLoading(false);
        }
    }, [
        fetchAndSetProducts,
        fetchAndSetProductGroups,
        fetchAndSetCoverInformation,
        fetchAndSetCoverTerms,
        fetchAndSetCoverCategories,
    ]);

    // ***************** Initialise *****************

    useEffect(() => {
        fetchAndSetAllProducts();
    }, [fetchAndSetAllProducts]);

    const value: IProductContext = {
        ...state,
    };

    const { children, ...passThroughProps } = props;
    return (
        <ProductContext.Provider value={value} {...passThroughProps}>
            {state.loading && <LoadingSpinnerOverlay />}
            {state.error && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            {/* Block rendering of children if product context isn't initialised */}
            {state.initialised && children}
        </ProductContext.Provider>
    );
};
