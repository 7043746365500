import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BandaidIcon from '../../assets/images/icon-bandaid.svg';
import CalendarIcon from '../../assets/images/icon-calendar.svg';
import { useUser } from '../../business-logic/context-provider/user-context';
import BrandPurchaseButton from '../../components/brand-purchase-button/BrandPurchaseButton';
import ClaimsDeprecateModal from '../../components/claims-deprecation-modal/ClaimsDeprecateModal';
import DashboardButton from '../../components/dashboard-button/DashboardButton';
import DashboardV2 from '../../components/dashboard/DashboardV2';
import LoadingSpinnerOverlay from '../../components/loading-spinner-overlay/LoadingSpinnerOverlay';
import homeV3Content from '../../content/ui/screens/home-v3/homeV3';
import requireFlags from '../../hoc/require-flags/requireFlags';
import withContent from '../../hoc/with-content/withContent';
import Routes from '../../utils/Routes';
import SideNavigation from './components/SideNavigation';

import './HomeV3.scss';

const contentMap = {
    greeting: 'ui.greeting',
    purchaseHeading: 'ui.purchaseHeading',
    linksHeading: 'ui.linksHeading',
    manageCoverLinkTitle: 'ui.manageCoverLinkTitle',
    manageCoverLinkDescription: 'ui.manageCoverLinkDescription',
    claimsLinkTitle: 'ui.claimsLinkTitle',
    claimsLinkDescription: 'ui.claimsLinkDescription',
    coverSubLinkTitle: 'ui.coverSubLinkTitle',
    claimsSubLinkTitle: 'ui.claimsSubLinkTitle',
    accountSubLinkTitle: 'ui.accountSubLinkTitle',
};

interface HomeV3Props {
    content: Record<keyof typeof contentMap, string>;
}

const HomeV3: FC<HomeV3Props> = ({ content }) => {
    const history = useHistory();
    const { loading: userLoading, userDetails } = useUser();
    const [openClaimsDeprecation, setOpenClaimsDeprecation] = useState(false);

    const isAppLoading = userLoading;

    if (isAppLoading) {
        return <LoadingSpinnerOverlay />;
    }

    return (
        <DashboardV2
            title={homeV3Content.formatString(content.greeting, { name: userDetails.firstName || '!' }).toString()}
        >
            <div className="home-v3 dashboardV2__main__content">
                <div className="home-v3__column home-v3__column--main">
                    <DashboardButton
                        label={content.manageCoverLinkTitle}
                        icon={CalendarIcon}
                        description={content.manageCoverLinkDescription}
                        onClick={() => {
                            // TODO: revisit route url
                            history.push(Routes.MANAGE_COVER);
                        }}
                    />
                    <div className="home-v3__purchase--mobile">
                        <h4 className="home-v3__purchase__heading">{homeV3Content.purchaseHeading}</h4>
                        <BrandPurchaseButton />
                    </div>
                    <DashboardButton
                        label={content.claimsLinkTitle}
                        icon={BandaidIcon}
                        description={content.claimsLinkDescription}
                        onClick={() => {
                            setOpenClaimsDeprecation(true);
                        }}
                    />
                </div>
            </div>
            <ClaimsDeprecateModal isOpen={openClaimsDeprecation} onClose={() => setOpenClaimsDeprecation(false)} />
            <SideNavigation className="dashboardV2__main__side" />
        </DashboardV2>
    );
};

export default requireFlags(withContent(HomeV3, contentMap, homeV3Content));
