enum RiveAnimation {
    ON_INTRO = 'on_intro',
    OFF_INTRO = 'off_intro',
    ON_OFF = 'on-off',
    OFF_BOOST = 'off-boost',
    ON_BOOST_ACTIVE = 'on-boost-active',
    ON_BOOST_INACTIVE = 'on-boost-inactive',
    ON_SCHEDULED = 'on-scheduled',
}

export default RiveAnimation;
