import classNames from 'classnames';

import { RadioCheckboxProps } from '../RadioCheckboxProps';

import './ImageButtonInput.scss';

export type ImageButtonInputProps = RadioCheckboxProps & {
    icon: string;
    type: 'radio' | 'checkbox';
    variant?: 'horizontal' | 'vertical';
};

const ImageButtonInput: React.FC<ImageButtonInputProps> = ({
    id,
    name,
    label,
    icon,
    type,
    checked,
    className,
    variant,
    onChange,
    onBlur,
    onFocus,
}) => {
    return (
        <label
            htmlFor={id}
            className={classNames(
                'image-button-input',
                {
                    checked,
                    'image-button-input--with-icon': !!icon,
                    'image-button-input--vertical': variant === 'vertical',
                },
                className,
            )}
        >
            <input
                type={type}
                id={id}
                name={name}
                checked={checked}
                className="image-button-input__input"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <img src={icon} alt="" className={classNames('image-button-input__icon', { checked })} />
            <span className="image-button-input__label">{label}</span>
        </label>
    );
};

ImageButtonInput.defaultProps = {
    variant: 'vertical',
};

export default ImageButtonInput;
