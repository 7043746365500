import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import CoverStatus from '../../../utils/constants/CoverStatus';
import Dashboard from '../../dashboard/Dashboard';

import './HomeLayout.scss';

interface HomeLayoutProps {
    children: ReactNode;
    title: string;
    coverStatus: CoverStatus;
}

const HomeLayout: FC<HomeLayoutProps> = ({ children, title, coverStatus }: HomeLayoutProps) => (
    <Dashboard title={title} className={classNames('home-layout', `home-layout--${coverStatus}`)} centerTitle>
        {children}
    </Dashboard>
);

export default HomeLayout;
