import { format } from 'date-fns';
import { FC, useCallback } from 'react';
import classNames from 'classnames';

import calendarButton from '../../../../content/ui/components/calendar-button/calendarButton';
import withContent from '../../../../hoc/with-content/withContent';
import DateFormat from '../../../../utils/constants/DateFormat';
import { Button } from '../../../button/Button';
import { NewDatePickerModes } from '../../../new-date-picker/types';
import './CalendarButton.scss';

const contentMap = {
    editDatesLabel: 'ui.editDatesLabel',
    selectDatesLabel: 'ui.selectDatesLabel',
    weekSelectedIndexLabel: 'ui.weekSelectedIndexLabel',
    weekSelectedStartingDateLabel: 'ui.weekSelectedStartingDateLabel',
    daySelectedIndexLabel: 'ui.daySelectedIndexLabel',
    daySelectedStartingDateLabel: 'ui.daySelectedStartingDateLabel',
};

type CalendarButtonProps = {
    mode: NewDatePickerModes;
    selectedDates: Date[];
    onClick: () => void;
    content: Record<keyof typeof contentMap, string>;
    disabled?: boolean;
    changeButtonOnDateSelected?: boolean;
};

const CalendarButton: FC<CalendarButtonProps> = ({
    onClick,
    selectedDates,
    mode,
    content,
    disabled,
    changeButtonOnDateSelected,
}) => {
    const getButtonLabel = useCallback(() => {
        let labelText = content.selectDatesLabel;

        if (selectedDates.length > 0) {
            labelText = content.editDatesLabel;
        }

        return (
            <div className="calendar-button__label">
                <span className="calendar-button__icon" />
                {labelText}
            </div>
        );
    }, [content.editDatesLabel, content.selectDatesLabel, selectedDates]);

    return (
        <div className="calendar-button">
            <Button
                variant={changeButtonOnDateSelected && selectedDates.length === 0 ? 'primary' : 'secondary'}
                label={getButtonLabel()}
                className={classNames({
                    'calendar-button__btn--navy': changeButtonOnDateSelected && selectedDates.length === 0,
                    'calendar-button__btn': !changeButtonOnDateSelected || selectedDates.length > 0,
                })}
                onClick={onClick}
                width="full"
                disabled={disabled}
            />

            {selectedDates.length > 0 && mode === 'single' && (
                <div className="calendar-button__dates">
                    <div key={selectedDates[0].toDateString()} className="calendar-button__date">
                        {calendarButton.formatString(content.weekSelectedStartingDateLabel, {
                            date: format(selectedDates[0], DateFormat.VERBOSE),
                        })}
                    </div>
                </div>
            )}

            {selectedDates.length > 0 && mode === 'multiple' && (
                <div className="calendar-button__dates">
                    {selectedDates.map((date, key) => {
                        const dateFormatted = format(date, DateFormat.VERBOSE);
                        return (
                            <div key={dateFormatted} className="calendar-button__date">
                                <span>
                                    {calendarButton.formatString(content.daySelectedIndexLabel, { index: key + 1 })}
                                </span>{' '}
                                {calendarButton.formatString(content.daySelectedStartingDateLabel, {
                                    date: dateFormatted,
                                })}
                            </div>
                        );
                    })}
                </div>
            )}

            {selectedDates.length > 0 && mode === 'week' && (
                <div className="calendar-button__dates">
                    {selectedDates.map((date, key) => {
                        const dateFormatted = format(date, DateFormat.VERBOSE);
                        return (
                            <div key={dateFormatted} className="calendar-button__date">
                                <span>
                                    {calendarButton.formatString(content.weekSelectedIndexLabel, { index: key + 1 })}
                                </span>{' '}
                                {calendarButton.formatString(content.weekSelectedStartingDateLabel, {
                                    date: dateFormatted,
                                })}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default withContent(CalendarButton, contentMap, calendarButton);
