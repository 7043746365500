import LocalizedStrings from 'react-localization';

const coverListCardContent = new LocalizedStrings({
    en: {
        yourCover: 'Your cover{numberOfCovers}',
        firstNameCover: "{firstName}'s cover{numberOfCovers}",
        activeBadge: 'Active',
        coverScheduled: 'Starting {startDate}',
        coverActive: '{coverName} cover is active until {endDateTime}',
        subscriptionRenews: 'Next billing date {renewDate}',
        subscriptionEnding: "Your cover is about to end. You'll have cover until {endDate}.",
        subscriptionCancelled:
            'You can always start cover again after it ends, just be sure to check the latest {pds} in case things have changed.',
        subscriptionPaymentFailed:
            "Sorry, your payment's failed. You'll need to update your payment details in {billingCenter} and pay any overdue invoice to avoid your cover ending.",
        eventCoverScheduled: 'Your {eventName} event cover will be active from {startDateTime} until {endDateTime}',
        eventCoverActive: 'Your {eventName} cover is active until {endDateTime}.',
        eventCoverHere: 'here',
        cancelCoverAriaLabel: 'Cancel {coverName} cover',
        manageCoverLabel: 'Manage cover',
    },
});

export default coverListCardContent;
