import { PartnerEvent, Policy } from '../../../business-logic/models/open-covers-models/OpenCoverPolicies';

const getDashboardState = (policies: Policy[], partnerEvents: PartnerEvent[]): 'active' | 'scheduled' | 'noCover' => {
    if (policies.some((c) => c.mainCover.status === 'Started') || partnerEvents.some((c) => c.status === 'Started')) {
        return 'active';
    }

    if (
        policies.some((c) => c.mainCover.status === 'Scheduled') ||
        partnerEvents.some((c) => c.status === 'Scheduled')
    ) {
        return 'scheduled';
    }

    return 'noCover';
};

export default getDashboardState;
