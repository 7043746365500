import { useMachine } from '@xstate/react';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { assign } from 'xstate';
import Analytics from '../../analytics/Analytics';
import { SCHEDULED_FOR } from '../../analytics/AnalyticsConstants';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import { useRoamingBaymax } from '../../business-logic/context-provider/RoamingBaymaxContext';
import requireFlags from '../../hoc/require-flags/requireFlags';
import { PurchaseState } from '../../types/PurchaseState';
import DateFormat from '../../utils/constants/DateFormat';
import formatDateToString from '../../utils/formatDateToString';
import Routes from '../../utils/Routes';
import roamingDatesBaymaxMachine from './roaming-dates-baymax-machine/roamingDatesBaymaxMachine';
import RoamingDatesBaymaxBody from './RoamingDatesBaymaxBody';

import './RoamingDates.scss';

const RoamingDatesBaymax = () => {
    const history = useHistory();
    const { products } = useProduct();
    const location = useLocation<PurchaseState>();
    const {
        chosenProductGroup,
        selectedProductOption,
        loading: roamingBaymaxLoading,
        initialised: roamingBaymaxInitialised,
    } = useRoamingBaymax();

    const [state, send] = useMachine(roamingDatesBaymaxMachine, {
        context: {
            selectedProductGrouping: chosenProductGroup,
            timezone: location?.state?.destination?.timezone,
            destinations: location?.state?.destination?.destinations ?? [],
            startingRegion: location?.state?.destination?.startingRegion,
            startingDestination: location?.state?.destination?.startingDestination,
            coverStartDates: location?.state?.coverStartDates,
        },
        actions: {
            setScheduleLimitInHours: assign({
                scheduleLimitInHours: (ctx) =>
                    products.find(
                        (product) =>
                            product.productSpec.mainCoverType.coverCode === ctx.selectedCover?.representedByCoverCode,
                    )?.productSpec.mainCoverType.schedule.scheduleLimitInHours ?? null,
            }),
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER_BAYMAX,
                    state: {
                        selectedProductGrouping: '',
                        selectedProductOption: '',
                        destination: null,
                        coverStartDates: [],
                    },
                });
            },
            goToCheckout: (ctx) => {
                const zonedToday = utcToZonedTime(new Date(), ctx.timezone);
                ctx.daysToSchedule.forEach((date) => {
                    Analytics.trackProductAdded({
                        productGroup: state.context.selectedProductGrouping,
                        variant: formatDateToString(date, DateFormat.ANALYTICS),
                        scheduledFor: isSameDay(zonedToday, date) ? SCHEDULED_FOR.TODAY : SCHEDULED_FOR.FUTURE,
                        startingInDays: differenceInCalendarDays(date, zonedToday),
                    });
                });
                if (chosenProductGroup) {
                    history.push({
                        pathname: Routes.CART_BAYMAX,
                        state: {
                            selectedProductGrouping: chosenProductGroup,
                            selectedProductOption,
                            coverStartDates: [
                                ...ctx.daysToSchedule.map((date) => formatDateToString(date, DateFormat.DEFAULT)),
                            ],
                            destination: {
                                destinations: ctx.destinations,
                                startingDestination: ctx.startingDestination,
                                startingRegion: ctx.startingRegion,
                                timezone: ctx.timezone,
                            },
                        },
                    });
                } else {
                    history.push({
                        pathname: Routes.SELECT_COVER_BAYMAX,
                        // RESET
                        state: {
                            selectedProductGrouping: null,
                            selectedProductOption: null,
                            coverStartDates: [],
                            destinations: null,
                        },
                    });
                }
            },
        },
    });
    useEffect(() => {
        if (!roamingBaymaxLoading && roamingBaymaxInitialised && !!chosenProductGroup) {
            const contextState = {
                selectedProductGrouping: chosenProductGroup,
                coversAvailable: [selectedProductOption!],
            };
            send({
                type: 'CONTEXT_INITIALISED',
                data: contextState,
            });
        }
    }, [send, chosenProductGroup, roamingBaymaxLoading, roamingBaymaxInitialised, selectedProductOption]);

    const { datePickerMode, daysToSchedule, disabledDays, destinations, selectedCover, startingRegion, timezone } =
        state.context;

    const handleCalendarOpen = () => {
        if (!selectedCover?.representedByCoverCode) return;
        Analytics.trackCalendarOpened({ product: selectedCover.representedByCoverCode });
    };

    const handleDateSubmission = (dates: Date[]) => {
        send({ type: 'SELECT_DATES', data: [...dates] });
    };
    return (
        <>
            <RoamingDatesBaymaxBody
                loading={!roamingBaymaxInitialised || roamingBaymaxLoading}
                selectedRoamingCover={selectedCover}
                datePickerMode={datePickerMode}
                daysToSchedule={daysToSchedule}
                disabledDays={disabledDays}
                handleDateSubmission={handleDateSubmission}
                canSelectDates={state.can({ type: 'SELECT_DATES', data: [] })}
                proceedToCheckout={() => send('CHECKOUT')}
                startingRegion={startingRegion}
                destinations={destinations}
                timezone={timezone}
                openRoamingProductModal={state.matches('showRoamingModal')}
                agreeToConditions={() => send({ type: 'AGREE_CONDITIONS' })}
                canAgreeToConditions={state.can({ type: 'AGREE_CONDITIONS' })}
                handleCalendarOpen={handleCalendarOpen}
            />
        </>
    );
};

export default requireFlags(RoamingDatesBaymax);
