import { createMachine } from 'xstate';
import { Destination, Region } from '../../../business-logic/models/RoamingDestinations';
import {
    trackStartRegionSelected,
    setAllRegions,
    setChosenRegion,
} from './actions/roamingStartingRegionBaymaxMachineActions';
import { RoamingStartingRegionBaymaxMachineContextTypes } from './context/roamingStartingRegionBaymaxMachineContext';
import { isStartingDestinationSelected } from './guards/roamingStartingRegionBaymaxGuards';

const RoamingStartingRegionBaymaxMachine = createMachine(
    {
        id: 'RoamingStartingRegionBaymaxMachine',
        initial: 'verifyStartingDestination',
        schema: {
            context: {} as RoamingStartingRegionBaymaxMachineContextTypes,
            events: {} as
                | { type: 'SELECTED_REGIONS'; data: Region | null }
                | { type: 'CONTINUE' }
                | {
                      type: 'LOAD_REGIONS';
                      data: {
                          fetchedDestinations: Destination[];
                      };
                  },
        },
        tsTypes: {} as import('./roamingStartingRegionBaymaxMachine.typegen').Typegen0,
        context: {
            selectedProductGrouping: '',
            selectedProductOption: null,
            selectedDestination: '',
            availableRegions: [],
            chosenRegion: null,
        },
        preserveActionOrder: true,
        states: {
            verifyStartingDestination: {
                always: [
                    {
                        cond: 'isStartingDestinationSelected',
                        target: 'initialise',
                    },
                    {
                        target: 'redirectToStart',
                    },
                ],
            },
            redirectToStart: {
                entry: 'redirectToPurchase',
                type: 'final',
            },
            initialise: {
                tags: ['loading'],
                on: {
                    LOAD_REGIONS: {
                        actions: 'setAllRegions',
                        target: 'idle',
                    },
                },
            },
            idle: {
                on: {
                    SELECTED_REGIONS: {
                        actions: 'setChosenRegion',
                    },
                    CONTINUE: {
                        target: 'selectRoamingDates',
                    },
                },
            },
            selectRoamingDates: {
                entry: ['redirectToSelectDates', 'trackStartRegionSelected'],
                tags: ['loading'],
                type: 'final',
            },
        },
    },
    {
        actions: {
            trackStartRegionSelected,
            setChosenRegion,
            setAllRegions,
        },
        guards: {
            isStartingDestinationSelected,
        },
    },
);

export default RoamingStartingRegionBaymaxMachine;
