import UserDetails from '../../../../../business-logic/models/UserDetails';
import PersonService from '../../../../../services/person-service/PersonService';
import formatDateToString from '../../../../../utils/formatDateToString';
import { PersonsInsuredBaymaxMachineContextTypes } from '../context/personsInsuredBaymaxMachineContext';

export type PersonsInsuredBaymaxMachineServices = {
    createDependant: {
        data: UserDetails;
    };
};

export const createDependant = async (ctx: PersonsInsuredBaymaxMachineContextTypes): Promise<UserDetails> => {
    const child = ctx.kidDetailsFormMachineRef?.getSnapshot()?.context;
    const { firstName, lastName, dob, gender } = child!;

    if (!ctx.isAuthenticated) {
        const { dependants } = await PersonService.createGuestDependants({
            accessToken: ctx.accessToken!,
            dependants: [
                {
                    firstName,
                    lastName,
                    dateOfBirth: formatDateToString(new Date(`${dob.year}/${dob.month}/${dob.day}`)),
                    gender: gender!,
                },
            ],
        });
        return dependants[0];
    }
    const { dependants } = await PersonService.createDependants({
        accessToken: ctx.accessToken,
        dependants: [
            {
                firstName,
                lastName,
                dateOfBirth: formatDateToString(new Date(`${dob.year}/${dob.month}/${dob.day}`)),
                gender: gender!,
            },
        ],
    });
    return dependants[0];
};
