import LocalizedStrings from 'react-localization';

const homeV3Content = new LocalizedStrings({
    en: {
        greeting: 'Hi {name}',
        purchaseHeading: 'Get more from Flip',
        linksHeading: 'Quick links',
        manageCoverLinkTitle: 'Manage Cover',
        manageCoverLinkDescription: 'View purchased Flip cover that is active or scheduled, and your cover history.',
        claimsLinkTitle: 'Make a claim',
        claimsLinkDescription: 'Super quick claims, get paid fast.',
        coverSubLinkTitle: 'Cover',
        claimsSubLinkTitle: 'Claims',
        accountSubLinkTitle: 'Account',
        dashboardSubLinkTitle: 'Dashboard',
    },
});

export default homeV3Content;
