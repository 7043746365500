import { FC, ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

import './SlideInUpTransition.scss';

export interface SlideInUpTransitionProps {
    children: ReactNode;
    in: boolean;
}

const SlideInUpTransition: FC<SlideInUpTransitionProps> = ({ in: inProp, children }) => {
    return (
        <div className="slide-in-up-wrapper">
            <CSSTransition mountOnEnter in={inProp} timeout={500} classNames="slide-in-up" unmountOnExit>
                <div>{children}</div>
            </CSSTransition>
        </div>
    );
};

export default SlideInUpTransition;
