import dayPassIcon from '../../../../assets/images/cover/day-pass.svg';
import { PartnerEvent } from '../../../../business-logic/models/open-covers-models/OpenCoverPolicies';
import { ActionItemProps } from '../../../../components/action-list/action-item/ActionItem';
import coverListCardContent from '../../../../content/ui/components/cover-list-card/coverListCard';
import formatEndingTime from '../../../../utils/formatEndingTime';
import ActiveBadge from '../active-badge/ActiveBadge';

const createPartnerEventCoverListItem = (event: PartnerEvent, timeZone: string): ActionItemProps => {
    const { status, partnerEvent } = event;
    const isCoverScheduled = status === 'Scheduled';
    const content = {
        eventCoverScheduled: coverListCardContent.formatString(coverListCardContent.eventCoverScheduled, {
            eventName: partnerEvent.eventName,
            startDateTime: <strong>{formatEndingTime(partnerEvent.activeFrom, timeZone)}</strong>,
            endDateTime: <strong>{formatEndingTime(partnerEvent.activeTo, timeZone)}</strong>,
        }),
        eventCoverActive: coverListCardContent.formatString(coverListCardContent.eventCoverActive, {
            eventName: partnerEvent.eventName,
            endDateTime: <strong>{formatEndingTime(partnerEvent.activeTo, timeZone)}</strong>,
        }),
    };

    if (isCoverScheduled) {
        return {
            leftIcon: dayPassIcon,
            rightIcon: null,
            header: partnerEvent.eventName,
            customDataElement: <p className="cover-list-card__action-item-subtext">{content.eventCoverScheduled}</p>,
        };
    }

    return {
        leftIcon: dayPassIcon,
        rightIcon: <ActiveBadge />,
        header: partnerEvent.eventName,
        customDataElement: <p className="cover-list-card__action-item-subtext">{content.eventCoverActive}</p>,
    };
};

export default createPartnerEventCoverListItem;
