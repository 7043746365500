import React, { ChangeEvent, FocusEvent, KeyboardEvent, RefObject } from 'react';
import classNames from 'classnames';
import Regex from '../../utils/RegexCollection';
import InputType from '../../utils/constants/InputType';
import './TextField.scss';

export interface TextFieldProps {
    id?: string;
    innerRef?: RefObject<HTMLInputElement>;
    name: string;
    type?: InputType;
    label?: string | React.ReactNode;
    isError?: boolean;
    error?: string;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    value?: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    minLength?: number;
    maxLength?: number;
    inputMode?: 'text' | 'numeric';
    form?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
    id,
    innerRef,
    name,
    label,
    placeholder,
    type,
    value,
    isError,
    error,
    disabled,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    className,
    minLength,
    maxLength,
    inputMode,
    form,
}) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;

        if (type !== InputType.NUMBER) {
            onChange(e);
            return;
        }

        const allowedText = Regex.numbers;
        if (input === '' || allowedText.test(input)) {
            onChange(e);
        }
    };

    return (
        <div className={classNames('text-field', className)}>
            {label && (
                <label className={classNames('text-field__label', disabled && 'disabled')} htmlFor={id}>
                    {label}
                </label>
            )}
            <input
                id={id}
                ref={innerRef}
                name={name}
                // dont use number type, we'll handle not
                // allowing non-number on chnage instead
                // to avoid the default number input actions
                type={type === InputType.NUMBER ? InputType.TEXT : type}
                inputMode={inputMode}
                value={value}
                placeholder={placeholder}
                disabled={disabled}
                onChange={handleOnChange}
                onFocus={onFocus}
                onBlur={onBlur}
                minLength={minLength}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                className={classNames(
                    'text-field__input',
                    disabled && 'text-field__input--disabled',
                    isError && 'text-field__input--error',
                )}
                form={form}
            />
            {isError && error && <span className="text-field__error">{error}</span>}
        </div>
    );
};

export default TextField;
