import { FC } from 'react';
import withContent from '../../../../hoc/with-content/withContent';
import coverListCardContent from '../../../../content/ui/components/cover-list-card/coverListCard';
import './ActiveBadge.scss';

const contentMap = { activeBadge: 'ui.activeBadge' };

const ActiveBadge: FC<{ content: Record<keyof typeof contentMap, string> }> = ({ content }) => (
    <span className="cover-list-card__active-badge">{content.activeBadge}</span>
);

export default withContent(ActiveBadge, contentMap, coverListCardContent);
