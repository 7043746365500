import React from 'react';
import Lottie from 'react-lottie';
import { Button } from '../button/Button';
import { Layout } from '../layout/Layout';
import SuccessBgMobile from '../../assets/lotties/success-page-bg-mobile.json';
import SuccessBgTabletPortrait from '../../assets/lotties/success-page-bg-tablet-portrait.json';
import SuccessBgTabletLandscape from '../../assets/lotties/success-page-bg-tablet-landscape.json';
import SuccessBgDesktop from '../../assets/lotties/success-page-bg-desktop.json';
import ScheduleBoostSuccessIcon from '../../assets/lotties/success-icon.json';
import './SuccessPage.scss';

export interface SuccessPageProps {
    header: string | JSX.Element | JSX.Element[];
    icon?: any;
    description?: string | JSX.Element | JSX.Element[];
    buttonLabel?: string;
    buttonOnClick?: () => void;
}

export const SuccessPage: React.FC<SuccessPageProps> = ({
    icon = ScheduleBoostSuccessIcon,
    header,
    description,
    buttonLabel,
    buttonOnClick,
}) => {
    return (
        <Layout className="success-page" bgPattern={false} showPdsVersion={false}>
            <div className="success-page__animation-sm">
                <Lottie options={{ animationData: SuccessBgMobile, loop: false }} />
            </div>
            <div className="success-page__animation-md">
                <Lottie options={{ animationData: SuccessBgTabletPortrait, loop: false }} />
            </div>
            <div className="success-page__animation-lg">
                <Lottie options={{ animationData: SuccessBgTabletLandscape, loop: false }} />
            </div>
            <div className="success-page__animation-xl">
                <Lottie options={{ animationData: SuccessBgDesktop, loop: false }} />
            </div>
            <div className="success-page__container">
                <div className="success-page__icon">
                    <Lottie options={{ animationData: icon, loop: false }} width="100%" />
                </div>
                <h1>{header}</h1>
                {description && <p className="success-page__description">{description}</p>}
                {buttonLabel && (
                    <Button
                        variant="primary"
                        label={buttonLabel}
                        width="full"
                        className="success-page__btn"
                        onClick={buttonOnClick}
                    />
                )}
            </div>
        </Layout>
    );
};

export default SuccessPage;
