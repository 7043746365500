import { useMachine } from '@xstate/react';
import { format } from 'date-fns';
import { FC, FormEvent, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Destination, Region } from '../../business-logic/models/RoamingDestinations';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Fieldset from '../../components/form/fieldset/Fieldset';
import RadioButton from '../../components/form/radio-button/RadioButton';
import Layout from '../../components/layout/Layout';
import Sticky from '../../components/sticky/Sticky';
import roamingStartingRegionContent from '../../content/ui/screens/roaming-starting-region/roamingStartingRegion';
import withContent from '../../hoc/with-content/withContent';
import common from '../../strings/common';
import { PurchaseState } from '../../types/PurchaseState';
import Routes from '../../utils/Routes';
import DateFormat from '../../utils/constants/DateFormat';
import getTimezone from '../../utils/getTimezone';
import RoamingStartingRegionBaymaxMachine from './roaming-starting-region-baymax-machine/roamingStartingRegionBaymaxMachine';
import { useRoamingBaymax } from '../../business-logic/context-provider/RoamingBaymaxContext';

import './RoamingStartingRegion.scss';

const contentMap = {
    heading: 'ui.heading',
    description: 'ui.description',
};

interface RoamingStartingRegionBaymaxProps {
    content: Record<keyof typeof contentMap, string>;
}

const RoamingStartingRegionBaymax: FC<RoamingStartingRegionBaymaxProps> = ({ content }) => {
    const location = useLocation<PurchaseState>();
    const history = useHistory();
    const {
        loading: roamingLoading,
        initialised: roamingInitialised,
        chosenProductGroup,
        selectedProductOption,
        destinations,
    } = useRoamingBaymax();

    const [state, send] = useMachine(RoamingStartingRegionBaymaxMachine, {
        context: {
            selectedProductGrouping: chosenProductGroup,
            selectedProductOption,
            selectedDestination: location?.state?.destination?.startingDestination,
        },
        actions: {
            redirectToPurchase: () => {
                history.push({
                    pathname: Routes.SELECT_COVER_BAYMAX,
                    state: {
                        selectedProductGrouping: null,
                        selectedProductOption: null,
                        coverStartDates: [],
                        destination: null,
                    },
                });
            },
            redirectToSelectDates: (ctx) => {
                history.push({
                    pathname: Routes.ROAMING_DATES_BAYMAX,
                    state: {
                        destination: {
                            destinations: location.state.destination?.destinations,
                            startingDestination: location.state.destination?.startingDestination,
                            startingRegion: ctx.chosenRegion?.region,
                            timezone: ctx.chosenRegion?.timezone,
                        },
                    },
                });
            },
        },
    });

    const selectRegion = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        send({ type: 'CONTINUE' });
    };

    useEffect(() => {
        if (!roamingLoading && roamingInitialised) {
            const contextState = {
                fetchedDestinations: destinations as Destination[],
            };
            send({
                type: 'LOAD_REGIONS',
                data: contextState,
            });
        }
    }, [send, roamingLoading, roamingInitialised, destinations]);

    return (
        <Layout
            title={content.heading}
            isContentCentered
            showBackButton
            className="roaming-start-region__layout"
            showLoading={roamingLoading}
        >
            <p>{content.description}</p>
            {(!roamingInitialised || (destinations.length === 0 && !state.hasTag('loading'))) && (
                <Alert
                    type={AlertTypes.ALERT}
                    size={AlertSizes.LARGE}
                    message={ErrorMessages.refreshOrComebackWithApologies}
                />
            )}
            <form onSubmit={selectRegion}>
                <Fieldset legend={content.heading} visuallyHideLegend className="roaming-start-destinations__list">
                    {roamingInitialised &&
                        !state.hasTag('loading') &&
                        state.context.availableRegions.map((item: Region) => (
                            <div className="roaming__start__region-wrapper" key={item.region}>
                                <RadioButton
                                    id={item.region}
                                    name="destination__option"
                                    className="roaming__start__region"
                                    label={`${item.region} (${getTimezone(
                                        item.timezone,
                                        format(new Date(), DateFormat.DEFAULT),
                                    )})`}
                                    onChange={(e) => {
                                        send({
                                            type: 'SELECTED_REGIONS',
                                            data: e.target.checked ? item : null,
                                        });
                                    }}
                                    checked={state.context.chosenRegion?.region === item.region}
                                />
                                {Object.hasOwn(item, 'description') && (
                                    <p className="roaming-starting-region__description">{item.description}</p>
                                )}
                            </div>
                        ))}
                </Fieldset>
                <Sticky>
                    <Button
                        width="full"
                        type="submit"
                        variant="primary"
                        label={common.continue}
                        disabled={!state.context.chosenRegion}
                        className="roaming-destinations__cta"
                    />
                </Sticky>
            </form>
        </Layout>
    );
};

export default withContent(RoamingStartingRegionBaymax, contentMap, roamingStartingRegionContent);
