import CoverTypeId from '../../utils/constants/CoverTypeId';

export const enum PaymentStatus {
    CREATED = 'Created',
    SUCCEEDED = 'Succeeded',
    FAILED = 'Failed',
    CANCELED = 'Canceled',
    ABANDONED = 'Abandoned',
    OVERDUE = 'Overdue',
}

export const enum CycleStatus {
    STARTED = 'Started',
    OVERDUE = 'Overdue',
    PAID = 'Paid',
    CANCELED = 'Canceled',
    CREATED = 'Created',
    ENDED = 'Ended',
}

export const enum EventStatus {
    STARTED = 'Started',
    SCHEDULED = 'Scheduled',
    ENDED = 'Ended',
}

export const enum Status {
    CREATED = 'Created',
    STARTED = 'Started',
    SCHEDULED = 'Scheduled',
    ENDED = 'Ended',
    CANCELED = 'Canceled',
    INVALIDATED = 'Invalidated',
}

export type CoverStatus = Status;
export type CurrentCycleStatus = CycleStatus;
export type CoverPaymentStatus = PaymentStatus;
export type PartnerEventStatus = EventStatus;

export interface CoverItem {
    insuranceCoverId: string;
    insuredPersonId: string | null;
    partnerEventId?: string | null;
    businessPartnerId?: string | null;
    coverTypeId: CoverTypeId;
    coverCode: string;
    coverName?: string;
    startTime: string;
    endTime: string;
    isMainCover: boolean;
    status: CoverStatus;
    currentCycleStatus?: CurrentCycleStatus;
    currentCycleEndTime: string | null;
    nextCycleAt: string | null;
    paymentFailedReason: string;
    paymentStatus?: PaymentStatus;
    paymentAttempts?: number;
    coverTimezone: string | null;
    invalidatedAt?: string;
    invalidationReason?: any;
    cycle?: number;
    destination?: {
        destinations: string[];
        startingRegion: {
            region: string;
            timeZone: string;
        };
    };
}

export interface PolicyItem {
    insurancePolicyId: string;
    coverTypeId?: string;
    policyNumber: number;
    identityId: string;
    customerNumber?: number;
    categoryId?: string; // currently only one category
    productId?: string;
    PDSVersion: string;
    status: CoverStatus;
    mainCover: CoverItem;
    extraCovers: CoverItem[];
    policyTimezone?: string;
    ownerType?: string;
    businessAlias?: string;
    partnerEvent?: EventItem;
}

export interface EventItem {
    partnerEventId: string;
    eventName: string;
    startTime: string;
    endTime: string;
    timeZone: string;
    status: EventStatus;
}

export interface EventPolicyItem {
    insuranceCoverId: string;
    insurancePolicyId: string;
    policyNumber: number;
    identityId: string;
    customerNumber: number | null;
    status: CoverStatus;
    policyTimezone: string;
    partnerEvent: EventItem;
}

interface PoliciesResponse {
    policies: PolicyItem[];
    partnerEventPolicies?: EventPolicyItem[];
}

export default PoliciesResponse;
