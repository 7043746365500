import { page, track, identify, reset, trackV2 } from './segmentAnalytics';
import { PageEvent, AnalyticsUserTraits, AuthenticationMethod } from './Analytics.d';
import { getAnalyticsPageName } from './utils';

const Analytics = {
    // Screens tracking
    trackScreen: ([category, name]: PageEvent): void => {
        page(category, name);
    },

    // Identify the user
    identify: (traits?: Partial<AnalyticsUserTraits>, user_id?: string): void => {
        identify(traits, user_id);
    },

    // Auth events
    // Registration
    trackSignedUp: (traits?: Partial<AnalyticsUserTraits>): void => {
        setTimeout(() => {
            trackV2('Signed Up', traits);
        }, 200);
    },

    // Login
    trackSignedIn: (traits?: Partial<AnalyticsUserTraits>): void => {
        trackV2('Signed In', traits);
    },

    // Logout
    trackSignedOut: (traits?: Partial<AnalyticsUserTraits>): void => {
        trackV2('Signed Out', traits);
        reset();
    },

    reset: (): void => {
        reset();
    },

    trackBenefitsDrawerViewed: (screen_name: string, coverCode: string): void => {
        trackV2('Benefits Drawer triggered', { screen_name, coverCode });
    },

    trackProductListViewed: (list_name: string, products: any[]): void => {
        trackV2('Product List Viewed', { list_name, products });
    },

    trackProductGroupsViewed: (productGroups: any[]): void => {
        trackV2('Product Groups Viewed', { productGroups });
    },

    trackProductSelected: (product: Record<string, unknown>): void => {
        trackV2('Cover Selected for Purchase', { ...product });
    },

    trackProductClicked: (product: Record<string, unknown>): void => {
        trackV2('Product Clicked', { ...product });
    },

    trackProductViewed: (product: Record<string, unknown>): void => {
        trackV2('Product Viewed', { ...product });
    },

    trackProductAdded: (product: Record<string, unknown>): void => {
        trackV2('Product Added', { ...product });
    },

    trackTabChange: (screenName: string, product: Record<string, unknown>): void => {
        trackV2(`${screenName} Tab Clicked`, { ...product });
    },

    trackProductRemoved: (product: Record<string, unknown>): void => {
        trackV2('Product Removed', { ...product });
    },
    trackDatesSelected: (): void => {
        trackV2('Dates Selected');
    },
    trackDestinationsSelected: (destinations: any[]): void => {
        trackV2('Destinations Selected', { destinations });
    },

    trackStartDestinationSelected: (startingDestination: string): void => {
        trackV2('Starting Destination Selected', { startingDestination });
    },

    trackStartRegionSelected: (startingRegion: string): void => {
        trackV2('Starting Region Selected', { startingRegion });
    },

    trackSelectedPersonsToInsure: (accountHolder: boolean, dependants: number): void => {
        trackV2('Selected Persons to Insure', { accountHolder, dependants });
    },

    trackRoamingCancellation: (cancel_location: string): void => {
        trackV2('Cancel Flip Roaming Purchase', { cancel_location: cancel_location + ' slide' });
    },

    trackAppstoreRedirect: (platform: string) => {
        trackV2('Redirect to Mobile App Download', { platform });
    },

    trackPaymentInfoEntered: (checkout_id: string, step: number, payment_method: string): void => {
        trackV2('Payment Info Entered', {
            checkout_id,
            shipping_method: 'digital',
            payment_method,
            step,
        });
    },

    trackWalletCheckoutStarted: (checkout_id: string, step: number, payment_method: string): void => {
        trackV2('Wallet Checkout Started', {
            checkout_id,
            shipping_method: 'digital',
            payment_method,
            step,
        });
    },

    trackCouponEntered: (checkout_id: string, coupon_id: string): void => {
        trackV2('Coupon Entered', { checkout_id, coupon_id });
    },

    trackCouponApplied: (checkout_id: string, coupon_id: string, discount: number): void => {
        trackV2('Coupon Applied', { checkout_id, coupon_id, discount: discount / 100 });
    },

    trackCouponDenied: (checkout_id: string, coupon_id: string, reason: string): void => {
        trackV2('Coupon Denied', { checkout_id, coupon_id, reason });
    },

    trackCalendarOpened: (product: Record<string, unknown>): void => {
        trackV2('Calendar Opened', product);
    },

    trackCheckoutStarted: (properties: { products: Record<string, unknown>[] }): void => {
        trackV2('Checkout Started', properties);
    },

    trackCheckoutStepViewed: (step: number, step_name: string): void => {
        trackV2('Checkout Step Viewed', {
            shipping_method: 'digital',
            step_name,
            step,
        });
    },

    trackCheckoutStepCompleted: (step: number, step_name: string): void => {
        trackV2('Checkout Step Completed', {
            shipping_method: 'digital',
            step_name,
            step,
        });
    },

    trackCheckoutCompleted: (): void => {
        trackV2('Checkout Completed');
    },

    trackOrderCompleted: ({
        order_id,
        total,
        discount,
        coupon,
        products,
    }: {
        order_id: string;
        discount?: number;
        coupon?: string;
        total: number;
        products: Record<string, unknown>[];
    }): void =>
        trackV2('Order Completed', {
            order_id,
            total: total / 100,
            revenue: total / 100,
            discount: discount ? discount / 100 : undefined,
            coupon,
            currency: 'AUD',
            products,
        }),

    trackPolicyProfileCompleted: (): void => {
        trackV2('Policy Profile Completed');
    },

    trackReferralLinkShareClick: (
        method: 'native' | 'fb' | 'twitter' | 'email' | 'copy',
        referral_link: string,
    ): void => {
        trackV2('Referral Share Click', { method, referral_link });
    },

    trackOnboardingStartedWithReferral: (referral_code: string): void => {
        trackV2('Onboarding Started With Referral', { referral_code });
    },

    trackOnboardingStartedInvalidReferral: (referral_code: string): void => {
        trackV2('Onboarding Started Invalid Referral', { referral_code });
    },

    trackAusResidencyYes: (): void => {
        track('Onboarding_Aus_Residency_Click=Yes');
    },
    trackAusResidencyNo: (error: string): void => {
        track('Onboarding_Aus_Residency_Click=No', { error });
    },
    trackCoverScreenBuyNow: (): void => {
        track('Onboarding_Cover_Click=BuyNow');
    },
    trackCoverScreenBuyMaybeLater: (): void => {
        track('Onboarding_Cover_Click=BuyMaybeLater');
    },
    // Alpha TMD
    trackCoverScreenNotTheCoverYoureLookingFor: (): void => {
        track('Onboarding_Cover_Click=NotTheCoverYoureLookingFor');
    },
    trackReviewCoverClickProceedToPayment: (error?: string): void => {
        track('Onboarding_Review_Cover_Click=Proceed_To_Payment', { error });
    },
    trackOnboardingWelcomeScreenContinue: (): void => {
        track('Onboarding_Welcome_Click=Continue');
    },

    // Home events
    trackHomeActive: (): void => {
        track('Dashboard_Active_View');
    },
    trackDashboardWhatsCoveredClick: (): void => {
        track('Dashboard_Click=What_Is_Covered');
    },

    // Profile events

    trackProfileClickGetInTouch: (): void => {
        track('Profile_Click=GetInTouch');
    },
    trackProfileClickViewPrivacyPolicy: (): void => {
        track('Profile_Click=ViewPrivacyPolicy');
    },
    trackManageCoverClickViewCoverage: (): void => {
        track('Manage_Cover=ViewCoverage');
    },

    trackWhichCoverClick: (selectedCover: string): void => {
        track('Which_Cover_Click', { selectedCover });
    },
    trackYourCoverClickLearnMore: (selectedCover: string): void => {
        track('Your_Cover=LearnMore', { selectedCover });
    },
    trackYourCoverClickSkipForNow: (): void => {
        track('Your_Cover=SkipForNow');
    },
    trackWhatYouGetClickLetsGetStarted: (selectedCover?: string): void => {
        track('What_You_Get=LetsGetStarted', { selectedCover });
    },
    trackWhatYouGetClickNo: (): void => {
        track('What_You_Get=No');
    },
    trackScheduleAlwaysOnSuccessClickContinue: (): void => {
        track('Schedule_Always_On_Success=Continue');
    },
    trackScheduleJapanPassSuccessClickContinue: (): void => {
        track('Schedule_Japan_Pass_Success=Continue');
    },
    trackDashboardClickScheduleMoreDays: (): void => {
        track('Dashboard=ScheduleMoreDays');
    },
    trackDashboardClickCancelDayPass: (): void => {
        track('Dashboard=CancelDayPass');
    },
    trackDashboardClickScheduleMoreJapanPass: (): void => {
        track('Dashboard=ScheduleMoreJapanPass');
    },
    trackDashboardClickCancelJapanPass: (): void => {
        track('Dashboard=CancelJapanPass');
    },
    trackJapanPassCancelModalClickYes: (): void => {
        track('Dashboard_Japan_Pass_Cancel_Modal_Click=Yes');
    },
    trackJapanPassCancelModalClickNo: (): void => {
        track('Dashboard_Japan_Pass_Cancel_Modal_Click=No');
    },

    trackCoverageCancelled: (cover: string, reasonForCanceling: string[] | null, startingInDays?: number[]): void => {
        track('Coverage Cancelled', { cover, reasonForCanceling, startingInDays });
    },

    trackSignUpMethodClicked: (authenticationMethod: AuthenticationMethod): void => {
        track('Sign Up Method Clicked', { authenticationMethod });
    },

    trackSignInMethodClicked: (authenticationMethod: AuthenticationMethod): void => {
        track('Sign In Method Clicked', { authenticationMethod });
    },

    trackPDSLinkClicked: (): void => {
        track('PDS Link Clicked', { page: getAnalyticsPageName() });
    },

    trackTMDLinkClicked: (): void => {
        track('TMD Link Clicked', { page: getAnalyticsPageName() });
    },

    trackGSFLinkClicked: (): void => {
        track('Group Sport Factsheet Link Clicked', { page: getAnalyticsPageName() });
    },

    trackCoverageTierDetailsExpanded: (tier: number): void => {
        track('Coverage Tier Details Expanded', { tier });
    },

    trackOnboardingQuizAnswer: (
        question: 'existing-injury' | 'work-accidents' | 'motorcycling',
        answer: 'yes' | 'no',
    ): void => {
        trackV2('Onboarding Quiz Answer', { question, answer });
    },

    trackOnboardingQuizStarted: (): void => {
        trackV2('Onboarding Quiz Started');
    },

    trackOnboardingQuizCompleted: (): void => {
        trackV2('Onboarding Quiz Completed');
    },

    trackBeforeYouGoReason: (reason: string, feedback?: string): void => {
        trackV2('Before you go reason', { reason, feedback });
    },

    trackEditCoverDates: (): void => {
        trackV2('Edit cover dates');
    },

    trackSelectProductClickJapanPass: (): void => {
        track('Select_Product=JapanPass');
    },

    trackSelectProductClickAccidentInjury: (): void => {
        track('Select_Product=AccidentInjury');
    },

    trackSelectProductClickSkipForNow: (): void => {
        track('Select_Product=SkipForNow');
    },
};

export default Analytics;
