import { StoryFn, Meta } from '@storybook/react';
import MultipleInjuriesIconWithPattern from '../../assets/images/injuries/multiple-injuries-with-pattern.svg';
import BrokenBoneIconWithPattern from '../../assets/images/injuries/broken-bone-with-pattern.svg';
import ActionListComponent, { ActionListProps } from './ActionList';

export default {
    title: 'UI elements / Action List',
    component: ActionListComponent,
    parameters: {
        design: {
            type: 'figma',
            url: 'https://www.figma.com/file/AiU3BUpRicmO8uHsEZsdfJ/Flip-Incubation?node-id=862%3A1559',
        },
    },
} as Meta;

const Template: StoryFn<ActionListProps> = (args) => <ActionListComponent {...args} />;

export const ActionList = Template.bind({});
ActionList.args = {
    header: 'Existing claims',
    items: [
        {
            key: '1',
            header: 'Multiple injuries',
            data: 'Status: Draft',
            leftIcon: MultipleInjuriesIconWithPattern,
            onClick: () => {},
        },
        {
            key: '2',
            header: 'Broken bone',
            customDataElement: (
                <p className="claims__item">
                    Status: <span className="claims__item--approved">approved</span>
                </p>
            ),
            leftIcon: BrokenBoneIconWithPattern,
            onClick: () => {},
        },
        {
            key: '3',
            header: 'Multiple injuries',
            data: 'Status: Submitted',
            leftIcon: MultipleInjuriesIconWithPattern,
            onClick: () => {},
        },
    ],
};
