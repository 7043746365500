import LocalizedStrings from 'react-localization';

import femaleIcon from '../assets/images/gender/female.svg';
import maleIcon from '../assets/images/gender/male.svg';
import nonBinaryIcon from '../assets/images/gender/non-binary.svg';
import preferNotToSayIcon from '../assets/images/gender/prefer-not-to-say.svg';

const onboardingFlow = new LocalizedStrings({
    en: {
        onboardingStart: {
            title: 'Affordable protection for life’s adventures. Cancel anytime.',
        },
        residency: {
            title: 'Before we get started',
            residencyDescription: 'And your residency status',
            residencyOptions: {
                ausCitizen: 'Australian Citizen',
                nzCitizen: 'New Zealand Citizen',
                ausPR: 'Australian Permanent Resident',
                otherVisa: 'Other visa (working, student, etc)',
                tourist: "I'm visiting as a tourist",
            },
            residencyError:
                "We're sorry. <strong>Flip accidental injury insurance is only available to people that live in Australia.</strong> We'll let you know if this changes.",
            stateDescription: 'Select the state where you currently live',
            stateLabel: 'State',
            statePlaceholder: 'Select state',
            stateOptions: [
                { value: 'ACT', label: 'Australian Capital Territory' },
                { value: 'NSW', label: 'New South Wales' },
                { value: 'NT', label: 'Northern Territory' },
                { value: 'QLD', label: 'Queensland' },
                { value: 'SA', label: 'South Australia' },
                { value: 'TAS', label: 'Tasmania' },
                { value: 'WA', label: 'Western Australia' },
                { value: 'VIC', label: 'Victoria' },
            ],
            stateNotInAusOption: {
                value: 'NOT_IN_AUS',
                label: "I don't currently live in Australia",
            },
            stateError:
                "We're sorry. <strong>Because you don't currently live in Australia you can't purchase Flip cover.</strong> Look us up when you're back in Oz.",
        },
        dateOfBirth: {
            title: 'What’s your date of birth?',
            day: 'Day',
            month: 'Month',
            year: 'Year',
            errorInvalidDate: 'Sorry, the date doesn’t look right',
            errorMustBeOver18: 'Sorry, you need to be 18 or over to join Flip',
            errorMustBeUnder65: 'Sorry, you need to be 64 or under to join Flip',
            errorFutureDate: 'Sorry, you cannot enter a future date',
        },
        gender: {
            title: 'Select your gender',
            subtitle: 'The option you select will not alter your premium.',
            options: {
                female: { id: 'female', icon: femaleIcon, label: 'Female' },
                male: { id: 'male', icon: maleIcon, label: 'Male' },
                nonBinary: { id: 'non-binary', icon: nonBinaryIcon, label: 'Non-binary' },
                preferNotToSay: {
                    id: 'not-defined',
                    icon: preferNotToSayIcon,
                    label: 'I prefer not to say',
                },
            },
        },
        whatYouGet: {
            title: 'Here’s what you get',
            subtitle:
                "If you have an accident and suffer a specified injury as a result while your cover is active, here's what you get.",
            coverIncludesLabel: 'This includes:',
            coverIncludesPermanentLossOfLabel: 'This includes permanent loss of:',
            priceTitle: 'All for just:',
            whatsCovered: "What's covered",
            whatsNotCovered: "What's not covered",
            whatItCosts: 'What it costs',
            noOngoingCommitment: 'No ongoing commitment.',
            cancelAnytimeNoLockIt: 'Cancel anytime. No lock-in contracts.',
            whatYouGet: 'What you get',
            weekLabel: 'per week',
            infoAboutYourProtection:
                'Full details of your protection, who our target market is, including definitions are found in the {pds} and {tmd} documents.',
            infoAboutYourPrivacyPolicy: 'By clicking Continue you agree to our {privacyPolicy}.',
            disclaimer:
                'Flip advice is general in nature. We don’t take into account your personal circumstances, so please consider how appropriate it is for you before taking action. Flip is issued by HCF Life Insurance Pty Ltd, AFSL 236 806.',
            coverageOptions: {
                tier1: {
                    label: 'Ouch.',
                    description: 'For things like muscle sprains and minor dental injuries',
                    whatsCovered: [
                        'Sprains and strains to muscles, ligaments, tendons, or cartilage that are sudden onset',
                        'Wounds requiring stitches or ongoing care',
                        'Minor dental injuries which means concussion of the tooth or fracturing of the tooth enamel or dentin',
                        "Broken bones and dislocated joints that don't require surgery (with a hospital admission)",
                    ],
                    disclaimer:
                        "You'll need to see a health professional to confirm the injury within 14 days of your accident.",
                },
                tier2: {
                    label: 'Damn!',
                    description: 'For things like major surgery or major dental',
                    whatsCovered: [
                        'Surgery requiring admission to hospital',
                        'A displaced, fractured, or knocked out tooth, and as a result, you had major dental treatments like crown and bridge work, implants, dentures, splints, or root canal therapy.',
                    ],
                    disclaimer: 'Treatments must occur within 6 months of the accident.',
                },
                tier3: {
                    label: 'F!*%',
                    description: 'For really serious stuff like permanent disability',
                    whatsCovered: [
                        'Use of both arms, both legs, or one arm and one leg',
                        "Hearing in both ears which can't be corrected",
                        "Sight in both eyes which can't be corrected",
                    ],
                    disclaimer: 'For these unfortunate injuries, the loss needs to be total and irreversible.',
                },
            },
            notCovered: {
                description:
                    'There are some things we don’t cover. We won’t pay a benefit if your Specified Injury occurs as a result of:',
                overuse: 'Any overuse injury, like shin splints',
                overuseExpanded: 'We only cover injuries that are caused by accidents',
                dental: 'Dental injury when eating or drinking',
                dentalExpanded:
                    'Dental injury caused by food or drink including any foreign body in it while being consumed isn’t covered',
                motorcycling: 'Motorcycling',
                outsideAus: 'Accidents not in Australia',
                medical: 'Medical stuff',
                medicalExpanded:
                    'Any accident that happens directly as a result of, and mainly because of a medical episode, or a medical condition, or a complication relating to a medical procedure isn’t covered. For example, you won’t be covered where you suffer a specified injury that occurs as a result of fainting, and you also won’t be covered where you suffer a specified injury that occurs as a result of a car crash, if the car crash was caused by an epileptic seizure.',
                nonSportsWork: 'Work (except sport-related work)',
                nonSportsWorkExpanded:
                    'Paid work or employment isn’t covered (e.g. food delivery rider), except when the paid work or employment is a sports related activity (e.g. tennis coaching)',
                alcoholDrugs: 'Booze and drugs',
                alcoholDrugsExpanded:
                    'Being under the influence of alcohol, or taking illicit drugs, or taking medication in a way not as directed isn’t covered. Under the influence of alcohol means being over the prescribed legal limit for driving a motor vehicle according to the relevant state or territory limit.',
                suicide: 'Intentional self-injury',
                illegalAct: 'Breaking the law',
                illegalActExpanded: 'If you’re injured while involved in an illegal act, you’re not covered ',
                war: 'War or civil commotion',
                warExpanded:
                    'Acts of war, whether declared or not, hostilities, civil commotion, terrorism or insurrection aren’t covered',
                beforeCover: 'any Accident that occurs before the Cover starts; or',
                afterCoverEnds: 'any Accident that occurs after the Cover ends.',
                info: 'Also, there are claim limits. In a rolling 12-month rolling period you can only claim:',
                infoList: [
                    'Two times for the same injury on the same part of your body',
                    'Four times for Tier 1 injuries',
                ],
                promise: 'That’s it, we swear.',
            },
            isThisTheCoverYoureLookingFor: 'Is this the type of cover you’re looking for?',
            letsGetStarted: 'Yes, let’s get started',
            noModalContent:
                'Because you’ve answered no, we’re letting you know you might not be in the target market for this product.',
        },
        beforeYouGo: {
            title: 'Before you go',
            reasons: {
                notEnough: 'Not enough value for money',
                doesNotCover: 'It doesn’t cover me for what I need',
                dontNeed: 'I don’t need it now',
            },
            somethingElse: 'Something else',
            tellUsMore: 'Tell us more',
            whatsMissing: "What's Missing?",
            didNotWork: 'I’m leaving because',
            exitToDashboard: 'Exit to Dashboard',
            sendAndExitToDashboard: 'Send and exit to Dashboard',
            exit: 'Exit',
            sendAndExit: 'Send and exit',
        },
        reviewCover: {
            title: 'Review your cover',
            buttonLabel: 'Go to payment',
            coverItems: {
                singleTitle: 'Your Cover',
                subscriptionTitle: 'Your Subscription',
                startImmediatelyDescription: 'Starts: {date} (starts now)',
                startScheduledDescription: 'Starts: {date} at 12:00AM {time}',
                japanTimeZone: 'Japan (GMT+9)',
            },
            whatYouGetTitle: 'What you get',
            yourDetailsTitle: 'Your details',
            protectionDetailsListHeaders: {
                starting: 'Starting',
                payment: 'Payment',
                cancel: 'Cancel',
            },
            cancelLabel: {
                singleCover: 'Anytime before your {name} starts, (you can’t cancel your cover once it’s started).',
                subscriptionCover: 'Cancel anytime. If you do, cover ends at the end of your billing cycle.',
            },
            agreementTitle: "By clicking '{ctaLabel}' you agree that:",
            agreementBulletPoint1:
                'You have considered whether this cover is right for you, having regard to the {pdsFsg}.',
            agreementBulletPoint1PdsFsg: 'PDS and FSG',
            agreementBulletPoint2: 'Payment will be processed in accordance with the {directDebitAgreement}.',
            agreementBulletPoint3:
                'Your details are correct. If your details are incorrect please reach out to {customerSupport}.',
            errorYouAlreadyHaveAPolicy: 'You already have an active policy and cannot purchase multiple.',
            errorInvalidDob: 'Sorry, you need to be 64 or under to apply for cover.',
            errorMissingCoverSelection:
                'Sorry, looks like some cover selection details are missing. Please try re-entering them',
            residencyStatusHeader: 'Residency Status',
            residencyStatuses: {
                ausCitizen: 'Australian Citizen',
                ausPR: 'Australian Permanent Resident',
                nzCitizen: 'New Zealand Citizen',
                otherVisa: 'Other visa (working, student, etc)',
            },
            stateHeader: 'State where you live',
        },
        yourActivities: {
            title: 'Help us get to know you better',
            description: 'What activities do you do?',
            activities: {
                climbing: 'Climbing, hiking or trail running',
                cycling: 'Cycling',
                gym: 'Gym',
                yoga: 'Yoga, barre or pilates',
                ballSports: 'Ball sports',
                snowSports: 'Snow sports',
                waterSports: 'Water sports',
                enduranceSports: 'Endurance sports',
                combatSports: 'Combat sports',
                motorcycling: 'Motorcycling',
                other: 'Other',
            },
            labels: {
                otherTextbox: 'Please Specify:',
            },
            motorcyclingModal: {
                title: "We don't cover injuries from riding a motorcycle.",
                alertText:
                    "If you're looking for specific motorcycle injury cover, consider if Flip cover is right for you.",
            },
        },
    },
});

export default onboardingFlow;
