import { FC, useEffect, useMemo, useState } from 'react';
import { add } from 'date-fns';
import datePickerModal from '../../content/ui/components/date-picker-modal/datePickerModal';
import withContent from '../../hoc/with-content/withContent';

import Modal from '../modal/Modal';
import NewDatePicker from '../new-date-picker/NewDatePicker';
import { NewDatePickerProps } from '../new-date-picker/types';
import CalendarButton from './components/CalendarButton/CalendarButton';

import './DatePickerModal.scss';

const contentMap = {
    singleTitle: 'ui.singleTitle',
    multipleTitle: 'ui.multipleTitle',
    weekTitle: 'ui.weekTitle',
    scheduleInfo: 'ui.scheduleInfo',
};

export type DatePickerModalProps = NewDatePickerProps & {
    content: Record<keyof typeof contentMap, string>;
    parentClass?: string;
    disabled?: boolean;
    onCalendarOpen?: () => void;
    changeButtonOnDateSelected?: boolean;
};

const DatePickerModal: FC<DatePickerModalProps> = ({
    content,
    parentClass = '#root',
    disabled,
    onCalendarOpen,
    changeButtonOnDateSelected,
    ...props
}) => {
    const { mode, value, onChange } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value);

    const onClose = () => {
        setIsOpen(false);
    };

    const onValueChange = (date: Date[]) => {
        setIsOpen(false);
        onChange(date);
        setSelectedValue(date);
    };

    const onOpenModal = () => {
        setIsOpen(true);

        if (onCalendarOpen) {
            onCalendarOpen();
        }
    };

    const modalTitle = useMemo(() => {
        let title = content.singleTitle;
        if (mode === 'multiple') {
            title = content.multipleTitle;
        } else if (mode === 'week') {
            title = content.weekTitle;
        }
        return title;
    }, [mode, content]);

    useEffect(() => {
        setSelectedValue(value);
    }, [value]);

    // date picker constraints (can be from up to 12 months from current date)
    const todayDate = new Date();
    const aYearFromToday = add(todayDate, { months: 12 });

    return (
        <div className="date-picker-modal">
            <CalendarButton
                mode={mode}
                onClick={onOpenModal}
                selectedDates={selectedValue}
                disabled={disabled}
                changeButtonOnDateSelected={changeButtonOnDateSelected}
            />
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                parentClass={parentClass}
                variant="slide-up"
                className="date-picker-modal__modal"
            >
                <div className="date-picker-modal__header">
                    <span>{modalTitle}</span>
                    <div role="none" className="date-picker-modal__close" onClick={onClose} />
                </div>

                <NewDatePicker
                    fromMonth={todayDate}
                    toMonth={aYearFromToday}
                    {...props}
                    value={selectedValue}
                    onChange={onValueChange}
                    label={content.scheduleInfo}
                />
            </Modal>
        </div>
    );
};

export default withContent(DatePickerModal, contentMap, datePickerModal);
