import { Destination, RoamingDestinationsResponse } from '../../business-logic/models/RoamingDestinations';
import CoverCode from '../../utils/constants/CoverCode';
import asService, { ServiceArgs } from '../utils/asService';
import isAxios404Error from '../utils/isAxios404Error';

const baseApiPath = import.meta.env.VITE_BASE_CDN_PATH;

type transformDestinationsProps = {
    data: RoamingDestinationsResponse;
    coverCode: CoverCode;
    PDSVersion: string;
};

const getCoverDestinations = ({ data, coverCode, PDSVersion }: transformDestinationsProps) => {
    return data[coverCode as keyof RoamingDestinationsResponse][PDSVersion].destinations;
};

const getCoverConfig = async ({
    axios,
    coverCode,
    PDSVersion,
}: {
    coverCode: CoverCode;
    PDSVersion: string;
} & ServiceArgs): Promise<Destination[]> => {
    try {
        const { data } = await axios.get(`${baseApiPath}/json/coverConfig.json`);
        return getCoverDestinations({ data, coverCode, PDSVersion });
    } catch (error) {
        if (isAxios404Error(error)) {
            throw new Error(`Unable to retrieve data from Flip content delivery network.`);
        }
        throw new Error(`Error retrieving destination resource from Flip content delivery network`);
    }
};

export default asService(getCoverConfig, false);
