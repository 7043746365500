import classNames from 'classnames';
import { addDays, format, isAfter, isBefore, isEqual } from 'date-fns';
import React, { useMemo, useRef, useState } from 'react';
import DayPicker from 'react-day-picker';
import { DayModifiers } from 'react-day-picker/types/Modifiers';

import dateFormat from '../../../../utils/constants/DateFormat';
import Alert, { AlertTypes } from '../../../alert/Alert';
import defaultClassNames from '../../constants';
import { NewDatePickerProps } from '../../types';
import CalendarLabel from '../calendar-label/CalendarLabel';

import './RangePicker.scss';

type DateRange = {
    from: Date;
    to: Date;
};

/**
 * Enables selecting multiple date ranges of variable
 * lengths starting from 1 day
 * @returns {Date[]} - List of cover start dates
 */

const RangePicker: React.FC<NewDatePickerProps> = ({
    id,
    innerRef,
    onFocus,
    onBlur,
    onChange,
    value,
    monthToShow,
    fromMonth,
    toMonth,
    disabledDays,
    modifiers,
    showYearInCaption,
    className,
    error,
    label,
    rangeInDays,
    unitLabel,
    hideUnitLabel = false,
}) => {
    const [hoveredDays, setHoveredDays] = useState<DateRange | null>(null);
    const [disabledRanges, setDisabledRanges] = useState<DateRange[]>([]);
    const weeksContainerRef = useRef<HTMLDivElement>(null);

    const savedRanges = useMemo(() => {
        return value.map((startDate) => ({
            from: startDate,
            to: addDays(startDate, rangeInDays! - 1),
        }));
    }, [value, rangeInDays]);

    const sortRanges = (a: DateRange, b: DateRange) => a.from.getTime() - b.from.getTime();

    const handleDayMouseEnter = (day: Date, m: DayModifiers) => {
        if (m.disabled) return;
        setHoveredDays({
            from: day,
            to: addDays(day, rangeInDays! - 1),
        });
    };

    const handleDayMouseLeave = () => {
        setHoveredDays(null);
    };

    const handleTouchMouseLeave = () => {
        // On touch screens, the events that get fired when a date
        // is selected are in the following order
        // touch enter, touch leave, mouse enter
        // the mouse is captured and not released on touch
        // causing a hover state to remain after touch leave
        // we add a delay here to set hovered to null on touch leave
        // to override mouse enter firing after touch leave
        // and release the hovered state
        setTimeout(() => {
            setHoveredDays(null);
        }, 50);
    };

    const isRangeOverlapped = (range1: DateRange | null, range2: DateRange | null) => {
        if (!range2 || !range1) {
            return false;
        }
        return (
            (isBefore(range1.from, range2.to) || isEqual(range1.from, range2.to)) &&
            (isAfter(range1.to, range2.from) || isEqual(range1.to, range2.from))
        );
    };

    const onDayClickHandler = (day: Date, m: DayModifiers) => {
        if (!day || m.disabled) {
            return;
        }
        const newRange = {
            from: day,
            to: addDays(day, rangeInDays! - 1),
        };
        const sr = savedRanges.filter((range) => !isRangeOverlapped(range, newRange));
        onChange([newRange, ...sr].sort(sortRanges).map((x) => x.from));
        setHoveredDays(null);
    };

    const mods = {
        ...modifiers,
        hoveredRange: rangeInDays === 1 || !hoveredDays ? undefined : hoveredDays,
        hoveredStart: rangeInDays === 1 || !hoveredDays ? undefined : hoveredDays.from,
        hoveredEnd: rangeInDays === 1 || !hoveredDays ? undefined : hoveredDays.to,
        selected: rangeInDays === 1 ? savedRanges.map((x) => x.from) : undefined,
        savedRange: savedRanges.filter((range) => !isRangeOverlapped(range, hoveredDays)),
        savedStart:
            rangeInDays === 1
                ? []
                : savedRanges.filter((range) => !isRangeOverlapped(range, hoveredDays)).map((range) => range.from),
        savedEnd:
            rangeInDays === 1
                ? []
                : savedRanges.filter((range) => !isRangeOverlapped(range, hoveredDays)).map((range) => range.to),

        disabledRange:
            rangeInDays === 1
                ? []
                : disabledRanges.filter(
                      (range) => !isRangeOverlapped(range, hoveredDays) && range.from.getTime() !== range.to.getTime(),
                  ),
        disabledStart:
            rangeInDays === 1
                ? []
                : disabledRanges
                      .filter(
                          (range) =>
                              !isRangeOverlapped(range, hoveredDays) && range.from.getTime() !== range.to.getTime(),
                      )
                      .map((range) => range.from),
        disabledEnd:
            rangeInDays === 1
                ? []
                : disabledRanges
                      .filter(
                          (range) =>
                              !isRangeOverlapped(range, hoveredDays) && range.from.getTime() !== range.to.getTime(),
                      )
                      .map((range) => range.to),
        disabledRangeDay:
            rangeInDays === 1
                ? []
                : disabledRanges
                      .filter(
                          (range) =>
                              !isRangeOverlapped(range, hoveredDays) && range.from.getTime() === range.to.getTime(),
                      )
                      .map((range) => range.from),
    };

    const renderDay = (day: Date) => {
        const date = day.getDate();
        return (
            <div className="day-wrapper">
                <div className="day-value">{date}</div>
            </div>
        );
    };

    const onRemoveDateClick = (date: string) => {
        onChange(savedRanges.filter(({ from }) => format(from, dateFormat.DATE) !== date).map((x) => x.from));
    };

    const renderCaptionElement = ({ date }: { date: Date }) => (
        <div className="DayPicker-Caption">
            <p>{`${format(date, 'MMMM').toUpperCase()} ${showYearInCaption ? format(date, 'Y') : ''}`}</p>
            {label && <CalendarLabel text={label} />}
        </div>
    );

    const computedDisabledDays = useMemo(() => {
        const disabledDaysArray = Array.isArray(disabledDays) ? disabledDays : [disabledDays];

        const oneDayInMs = 24 * 60 * 60 * 1000;
        const oneWeekInMs = rangeInDays! * oneDayInMs;
        const dRanges: DateRange[] = [];

        const rangesDisabled = savedRanges.flatMap((range) => {
            return [
                { after: new Date(range.from.getTime() - oneWeekInMs), before: new Date(range.from.getTime()) },
                {
                    after: new Date(range.from.getTime() - oneDayInMs),
                    before: new Date(range.to.getTime() + oneDayInMs),
                },
            ];
        });

        for (let i = 0; i < savedRanges.length - 1; i += 1) {
            const currentRange = savedRanges[i];
            const nextRange = savedRanges[i + 1];

            if (nextRange.from.getTime() - currentRange.to.getTime() > oneDayInMs * rangeInDays!) {
                dRanges.push({
                    from: new Date(nextRange.from.getTime() - oneDayInMs * rangeInDays! - 1),
                    to: new Date(nextRange.from.getTime() - oneDayInMs),
                });
            } else {
                dRanges.push({
                    from: new Date(currentRange.to.getTime() + oneDayInMs),
                    to: new Date(nextRange.from.getTime() - oneDayInMs),
                });
            }
        }

        setDisabledRanges(dRanges);
        return [...rangesDisabled, ...disabledDaysArray];
    }, [disabledDays, rangeInDays, savedRanges]);

    return (
        <div
            id={id}
            ref={innerRef}
            className={classNames(
                rangeInDays !== 1 && 'date-picker-week',
                rangeInDays === 1 && 'date-picker-multiple-days',
                className,
            )}
        >
            <DayPicker
                renderDay={renderDay}
                className={classNames('day-picker', className)}
                classNames={{
                    ...defaultClassNames,
                    day: 'DayPicker-Day-custom',
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onDayClick={onDayClickHandler}
                month={monthToShow}
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={computedDisabledDays}
                modifiers={mods}
                fixedWeeks
                onDayMouseEnter={handleDayMouseEnter}
                onDayMouseLeave={handleDayMouseLeave}
                onDayTouchStart={handleDayMouseEnter}
                onDayTouchEnd={handleTouchMouseLeave}
                captionElement={renderCaptionElement}
            />
            {error && <Alert className="date-picker__alert" message={error} type={AlertTypes.INFO} />}
            {!!value.length && (
                <>
                    <div
                        className={classNames('range-picker__dates', {
                            'range-picker__selected': value.length >= 3,
                        })}
                        ref={weeksContainerRef}
                    >
                        {value.map((x, index) => {
                            const date = format(x, dateFormat.DATE);
                            const keyRow = date + index;
                            return (
                                <div key={keyRow}>
                                    <div className="range-picker__date">
                                        <div className="range-picker__date-label">
                                            {unitLabel} {hideUnitLabel === true ? '' : index + 1}:
                                        </div>
                                        <div className="range-picker__date-value">Starting {date}</div>
                                        <div
                                            role="none"
                                            className="range-picker__date-remove"
                                            onClick={() => onRemoveDateClick(date)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default RangePicker;
