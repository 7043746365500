import createDependants from './createDependants';
import createGuestDependants from './createGuestDependants';
import getDependants from './getDependants';
import getGuestDependants from './getGuestDependants';

export default {
    createDependants,
    createGuestDependants,
    getDependants,
    getGuestDependants,
};
