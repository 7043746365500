import { FC, useState } from 'react';
import coverIcon from '../../assets/images/health-beauty-covered.svg';
import purchaseMoreCover from '../../content/ui/components/purchase-more-cover/purchaseMoreCover';
import withContent from '../../hoc/with-content/withContent';
import CoverCode from '../../utils/constants/CoverCode';
import {
    LatestFlipActiveSubMonthlyPdsVersion,
    LatestFlipKidsSubMonthlyPdsVersion,
    LatestFlipRoamingPdsVersion,
} from '../../utils/constants/PdsVersion';
import { Button } from '../button/Button';
import SlideInCoverTerms from '../slide-in-cover-terms/SlideInCoverTerms';

import './PurchaseMoreCover.scss';

const contentMap = {
    header: 'ui.header',
    title: 'ui.title',
    buyNowCTA: 'ui.buyNowCTA',
    activeCTA: 'ui.activeCTA',
    kidsCTA: 'ui.kidsCTA',
    roamingCTA: 'ui.roamingCTA',
    whatYouGetDisclaimer: 'ui.whatYouGetDisclaimer',
};

export interface PurchaseMoreCoverProps {
    onBuyNowClick: () => void;
    content: Record<keyof typeof contentMap, string>;
}

const PurchaseMoreCover: FC<PurchaseMoreCoverProps> = ({ onBuyNowClick, content }) => {
    const [isActiveBenefitsOpen, setIsActiveBenefitsOpen] = useState(false);
    const [isKidsBenefitsOpen, setIsKidsBenefitsOpen] = useState(false);
    const [isRoamingBenefitsOpen, setIsRoamingBenefitsOpen] = useState(false);

    return (
        <div className="PurchaseMoreCover">
            <p className="PurchaseMoreCover__title">{content.header}</p>
            <Button label={content.buyNowCTA} className="PurchaseMoreCover__buy-now-btn" onClick={onBuyNowClick} />
            <div className="PurchaseMoreCover__promo">
                <div className="PurchaseMoreCover__promo-heading">
                    <div className="PurchaseMoreCover__icon">
                        <img src={coverIcon} alt="health-beauty-covered" />
                    </div>
                    <div className="PurchaseMoreCover__heading-content">
                        <p className="PurchaseMoreCover__heading-title">{content.title}</p>
                    </div>
                </div>
                <div className="PurchaseMoreCover__promo-content">
                    <Button
                        variant="drawer-link"
                        label={content.activeCTA}
                        onClick={() => setIsActiveBenefitsOpen(true)}
                    />
                    <SlideInCoverTerms
                        coverCode={CoverCode.FLIP_ACTIVE_SUB_MONTHLY}
                        pdsVersion={LatestFlipActiveSubMonthlyPdsVersion}
                        showGroupPrice
                        isOpen={isActiveBenefitsOpen}
                        onOpen={() => setIsActiveBenefitsOpen(true)}
                        onClose={() => setIsActiveBenefitsOpen(false)}
                    />
                    <div className="PurchaseMoreCover__line" />
                    <Button variant="drawer-link" label={content.kidsCTA} onClick={() => setIsKidsBenefitsOpen(true)} />
                    <SlideInCoverTerms
                        coverCode={CoverCode.FLIP_KIDS_SUB_MONTHLY}
                        pdsVersion={LatestFlipKidsSubMonthlyPdsVersion}
                        showGroupPrice
                        isOpen={isKidsBenefitsOpen}
                        onOpen={() => setIsKidsBenefitsOpen(true)}
                        onClose={() => setIsKidsBenefitsOpen(false)}
                    />
                    <div className="PurchaseMoreCover__line" />
                    <Button
                        variant="drawer-link"
                        label={content.roamingCTA}
                        onClick={() => setIsRoamingBenefitsOpen(true)}
                    />
                    <SlideInCoverTerms
                        coverCode={CoverCode.FLIP_ROAMING_WEEKLY}
                        pdsVersion={LatestFlipRoamingPdsVersion}
                        isOpen={isRoamingBenefitsOpen}
                        onOpen={() => setIsActiveBenefitsOpen(true)}
                        onClose={() => setIsRoamingBenefitsOpen(false)}
                    />
                </div>
            </div>
        </div>
    );
};

export default withContent(PurchaseMoreCover, contentMap, purchaseMoreCover);
