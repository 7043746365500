import LocalizedStrings from 'react-localization';

const cancelCoverDrawer = new LocalizedStrings({
    en: {
        cancelYourCover: 'Cancel your cover',
        cancelFirstNameCover: "Cancel {firstName}'s cover",
        starting: 'Starting {startDate}',
        destination: '{destination}',
        endTime: 'If you decide to cancel your cover, you will be protected until {endTime}',
        renews: 'Next billing date {renewDate}',
        cancelSubscriptionInfo: 'If you decide to cancel your cover, you will be protected until {endDateTime}.',
        cancelInfo: 'If you have active cover by the day or the week, you cannot cancel once it has started.',
        cancelCoverCTA: 'Cancel cover',
    },
});

export default cancelCoverDrawer;
