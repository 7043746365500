import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../../../analytics/Analytics';
import blackArrow from '../../../../assets/images/arrow-left-black.svg';
import ticketWhite from '../../../../assets/images/icon-ticket-white.svg';
import ticketYellow from '../../../../assets/images/icon-ticket-yellow.svg';
import SlideInCoverTerms from '../../../../components/slide-in-cover-terms/SlideInCoverTerms';
import useCoverInformation from '../../../../hooks/useCoverInformation';
import { Route } from '../../../../utils/Routes';
import CoverCode from '../../../../utils/constants/CoverCode';
import CoverStatus from '../../../../utils/constants/CoverStatus';
import DateFormat from '../../../../utils/constants/DateFormat';
import PageTitles from '../../../../utils/constants/PageTitles';
import formatISO8601DateTime from '../../../../utils/formatISO8601DateTime';
import getTimezone from '../../../../utils/getTimezone';

import './ManageCoverCard.scss';

interface ManageCoverEventCardProp {
    status: CoverStatus;
    coverCode: string | null;
    title: string;
    startTime: string;
    endTime: string;
    destinations?: string[];
    pdsVersion: string;
    timeZone: string;
}

const ManageCoverEventCard = ({
    status,
    coverCode,
    title,
    startTime,
    endTime,
    destinations,
    pdsVersion,
    timeZone,
}: ManageCoverEventCardProp) => {
    const history = useHistory();
    const defaultDate = formatISO8601DateTime(startTime, timeZone, DateFormat.DEFAULT);
    const coverInformation = useCoverInformation(coverCode ?? '', pdsVersion);
    const [selectedCoverForBenefits, setSelectedCover] = useState<CoverCode | null>(null);
    const pageName = PageTitles[history?.location.pathname as Route];

    return (
        <div
            className={classNames(
                'manage-cover-card',
                status === CoverStatus.INACTIVE && 'manage-cover-card--inactive',
            )}
        >
            <div className="manage-cover-card__header">
                <div className="manage-cover-card__header__wrapper">
                    <div className="manage-cover-card__header__content">
                        <img
                            src={
                                status === CoverStatus.INACTIVE
                                    ? coverInformation?.inverseIcon || ticketWhite
                                    : coverInformation?.icon || ticketYellow
                            }
                            alt=""
                            className="manage-cover-card__header__content__icon"
                        />
                        <span className="manage-cover-card__header__content__text">{title}</span>
                    </div>
                    {status === CoverStatus.ACTIVE && (
                        <div className="manage-cover-card__header__status">
                            <span>{capitalize(status)}</span>
                        </div>
                    )}
                </div>
                {coverCode && (
                    <button
                        className="manage-cover-card__header__button"
                        type="button"
                        onClick={() => {
                            setSelectedCover((coverCode as CoverCode) ?? null);
                            Analytics.trackBenefitsDrawerViewed(pageName, coverCode);
                        }}
                    >
                        <img
                            src={blackArrow}
                            className="manage-cover-card__header__button__icon"
                            alt="See insurance benefits"
                        />
                    </button>
                )}
            </div>
            <div className="manage-cover-card__date">
                <span className="manage-cover-card__date__label">
                    {status === CoverStatus.INACTIVE ? 'Started:' : 'Starts:'}
                </span>
                <span className="manage-cover-card__date__item">
                    {formatISO8601DateTime(startTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                </span>
            </div>
            <div className="manage-cover-card__date">
                <span className="manage-cover-card__date__label">
                    {status === CoverStatus.INACTIVE ? 'Ended:' : 'Ends:'}
                </span>
                <span className="manage-cover-card__date__item">
                    {formatISO8601DateTime(endTime, timeZone, DateFormat.VERBOSE_WITH_DAY_TIME)}
                </span>
            </div>
            {!!destinations && <span className="manage-cover-card__destinations">{destinations.join(', ')}</span>}
            <div className="manage-cover-card__footer">
                <small className="manage-cover-card__footer__timezone">
                    Times displayed in {getTimezone(timeZone, defaultDate)}
                </small>
                {coverCode && <small className="manage-cover-card__footer__pds">PDS version {pdsVersion}</small>}
            </div>
            {!coverCode && (
                <div className="manage-cover-card--event__nocover">
                    <span>
                        Please read the details on our website’s Group Active page for coverage details{' '}
                        <a
                            className="manage-cover-card--event__nocover__link"
                            href="https://www.flipinsurance.com.au/covered-group-active"
                            target="_blank"
                            rel="noreferrer"
                        >
                            here
                        </a>
                    </span>
                </div>
            )}
            {coverCode && (
                <SlideInCoverTerms
                    coverCode={coverCode as CoverCode}
                    pdsVersion={pdsVersion}
                    isOpen={selectedCoverForBenefits === coverCode}
                    onOpen={() => {
                        setSelectedCover((coverCode as CoverCode) ?? null);
                    }}
                    onClose={() => setSelectedCover(null)}
                    hidePriceChip
                />
            )}
        </div>
    );
};

export default ManageCoverEventCard;
