import { Destination } from '../../../../business-logic/models/RoamingDestinations';
import { RoamingStartDestinationBaymaxMachineContextTypes } from '../context/roamingStartDestinationBaymaxMachineContext';

export const hasMultipleRegions = (ctx: RoamingStartDestinationBaymaxMachineContextTypes): boolean => {
    const destination = ctx.availableDestinations.find(
        (item: Destination) => item.destination === ctx.startDestination,
    );
    return !!destination && destination.regions.length > 1;
};

export const areDestinationsChosen = (ctx: RoamingStartDestinationBaymaxMachineContextTypes): boolean =>
    !!ctx.chosenDestinations.length;
