import React from 'react';
import { useRoamingBaymax } from '../../business-logic/context-provider/RoamingBaymaxContext';
import Alert, { AlertSizes, AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Layout from '../../components/layout/Layout';

/**
 * This HOC checks whether there is a valid roaming context in the application
 * specifically for the baymax consolidated purchase flow.
 * If there is no valid roaming baymax context for a specific route, then will redirect
 * to the beginning of the purchase flow.
 *
 */
function requireRoamingBaymaxContext<T extends JSX.IntrinsicAttributes>(WrappedComponent: React.FC<T>): React.FC<T> {
    return (props: T) => {
        const { initialised, error } = useRoamingBaymax();

        if (initialised && error) {
            return (
                <Layout isContentCentered>
                    <Alert
                        type={AlertTypes.ALERT}
                        size={AlertSizes.LARGE}
                        message={ErrorMessages.refreshOrComebackWithApologies}
                    />
                </Layout>
            );
        }
        return <WrappedComponent {...props} />;
    };
}

export default requireRoamingBaymaxContext;
