import fromUnixTime from 'date-fns/fromUnixTime';
import format from 'date-fns-tz/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import enAU from 'date-fns/locale/en-AU';
import DateFormat from './constants/DateFormat';

const unixNumToString = (date: number, timeZone: string, toFormat: string = DateFormat.DEFAULT): string =>
    format(utcToZonedTime(fromUnixTime(date), timeZone), toFormat, {
        timeZone,
        locale: enAU,
    });

export default unixNumToString;
