import { compareAsc, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';

import { useUser } from '../../../business-logic/context-provider/user-context';
import { CheckoutDetailsResponse, CoverResponse } from '../../../business-logic/models/CheckoutDetails';
import { ListItemProps } from '../../../components/list-item/ListItem';
import useLazyDependency from '../../../hooks/lazy-dependency/useLazyDependency';

import ProductResponse from '../../../business-logic/models/ProductResponse';
import createAccountCreditListItem from './list-item-creators/createAccountCreditListItem';
import createAmountDueListItem from './list-item-creators/createAmountDueListItem';
import createDiscountCodeListItem from './list-item-creators/createDiscountCodeListItem';
import createScheduledCoverListItem from './list-item-creators/createScheduledCoverListItem';

import './CheckoutSummaryBaymax.scss';

interface CheckoutSummaryBaymaxProps {
    products: ProductResponse[];
    checkoutDetails: CheckoutDetailsResponse;
    covers: CoverResponse[];
}

const CheckoutSummaryBaymax: FC<CheckoutSummaryBaymaxProps> = ({ products, checkoutDetails, covers }) => {
    const { creditBalance: cbLazyDepObject, userTimeZone } = useUser();
    const creditBalance = useLazyDependency(cbLazyDepObject);
    const productNames = products.map((x) => x.productSpec.mainCoverType.coverName);

    const items: Record<string, ListItemProps[]> = useMemo(() => {
        const list =
            covers
                .sort((a, b) => compareAsc(parseISO(a.startTime), parseISO(b.startTime)))
                .reduce((acc: Record<string, ListItemProps[]>, item) => {
                    const invoiceItem = checkoutDetails.invoice.items.find(
                        (iItem) => iItem.insuranceCoverId === item.insuranceCoverId,
                    );

                    if (!invoiceItem) {
                        return acc;
                    }
                    const scheduleCoverListItem = {
                        item,
                        invoiceItem,
                        userTimeZone: item.destination?.startingRegion.timeZone || userTimeZone,
                        header: invoiceItem.description,
                    };

                    if (!Object.hasOwn(acc, invoiceItem.description)) {
                        acc[invoiceItem.description] = [
                            createScheduledCoverListItem(
                                scheduleCoverListItem.item,
                                scheduleCoverListItem.invoiceItem,
                                scheduleCoverListItem.userTimeZone,
                                scheduleCoverListItem.header,
                            ),
                        ];
                    } else {
                        acc[invoiceItem.description].push(
                            createScheduledCoverListItem(
                                scheduleCoverListItem.item,
                                scheduleCoverListItem.invoiceItem,
                                scheduleCoverListItem.userTimeZone,
                            ),
                        );
                    }
                    return acc;
                }, {}) ?? null;

        if (checkoutDetails.coupon) {
            list.discountCodeItem = [createDiscountCodeListItem(checkoutDetails)];
        }

        if (creditBalance.value && checkoutDetails.invoice.balanceApplied > 0) {
            list.creditBalance = [createAccountCreditListItem(checkoutDetails, creditBalance.value)];
        }

        list.amountDue = [createAmountDueListItem(checkoutDetails)];

        return list;
    }, [checkoutDetails, covers, creditBalance.value, userTimeZone]);

    return (
        <div className="checkout-summary-baymax__container">
            <ul className="checkout-summary-baymax__list">
                {Object.entries(items).map(([key, value]) => (
                    <div key={key} role="menuitem" className="checkout-summary-baymax__list__group">
                        {key && productNames.includes(key) && (
                            <p className="checkout-summary-baymax__list-item__header">{key}</p>
                        )}
                        {key && !productNames.includes(key) && (
                            <hr
                                className={`checkout-summary-baymax__list-item__divider${
                                    key.toLowerCase() !== 'amountdue' ? '--empty' : ''
                                }`}
                            />
                        )}
                        {value.map((x) => (
                            <div key={`${key}_${x.id}`} className="list-item__content">
                                {x.customDataElement}
                            </div>
                        ))}
                    </div>
                ))}
            </ul>
        </div>
    );
};

export default CheckoutSummaryBaymax;
