import enrolCustomer from './enrolCustomer';
import getCustomer from './getCustomer';
import initialiseCustomer from './initialiseCustomer';
import initialiseGuestCustomer from './initialiseGuestCustomer';
import updateCustomerBankAccount from './updateCustomerBankAccount';
import updateCustomerCommunication from './updateCustomerCommunication';
import updateCustomerProfile from './updateCustomerProfile';
import updateCustomerVariables from './updateCustomerVariables';
import updateGuestCustomerProfile from './updateGuestCustomerProfile';

const CustomerService = {
    enrolCustomer,
    initialiseCustomer,
    initialiseGuestCustomer,
    getCustomer,
    updateCustomerProfile,
    updateGuestCustomerProfile,
    updateCustomerBankAccount,
    updateCustomerCommunication,
    updateCustomerVariables,
};

export default CustomerService;
