import LocalizedStrings from 'react-localization';

const roamingStartingRegion = new LocalizedStrings({
    en: {
        heading: 'Select the closest region',
        description: 'This helps us align a timezone to your cover',
    },
});

export default roamingStartingRegion;
