import React from 'react';
import classNames from 'classnames';
import { ActionItem, ActionItemProps } from './action-item/ActionItem';
import './ActionList.scss';

export interface ActionListProps {
    items: WithReactKey<ActionItemProps>[];
    header?: string;
    className?: string;
}

export const List: React.FC<ActionListProps> = ({ header, items, className }) => {
    return (
        <>
            {header && <h2>{header}</h2>}
            <div className={classNames('action-list', className)}>
                <ul>
                    {items.map(({ key, ...item }) => (
                        <ActionItem key={key} {...item} />
                    ))}
                </ul>
            </div>
        </>
    );
};

export default List;
