import { useOktaAuth } from '@okta/okta-react';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import { useProduct } from '../../business-logic/context-provider/ProductContext';
import Alert, { AlertTypes } from '../../components/alert/Alert';
import ErrorMessages from '../../components/alert/error-messages/ErrorMessages';
import Button from '../../components/button/Button';
import Layout from '../../components/layout/Layout';
import SlideInCoverTerms from '../../components/slide-in-cover-terms/SlideInCoverTerms';
import selectCoverContent from '../../content/ui/screens/select-cover/selectCover';
import withContent from '../../hoc/with-content/withContent';
import common from '../../strings/common';
import Routes, { Route } from '../../utils/Routes';
import CoverCode from '../../utils/constants/CoverCode';
import PageTitles from '../../utils/constants/PageTitles';
import getPdsVersion from '../../utils/getPdsVersion';
import parseString from '../../utils/parseString';

import './SelectCover.scss';

const contentMap = {
    heading: 'ui.heading',
    benefitsCta: 'ui.benefitsCta',
};

interface SelectCoverProps {
    content: Record<keyof typeof contentMap, string>;
}

const SelectCoverBaymax: FC<SelectCoverProps> = ({ content }) => {
    const history = useHistory();
    const { authState } = useOktaAuth();
    const {
        productGroups,
        products,
        initialised: baymaxProductInitialised,
        loading: baymaxProductLoading,
    } = useProduct();
    const [selectedCoverForBenefits, setSelectedCover] = useState<keyof typeof CoverCode | null>(null);

    const availableCategories = useMemo(() => {
        if (!productGroups || !products) return [];
        return productGroups.filter((group) =>
            group.options.some((cover) =>
                cover.coverCodes.some((code) => products.find((x) => x.productSpec.mainCoverType.coverCode === code)),
            ),
        );
    }, [productGroups, products]);

    const redirectToProductGroupPurchase = useCallback(
        (productGroup: string): void => {
            switch (productGroup.toLowerCase()) {
                case 'fliproaming':
                    history.push({
                        pathname: Routes.ROAMING_DESTINATIONS_BAYMAX,
                    });
                    break;
                case 'flipactive':
                    history.push({
                        pathname: Routes.SCHEDULE_ACTIVE_COVER_BAYMAX,
                    });
                    break;
                case 'flipkids':
                    history.push({
                        pathname: Routes.SCHEDULE_KIDS_COVER_BAYMAX,
                    });
                    break;
                default:
                    if (authState?.isAuthenticated) {
                        history.push({
                            pathname: Routes.HOME,
                            state: {
                                selectedProductGrouping: null,
                                selectedProductOption: null,
                                coverStartDates: [],
                                destination: null,
                            },
                        });
                    } else {
                        history.goBack();
                    }
                    break;
            }
        },
        [authState?.isAuthenticated, availableCategories, history],
    );

    useEffect(() => {
        Analytics.trackProductGroupsViewed(availableCategories.map((x) => x.name));
    }, [availableCategories]);

    return (
        <Layout title={content.heading} showBackButton showLoading={!baymaxProductInitialised || baymaxProductLoading}>
            <div className="cover-selection">
                {baymaxProductInitialised && (!availableCategories || availableCategories.length === 0) && (
                    <Alert type={AlertTypes.ERROR} message={ErrorMessages.refreshOrContactUs} />
                )}
                {baymaxProductInitialised &&
                    availableCategories.length > 0 &&
                    availableCategories
                        .sort((a, b) => a.position - b.position)
                        .map((group) => {
                            const pdsVersion = getPdsVersion({
                                products,
                                coverCode: group.representedByCoverCode as CoverCode,
                            });
                            return (
                                <div className="cover" key={group.name}>
                                    <div className="cover__image-wrapper">
                                        <div className={`cover__highlight cover__highlight--${group.groupTagStyle}`}>
                                            <span className="cover__highlight__text">{group.groupTag}</span>
                                        </div>
                                        <img src={group.image} alt="" className="cover__image" />
                                    </div>
                                    <div className="cover__content">
                                        <div className="cover__content__text">
                                            <div className="cover__content__title">
                                                <h3>{group.name}</h3>
                                                <div className="cover__content__title__price">
                                                    <small>{group.pricePrefix}</small>${group.price}/{group.priceUnit}
                                                </div>
                                            </div>
                                            <span className="cover__content__text__blurb">
                                                {parseString(group.description)}
                                            </span>
                                        </div>
                                        <Button
                                            variant="drawer-link"
                                            label={content.benefitsCta}
                                            onClick={() => {
                                                setSelectedCover((group.representedByCoverCode as CoverCode) ?? null);
                                                const pageName = PageTitles[history.location.pathname as Route];
                                                Analytics.trackBenefitsDrawerViewed(
                                                    pageName,
                                                    group.representedByCoverCode,
                                                );
                                            }}
                                        />
                                        {pdsVersion && (
                                            <SlideInCoverTerms
                                                showGroupPrice
                                                coverCode={group.representedByCoverCode as CoverCode}
                                                pdsVersion={pdsVersion}
                                                isOpen={selectedCoverForBenefits === group.representedByCoverCode}
                                                onOpen={() => {
                                                    setSelectedCover(
                                                        (group.representedByCoverCode as CoverCode) ?? null,
                                                    );
                                                }}
                                                onClose={() => {
                                                    setSelectedCover(null);
                                                }}
                                            />
                                        )}
                                        <Button
                                            type="button"
                                            width="full"
                                            size="small"
                                            onClick={() => {
                                                Analytics.trackProductClicked({ product: group.id });
                                                redirectToProductGroupPurchase(group.id);
                                            }}
                                            variant="primary"
                                            label={common.select}
                                            className="cover__content__cta--baymax"
                                        />
                                    </div>
                                </div>
                            );
                        })}
            </div>
        </Layout>
    );
};

export default withContent(SelectCoverBaymax, contentMap, selectCoverContent);
