const ACCOUNT_BASE = '/profile';
const SCHEDULE_BOOST_BASE = '/schedule-boost';
const CHECKOUT_BASE = '/checkout';
const LOGIN_BASE = '/signin';
const BAYMAX_PURCHASE = '/purchase';
const BAYMAX_ROAMING_BASE = '/flipRoaming';

export type Route = (typeof Routes)[keyof typeof Routes];

const Routes = {
    LANDING: '/',
    LOGIN_CALLBACK: '/login/callback',
    HOME: '/dashboard',
    RESIDENCY_STATE: '/residency-state',
    RESIDENCY_STATUS: '/residency-status',
    DATE_OF_BIRTH: '/date-of-birth',
    GENDER: '/gender',
    NAME: '/name',
    LOADING: '/loading',

    ACCOUNT: ACCOUNT_BASE,
    ACCOUNT_DETAILS: `${ACCOUNT_BASE}/account`,
    MANAGE_COVER: '/manage-cover',
    ACCOUNT_PREFERENCES: `${ACCOUNT_BASE}/preferences`,
    ACCOUNT_CERTIFICATE_OF_COVER: `${ACCOUNT_BASE}/certificate-of-cover`,

    SCHEDULE_BOOST_SUCCESS: `${SCHEDULE_BOOST_BASE}/success`,

    ROAMING_DESTINATIONS_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}`,
    ROAMING_START_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}/starting-destination`,
    ROAMING_REGION_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}/starting-region`,
    ROAMING_DATES_BAYMAX: `${BAYMAX_PURCHASE}${BAYMAX_ROAMING_BASE}/dates`,

    CHECKOUT: CHECKOUT_BASE,
    CART: '/cart',
    CART_BAYMAX: `${BAYMAX_PURCHASE}/cart`,
    CART_SUCCESS: '/cart/success',
    CART_BAYMAX_SUCCESS: `${BAYMAX_PURCHASE}/success`,

    // Beta
    YOUR_ACTIVITIES: '/your-activities',
    SCHEDULE_ALWAYS_ON_SUCCESS: `/schedule-always-on-success`,
    SCHEDULE_ACTIVE_COVER_BAYMAX: `${BAYMAX_PURCHASE}/flipactive`,
    SCHEDULE_KIDS_COVER_BAYMAX: `${BAYMAX_PURCHASE}/flipkids`,
    BEFORE_YOU_GO: '/before-you-go',

    SELECT_DATES: `${BAYMAX_PURCHASE}/select-dates`,
    COVER_DETAILS: `${BAYMAX_PURCHASE}/cover-details`,
    SELECT_COVER_BAYMAX: `${BAYMAX_PURCHASE}`,

    // external
    LOGIN: `${LOGIN_BASE}`,
    REGISTER: `${LOGIN_BASE}/register`,
    FORGOT_PASSWORD: `${LOGIN_BASE}/forgot-password`,

    NOT_FOUND: '/404',
} as const;

export default Routes;
