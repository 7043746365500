import LocalizedStrings from 'react-localization';

const boostFlow = new LocalizedStrings({
    en: {
        instantBoostModal:
            'Do you want to Boost your cover? It’ll cost <strong>{price}</strong>.<br /><br />Your <strong>coverage amount will double</strong> until <strong>11:59 PM {timeZone}</strong> tonight (for <strong>{boostTimeDuration}</strong>).',
        boostCancelModal: 'Are you sure you want to cancel your Boost on <strong>{activeDate}</strong>?',
        title: 'Boost your cover',
        subtitleTime:
            'The Boost lasts until <strong>11:59 PM</strong> on the day you activate it. So Boost early to get double cover for longer.',
        scheduleBoost: {
            title: 'Book in your Boost days',
            subtitle: 'Just a note - you can schedule up to 10 Boosts at a time, and up to 150 Boosts in a year.',
            addDays: 'Add day(s)',
            confirmBoosts: 'Confirm Boosts',
            disclaimer:
                'We’ll charge you when you confirm your Boost days. If you cancel a Boost before it starts, we’ll automatically refund it to your account.',
            maxBoostsAtATimeReachedError: "You've reached your limit of scheduling 10 Boosts at a time.",
            maxBoostsPerYearReachedError: "You've reached your limit of 150 Boosts this year.",
        },
        scheduleBoostSuccess: {
            title: 'You’ve been Boosted!',
            description:
                "If you decide to cancel your Boost before your big day, we'll refund the money back to your account.",
        },
        unableToScheduleBoostModal: {
            title: 'Unable to purchase more cover',
            description: 'Cover is not available for purchase until your current subscription starts.',
        },
        noExtraCoverAvailable: {
            title: 'You already have cover.',
            description: 'Please cancel any existing cover to purchase new cover.',
        },
        timeRemaining: 'time remaining',
    },
});

export default boostFlow;
