export enum DayPassPdsVersion {
    ALPHA_V1 = '1.0',
    ALPHA_V2 = '1.1',
    BETA = '2.0',
    GROGU = '2.1',
    GROGU_SCHEDULE = '2.2',
    GROGU_SEPT_UPDATE = '2.3',
    GROGU_SCHEDULING_LIMIT_UPDATE = '2.4',
}

export const LatestDayPassPdsVersion = DayPassPdsVersion.GROGU_SCHEDULING_LIMIT_UPDATE;

export enum FlipActiveDailyPdsVersion {
    V1 = '2.5',
    V1_KIDS = '2.6',
    FLIP_2_0 = '3.0',
    FLIP_2_1 = '3.1',
}

export const LatestFlipActiveDailyPdsVersion = FlipActiveDailyPdsVersion.FLIP_2_1;

export enum FlipActiveWeeklyPdsVersion {
    V1 = '2.5',
    V1_KIDS = '2.6',
    FLIP_2_0 = '3.0',
    FLIP_2_1 = '3.1',
}
export const LatestFlipActiveWeeklyPdsVersion = FlipActiveWeeklyPdsVersion.FLIP_2_1;

export enum FlipActive90DaysPdsVersion {
    FLIP_2_1 = '3.1',
}

export const LatestFlipActive90DaysPdsVersion = FlipActive90DaysPdsVersion.FLIP_2_1;

export enum FlipActive180DaysPdsVersion {
    FLIP_2_1 = '3.1',
}

export const LatestFlipActive180DaysPdsVersion = FlipActive180DaysPdsVersion.FLIP_2_1;

export enum FlipActiveSubMonthlyPdsVersion {
    V1 = '2.5',
    V1_KIDS = '2.6',
    FLIP_2_0 = '3.0',
    FLIP_2_1 = '3.1',
}

export const LatestFlipActiveSubMonthlyPdsVersion = FlipActiveSubMonthlyPdsVersion.FLIP_2_1;

export enum FlipKidsDailyPdsVersion {
    V1 = '2.6',
    FLIP_2_0 = '3.0',
    FLIP_2_1 = '3.1',
}

export const LatestFlipKidsDailyPdsVersion = FlipKidsDailyPdsVersion.FLIP_2_1;

export enum FlipKidsWeeklyPdsVersion {
    V1 = '2.6',
    FLIP_2_0 = '3.0',
    FLIP_2_1 = '3.1',
}

export const LatestFlipKidsWeeklyPdsVersion = FlipKidsWeeklyPdsVersion.FLIP_2_1;

export enum FlipKids90DaysPdsVersion {
    FLIP_2_1 = '3.1',
}

export const LatestFlipKids90DaysPdsVersion = FlipKids90DaysPdsVersion.FLIP_2_1;

export enum FlipKids180DaysPdsVersion {
    FLIP_2_1 = '3.1',
}

export const LatestFlipKids180DaysPdsVersion = FlipKids180DaysPdsVersion.FLIP_2_1;

export enum FlipKidsSubMonthlyPdsVersion {
    V1 = '2.6',
    FLIP_2_0 = '3.0',
    FLIP_2_1 = '3.1',
}

export const LatestFlipKidsSubMonthlyPdsVersion = FlipKidsSubMonthlyPdsVersion.FLIP_2_1;

export enum AlwaysOnPdsVersion {
    ALPHA_V1 = '1.0',
    ALPHA_V2 = '1.1',
    BETA = '2.0',
    GROGU = '2.1',
    GROGU_SCHEDULE = '2.2',
    GROGU_SEPT_UPDATE = '2.3',
    GROGU_SCHEDULING_LIMIT_UPDATE = '2.4',
}

export const LatestAlwaysOnPdsVersion = AlwaysOnPdsVersion.GROGU_SCHEDULING_LIMIT_UPDATE;

export enum JapanPassPdsVersion {
    V1 = '1.0',
    V1_FIXED = '1.1',
    V1_SCHEDULING_LIMIT_UPDATE = '1.2',
}

export const LatestJapanPassPdsVersion = JapanPassPdsVersion.V1_SCHEDULING_LIMIT_UPDATE;

export enum FlipRoamingPdsVersion {
    V1 = '1.0',
}

export const LatestFlipRoamingPdsVersion = FlipRoamingPdsVersion.V1;

export enum FlipRoamingKidsPdsVersion {
    V1 = '1.0',
}

export const LatestFlipRoamingKidsPdsVersion = FlipRoamingKidsPdsVersion.V1;

export type MainCoverPdsVersion =
    | DayPassPdsVersion
    | AlwaysOnPdsVersion
    | JapanPassPdsVersion
    | FlipActiveDailyPdsVersion
    | FlipActiveWeeklyPdsVersion
    | FlipActive90DaysPdsVersion
    | FlipActive180DaysPdsVersion
    | FlipActiveSubMonthlyPdsVersion
    | FlipKidsDailyPdsVersion
    | FlipKidsWeeklyPdsVersion
    | FlipKids90DaysPdsVersion
    | FlipKids180DaysPdsVersion
    | FlipKidsSubMonthlyPdsVersion
    | FlipRoamingPdsVersion
    | FlipRoamingKidsPdsVersion;
