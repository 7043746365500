import Routes, { Route } from '../Routes';

const PageTitles: Record<Route, string> = {
    [Routes.HOME]: 'Dashboard',
    [Routes.LOGIN]: 'Login',
    [Routes.FORGOT_PASSWORD]: 'Forgot password',

    // Onboarding
    [Routes.LANDING]: 'Welcome',
    [Routes.REGISTER]: 'Create account',
    [Routes.YOUR_ACTIVITIES]: 'Your activities',

    // Checkout
    [Routes.RESIDENCY_STATE]: 'State of residence',
    [Routes.RESIDENCY_STATUS]: 'Residency status',
    [Routes.DATE_OF_BIRTH]: 'Date of birth',
    [Routes.GENDER]: 'Gender',
    [Routes.NAME]: 'Name',
    [Routes.SCHEDULE_ALWAYS_ON_SUCCESS]: 'Checkout success',
    [Routes.SCHEDULE_BOOST_SUCCESS]: 'Checkout success',
    [Routes.CHECKOUT]: 'Checkout',
    [Routes.BEFORE_YOU_GO]: 'Before you go',
    [Routes.SCHEDULE_ACTIVE_COVER_BAYMAX]: 'Purchase options',
    [Routes.SCHEDULE_KIDS_COVER_BAYMAX]: 'Purchase options',
    [Routes.SELECT_COVER_BAYMAX]: 'Select cover',
    [Routes.SELECT_DATES]: 'Select dates',
    [Routes.COVER_DETAILS]: 'Your selected cover',

    [Routes.ROAMING_DESTINATIONS_BAYMAX]: 'Select Destinations',
    [Routes.ROAMING_START_BAYMAX]: 'Select starting destination',
    [Routes.ROAMING_REGION_BAYMAX]: 'Select starting region',
    [Routes.ROAMING_DATES_BAYMAX]: 'Schedule Roaming cover',

    // Profile
    [Routes.ACCOUNT]: 'Account',
    [Routes.ACCOUNT_DETAILS]: 'Account details',
    [Routes.ACCOUNT_PREFERENCES]: 'Preferences',
    [Routes.ACCOUNT_CERTIFICATE_OF_COVER]: 'Certificate of cover',
    [Routes.MANAGE_COVER]: 'Manage Cover',

    // Intermediate states, no need to title
    [Routes.LOGIN_CALLBACK]: '',
    [Routes.LOADING]: '',

    [Routes.CART]: 'Cart',
    [Routes.CART_BAYMAX]: 'Cart',
    [Routes.CART_SUCCESS]: 'Checkout Success',
    [Routes.CART_BAYMAX_SUCCESS]: 'Cart Success',
    [Routes.NOT_FOUND]: 'Page not found',
};

export default PageTitles;
