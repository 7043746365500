import { get } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Analytics from '../../analytics/Analytics';
import LinkOutIcon from '../../assets/images/link-out.svg';
import { useOpenCovers } from '../../business-logic/context-provider/OpenCoversContext';
import { useUser } from '../../business-logic/context-provider/user-context';
import { Button } from '../../components/button/Button';
import { Layout } from '../../components/layout/Layout';
import { ListItemProps } from '../../components/list-item/ListItem';
import { List } from '../../components/list/List';
import { useZendesk } from '../../components/zendesk/Zendesk';
import useLazyDependency from '../../hooks/lazy-dependency/useLazyDependency';
import useStripe from '../../hooks/usePaymentStripe';
import common from '../../strings/common';
import profile from '../../strings/profileFlow';
import Routes from '../../utils/Routes';
import CoverTypeId from '../../utils/constants/CoverTypeId';
import ExternalLinks from '../../utils/constants/ExternalLinks';
import AccountCredit from './AccountCredit';

import './Profile.scss';

const Profile: React.FC = () => {
    const {
        accessToken,
        loading: userLoading,
        logout,
        creditBalance: cbLazyDepObject,
        totalPurchases: tpLazyDepObj,
    } = useUser();
    const { openCoversLoading, policies } = useOpenCovers();
    const history = useHistory();
    const { openZendesk } = useZendesk();
    const creditBalance = useLazyDependency(cbLazyDepObject);

    const isAppLoading = userLoading || openCoversLoading;

    const hasSubscriptionCover =
        policies.find((p) => p.mainCover.coverTypeId === CoverTypeId.SUBSCRIPTION_V1) !== undefined;

    const [showZendesk, setShowZendesk] = useState(false);

    const goToAccountSettings = () => {
        history.push(Routes.ACCOUNT_DETAILS);
    };

    const goToPreferenceSettings = () => {
        history.push(Routes.ACCOUNT_PREFERENCES);
    };

    const goToPrivacyPolicy = () => {
        Analytics.trackProfileClickViewPrivacyPolicy();
        window.open(ExternalLinks.privacyPolicy);
    };

    const goToTermsAndConditions = () => window.open(ExternalLinks.termsAndConditions);

    const goToFrequentlyAskedQuestions = () => window.open(ExternalLinks.frequentlyAskedQuestions);

    const goToYourCertificateOfCover = async () => {
        history.push(Routes.ACCOUNT_CERTIFICATE_OF_COVER);
    };

    const { loading, error, manageProtection } = useStripe({
        basePath: import.meta.env.VITE_BASE_API_PATH || '',
        accessToken,
    });

    const goToManageBillingAndPayments = () => {
        // TODO - Add loading UI
        console.log(loading);
        // TODO - Handle error
        console.log(error);
        manageProtection();
    };

    const onGetInTouch = () => {
        setShowZendesk(true);
        openZendesk();
    };

    const accountMenu: ListItemProps[] = [
        { id: 'your-account', data: profile.yourAccount, onClick: () => goToAccountSettings() },
        { id: 'your-preference', data: profile.yourPrefs, onClick: () => goToPreferenceSettings() },
    ];

    const totalPurchasesData = useLazyDependency(tpLazyDepObj, true);
    const totalPurchases = get(totalPurchasesData, 'value', 0);

    const yourCoverMenu: ListItemProps[] = [
        {
            id: 'manage-billing-and-payments',
            data: profile.manageBillingAndPayments,
            onClick: () => goToManageBillingAndPayments(),
            rightIcon: LinkOutIcon,
        },
    ];

    if (totalPurchases >= 1) {
        yourCoverMenu.push({
            id: 'cover-cert',
            data: profile.yourCertificateOfCover,
            onClick: () => goToYourCertificateOfCover(),
        });
    }

    const supportMenu: ListItemProps[] = [
        { id: 'get-in-touch', data: profile.getInTouch, onClick: () => onGetInTouch(), rightIcon: LinkOutIcon },
        {
            id: 'faq',
            data: profile.viewFaqs,
            onClick: () => goToFrequentlyAskedQuestions(),
            rightIcon: LinkOutIcon,
        },
    ];

    const legalMenu: ListItemProps[] = [
        {
            id: 'terms-and-conditions',
            data: profile.viewTermsAndConditions,
            onClick: () => goToTermsAndConditions(),
            rightIcon: LinkOutIcon,
        },
        {
            id: 'privacy-policy',
            data: profile.viewPrivacyPolicy,
            onClick: () => goToPrivacyPolicy(),
            rightIcon: LinkOutIcon,
        },
    ];

    return (
        <Layout className="profile" title={profile.title} nav showZendesk={showZendesk} bgPattern={false}>
            {!isAppLoading && (
                <>
                    <List innerHeader={profile.accountDetails} items={accountMenu} className="profile__list" />
                    <List innerHeader={profile.yourCover} items={yourCoverMenu} className="profile__list" />
                    {!!creditBalance.value && (
                        <AccountCredit
                            creditBalanceValue={creditBalance.value}
                            hasActiveSubscription={hasSubscriptionCover}
                        />
                    )}
                    <List innerHeader={common.support} items={supportMenu} className="profile__list" />
                    <List innerHeader={common.legal} items={legalMenu} className="profile__list" />
                    <Button
                        variant="secondary"
                        width="full"
                        label={common.logout}
                        onClick={logout}
                        className="profile__btn-logout"
                    />
                </>
            )}
        </Layout>
    );
};

export default Profile;
